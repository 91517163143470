const NOR = {
  auth_mobileNumber: 'Mobilnummer',
  auth_password: 'Passord',
  auth_passwordPlaceholder: 'Skriv inn passordet ditt',
  auth_rememberMe: 'Forbli pålogget på denne enheten',
  auth_login: 'Logg inn',
  auth_login_here: 'Logg inn her',
  auth_get_newsletr: `Jeg ønsker å motta informasjon og nyheter fra Nettgrav om produktet, vårt formål og generell informasjon`,
  auth_notRegistered: 'Har du ikke konto? ',
  auth_alreadyRegistered: 'Har du allerede en konto? ',
  auth_register: 'Registrer deg',
  auth_forgotPassword: 'Glemt passord?',
  auth_welcome: 'Opprett din Nettgrav.no konto',
  auth_toNettgrav: 'til nettgrav.no',
  auth_enterPhoneNumber: 'Skriv inn mobilnummeret ditt',
  auth_man: 'Mann',
  auth_woman: 'Kvinne',
  auth_other: 'Annen',
  auth_acceptConditions:
    'Jeg aksepterer å motta SMS fra Nettgrav, og jeg godtar',
  auth_termsAndCondition: 'vilkår og betingelser',
  auth_next: 'Neste',
  auth_receivedSMS: 'Du vil snart motta en melding',
  auth_otpCode: 'Skriv inn engangskoden',
  auth_received: 'Har du ikke mottatt engangskoden?',
  auth_newOtp: 'Du kan sende ny engangskode om 00:',
  auth_sendNew: 'Send ny',
  auth_confirm: 'Bekreft',
  auth_yourName: 'Ditt navn',
  auth_enterFullName: 'Skriv inn navnet ditt',
  auth_enterPasswordAgain: 'Skriv inn passordet på nytt',
  auth_passwordMessage:
    'Passordet må bestå av minst 7 tegn og inneholde minst 3 av følgende:',
  auth_capitalLetter: '⚙︎ Store bokstaver',
  auth_smallLetters: '⚙︎ Små bokstaver',
  auth_digits: '⚙︎ Sifre',
  auth_characters: '⚙︎ Spesialtegn',
  auth_signupInvitation: 'Du er invitert til familieabonnementet fra',
  auth_resetPassword: 'Tilbakestill passord',
  auth_resetPasswordSMS: 'Engangskode sendes til mobilnummeret ditt',
  auth_back: 'Tilbake',
  auth_send: 'Send',
  auth_restOtp: 'Skriv inn engangskoden sendt til {code}{phoneNumber}',
  auth_code: 'Skriv inn koden',
  auth_confirmPassword: 'Bekreft nytt passord',
  auth_newPassword: 'Nytt passord',
  auth_notSame: 'Passordene er ikke like',
  auth_save: 'Lagre',
  settings_profile: 'Profil',
  settings_personalInformation: 'Personopplysninger',
  settings_payments: 'Betalinger',
  settings_emptyPayments: 'Ingen tilgjengelige betalinger',
  settings_paymentMethod: 'Betalingsmetode',
  settings_subscription: 'Abonnement',
  settings_members: 'Medlemmer',
  settings_giftInvitations: 'Gaveinvitasjoner',
  settings_notifications: 'Varsler',
  settings_account_information: 'Kontoadministrasjon',
  settings_contact: 'Kontakt Nettgrav',
  settings_password: 'Passord',
  settings_edit: 'Rediger',
  settings_fullName: 'Fullt navn',
  setting_gender: 'Kjønn',
  settings_occupation: 'Yrke',
  settings_birthday: 'Bursdag',
  settings_email: 'E-postadresse',
  settings_telephoneNumber: 'Telefonnummer',
  settings_address: 'Adresse',
  settings_language: 'Språk',
  settings_currency: 'Valuta',
  settings_timeZone: 'Tidssone',
  settings_invalidEmail: 'Ugyldig e-postadresse',
  settings_cancel: 'Avbryt',
  settings_currentPassword: 'Nåværende passord',
  confirm_password: 'Bekreft passord',
  settings_newPassword: 'Nytt passord',
  settings_retype: 'Skriv inn passordet på nytt',
  settings_billingMethod: 'Betalingsmetode',
  settings_nextDue: 'Neste betaling forfaller',
  settings_paymentMethods: 'Betalingsmetoder',
  settings_nextBilling: 'Neste fakturering',
  settings_mangeMethods: 'Administrer betalingsmetoder',
  settings_emptyMethods: 'Ingen betalingsmetoder lagt til',
  settings_vippsHeading: `Vipps`,
  settings_manageVipps: 'Administrer Vipps-avtaler',
  settings_goToAgreements: 'Gå til avtaler',
  settings_cancelSubscription: 'Avslutt abonnement',
  settings_cancel_mySubscription: 'Ja, avslutt abonnementet mitt',
  settings_cardEnd: 'slutter på',
  settings_remove: 'Fjern',
  settings_stop: 'Stopp',
  settings_vippsAgreement: 'Vipps-avtaler',
  view_manage_agreements: 'Se og administrer avtalene dine',
  empty_agreements: 'Ingen Vipps-avtaler ble funnet',
  settings_amount: '{amount}',
  settings_subscriptionHeading: 'Abonnementsadministrasjon',
  settings_subscriptionStatus: 'Under behandling',
  settings_freeSubscriptionHeading: 'Kom i gang med Fri',
  settings_month: 'måned',
  months: 'måneder',
  year: 'år',
  month: 'måned',
  years: 'år',
  saved: 'Lagret',
  saved_message: '{name} er lagret',
  settings_upgrade: 'Oppgrader plan',
  settings_subDescription: 'For barn, foreldre, barnebarn og andre nære',
  settings_for: 'for',
  settings_paymentInfo: 'Betalingsinformasjon',
  settings_cardNumber: 'Kortnummer',
  settings_billingInfo: 'Faktureringsinformasjon',
  settings_name: 'Navn',
  settings_billingEmail: 'E-post',
  settings_billingCountry: 'Land',
  settings_postCode: 'Postnummer',
  settings_changePlan: 'Endre plan',
  change_plan: 'Endre plan',
  change: 'Endre',
  change_members: 'Administrer medlemmer',
  settings_noSubscription:
    'Du har ikke et aktivt abonnement. Gå til plansiden for å velge en plan.',
  settings_clickHere: 'Klikk her',
  settings_memberHeading: 'Deltakende medlemskap',
  settings_receivedMembership: '(Mottatt medlemskap)',
  settings_emptyMembership: 'Du har ingen invitasjoner akkurat nå',
  settings_memberPage: 'Administrer medlemskapet ditt på medlemssiden',
  settings_subscribedPlan: 'Abonnementet ditt er aktivert.',
  settings_updatePlan: 'Abonnementet ditt er oppdatert.',
  settings_downgradePlan: 'Abonnementet ditt er nedgradert.',
  settings_addedMember: 'Nye medlemmer er lagt til i planen din.',
  payment_progress: 'Betaling pågår. Vennligst vent',
  something_wrong: 'Noe gikk galt. Prøv igjen senere.',
  add_validNumber: 'Oppgi et gyldig telefonnummer',
  select_planMessage: 'Velg en plan for å fortsette.',
  settings_notificationsSubheading: 'Angi varselpreferanser',
  settings_preferenceText: 'Motta varsler fra Nettgrav og partnere via:',
  settings_genealText: 'Motta nyheter og oppdateringer fra Nettgrav.no via:',
  settings_textMessage: 'Tekstmeldinger',
  settings_general: 'Generelle oppdateringer',
  settings_your: 'din.',
  settings_enable: 'Plan aktivert',
  free: 'Fri',
  conscious: 'Bevisst',
  consciousPlus: 'Bevisst +',
  settings_membershipOn: '-medlemskap på',
  settings_cancelSubscription: 'Avslutt abonnement',
  cancelSubscription_message: `Du er i ferd med å kansellere abonnementene dine på Nettgrav {name}-planen. Merk at ved å kansellere abonnementet ditt, vil du ikke ha tilgang til alle funksjonene knyttet til denne planen. Du vil kun kunne samhandle med Nettgrav med gratisplanfunksjonen.`,
  reactivateSubscription_message: `Reaktiver medlemskapet ditt på Nettgrav {name}. Dette gjenoppretter tilgang til alle funksjonene knyttet til denne planen.`,
  not_now: 'ikke nå',
  reactivate: 'Ja, reaktiver abonnementet mitt',
  settings_modalSubheading: 'Her er kjærligheten størst.',
  settings_modalLink: 'Takk for at du er her.',
  settings_memberSubheading: 'Din familieplan.',
  settings_emptyMembers: 'Ingen medlemmer i abonnementet ditt',
  settings_memberName: `Familien til {name}`,
  settings_memberLength: '{length} medlemmer',
  settings_administrator: 'Administrator',
  settings_showMembers: 'Vis medlemmer',
  settings_familyMembers: 'Familiemedlemmer',
  settings_you: 'Du',
  settings_connect: 'koble til',
  settings_setRelation: 'Angi relasjon',
  settings_selectedRelation: 'Velg relasjon',
  settings_giftHeading: `Gi bort et medlemskap til noen du bryr deg om, og la dem ta del i opplevelsen.`,
  settings_giftFreeSubscription: ` Du er på Fri-planen. Oppgrader abonnementet ditt for ubegrenset tilgang til alle Nettgravs tjenester og for å invitere flere medlemmer:`,
  settings_seeSubscriptions: 'Se abonnementene',
  settings_youHave: 'Du har {count} ',
  settings_addMore: 'for å legge til flere medlemskap i planen din.',
  settings_addMember: 'Legg til medlem',
  settings_invitedMember: 'Inviterte medlemmer',
  settings_emptyGiftMembers: 'Ingen ubesvarte invitasjoner',
  settings_inviteTo: 'Klikk her for å invitere til ditt familieabonnement',
  settings_pendingSubscription: 'Abonnementet ditt bekreftes snart.',
  delete: 'Slett',
  settings_shareLink: 'Kopier og del din Nettgrav.no-invitasjonslenke',
  settings_copyToClipboard: 'Lenke kopiert til utklippstavlen',
  discard_subheading: `Er du sikker på at du vil forkaste endringene? Denne handlingen kan ikke angres.`,
  no: 'Nei',
  yes: 'Ja',
  invitation: 'invitasjon',
  information: 'Informasjon',
  interests: 'Interesser',
  note: 'Notat',
  relative: 'Slektning',
  uploadingImages: 'Laster opp bilder',
  lifeEvent: 'Livshendelse',
  workplace: 'Arbeidsplass',
  delete_modalHeading: 'Bekreft at du ønsker å fjerne',
  picture: 'Bilde',
  paymentMethod: 'Betalingsmetode',
  vippsAgreement: 'Vipps-avtale',
  settings_complete_subscription: 'Fullfør abonnement',
  pending_message: 'Abonnementet ditt må fullføres.',
  pending_title: 'Betalingsavtalen din venter',
  stopped_title: 'Betalingsavtalen din er stoppet',
  stopped_message:
    'Abonnementet ditt er ufullstendig fordi betalingsavtalen ble kansellert. Fullfør abonnementet ditt for å fortsette.',
  expired_title: 'Betalingsavtalen din har utløpt',
  expired_message:
    'Abonnementet ditt er ufullstendig fordi betalingsavtalen er utløpt.',
  complete_setup: 'Fullfør oppsett',
  cancelled_title: 'Abonnementet ditt er kansellert',
  cancelled_message:
    'Abonnementet ditt er kansellert. Du kan fortsette å bruke Nettgrav.no som vanlig frem til medlemsdatoen utløper. Medlemskapet utløper {period_end_date}. Etter denne datoen vil abonnementet ditt automatisk endres til Fri.',
  activeStopped_title: 'Betalingsavtalen din er avsluttet',
  activeStopped_message: `Betalingsmåten knyttet til abonnementet ditt er stoppet. Vennligst forny betalingsmåten for å fortsette å nyte premiumtjenester. Hvis problemet vedvarer, vil abonnementet bli nedgradert til gratisplanen etter gjeldende faktureringssyklus.`,
  renew_Agreement: 'Forny betalingsavtale',
  add_card: 'Legg til betalingsmåte',
  Reactivate: 'Aktiver abonnementet på nytt',
  active_title: 'Abonnementet ditt vil snart bli aktivert',
  active_message: `Abonnementet ditt er under behandling og vil bli aktivert om noen minutter. Takk for tålmodigheten.`,
  activeExpired_title: 'Betalingsavtalen din har utløpt',
  activeExpired_message: `Betalingsmåten knyttet til abonnementet ditt har utløpt. Vennligst forny eller opprett en ny betalingsavtale for å fortsette.`,
  consciousness: 'Ledelse',
  about_us: 'Om nettgrav.no',
  team: 'Om End Of Time',
  careers: 'Ledige stillinger',
  features: 'Om graven',
  available_sustainable: 'Tilgjengelig og bærekraftig',
  maintenance_free_grave: 'Vedlikeholdsfri',
  memory_album: 'Minnealbum',
  value_archive: 'Verdiarkiv',
  memorial_wall: 'Minnetavle',
  life_archive: 'Livsarkiv',
  anniversary_calendar: 'Merkedagskalender',
  Family_values: 'Familietre',
  resources: 'Ressurser',
  find_grave: 'Finn en grav',
  blog: 'Blogg',
  help_center: 'Hjelpesenter',
  community: 'Fellesskap',
  contact: 'Kontakt',
  contact_us: 'Kontakt oss',
  business_inquiries: 'Forretningsforespørsler',
  feedback: 'Tilbakemelding',
  settings_inviteAgain: 'invitasjoner igjen.',
  activeExpired_message: `Din Vipps-betalingsavtale har utløpt. Vennligst forny eller opprett en ny betalingsavtale for å fortsette.`,
  notValid_title: 'Betalingsmåte kreves',
  notValid_message: `Det ser ut til at betalingsmåten din mangler eller ikke lenger er gyldig. Oppdater betalingsopplysningene dine for å fortsette uavbrutt tjeneste.`,
  basic_information: 'Grunnleggende informasjon',
  interest: 'Interesser',
  notes: 'Notater',
  passion: 'Lidenskap',
  relation: 'Relasjon',
  picture: 'Bilde',
  life_event: 'Livshendelse',
  subscription_invitation: 'Abonnementsinvitasjon',
  find_grave_norway: 'Finn en grav i Norge',
  search_grave: 'Søk på navn',
  or: 'eller',
  create_netgrave: 'Opprett Nettgrav',
  visit_grave: 'Besøk graven når du',
  wish: 'ønsker',
  second_section_description: 'Digital tilgang til graven når som helst',
  memorial_plaque: 'Minnetavlen',
  memorial_plaque_description: 'Del minner og opplevelser',
  archive: 'Verdiarkivet',
  archive_description: `Bevar verdier og livsvisdom for fremtidige generasjoner.`,
  memory_album: 'Minnealbum',
  memory_album_description: 'Samle og del bilder fra den avdødes liv.',
  find_municipality: 'Tilgjengelige kommuner',
  search_municipality: 'Søk etter kommune',
  it_is: 'Finn en grav i over',
  find_grave_count: '{count}',
  municipality_description: 'tilgjengelige kommuner.',
  no_results: 'Fant ingen resultater for',
  find_cemetery: 'Finn en grav på disse kirkegårdene.',
  search_cemetery: 'Søk etter kirkegård.',
  cemetery_description:
    'tilgjengelige kirkegårder. Søk etter en kirkegård for lettere å finne graven.',
  graves: 'Graver',
  grave_description:
    'tilgjengelige graver på denne kirkegården. Søk etter navn for å finne og besøke en grav. Ved å legge til din relasjon kan du lagre graven i din profil og motta oppdateringer på merkedager',
  goto_grave: 'Gå til graven',
  payment_due_message:
    'Abonnementet ditt er aktivt, og betalingen pågår. Vipps belaster abonnementsbeløpet fra kontoen din.',
  payment_due_title: 'Abonnementet ditt er aktivt',
  find_grave_image_title:
    'Nettgrav gjør det enkelt å ivareta minnene på en moderne måte.',
  find_grave_image_text:
    'Med Nettgrav kan du få en tilgjengelig grav som er alltid nær, uansett hvor du bor.',
  find_grave_text: 'lagres gravene du har relasjon til.',
  life_page: 'Livsside',
  the_life_page: 'Oppdag Livssiden',
  first_description:
    'Din personlige side i Nettgrav, hvor alle gravene du har relasjoner til vises på ett sted. Hold kontakten med dine kjære og oppdater minnene når det passer deg.',
  second_description: 'Alle graver og relasjoner vises på livssiden din.',
  update: 'Relasjoner og oppdateringer',
  third_description:
    'Livssiden lar deg se alle graver og relasjoner du har i ditt nettverk, samt motta varsler om nye minner og viktige hendelser. Alltid oppdatert, alltid tilgjengelig.',
  netgrave: 'Nettgrav',
  find_grave_last_text:
    'En vedlikeholdsfri grav for alle, som gir deg muligheten til å opprettholde relasjonene og minnene uten fysisk vedlikehold. Samle familie og pårørende for en felles opplevelse av gravpleie.',
  plans_page_heading: 'Finn en plan som passer dine behov',
  users: 'Medlemmer',
  for_members: 'For {user} medlemmer',
  for_member: 'For {user} medlem',
  for_6_members: 'For {user} eller mer',
  plans_page_subheading:
    'Velg en plan som gir deg full tilgang til våre funksjoner for å ivareta minner og skape en meningsfull grav. Start gratis eller oppgrader til Bevisst-planen for å få tilgang til eksklusive funksjoner som Minnealbum, Verdiarkiv, Livsarkiv og mer.',
  per_user: 'per medlem',
  current_members: 'Nåværende medlemmer',
  total_members: 'Oppdatert medlemmer',
  subscription_length: 'Abonnement lengde',
  plan_price: 'Planpris',
  increment_text: 'Velg antall medlemskap du ønsker for deg og din familie',
  free_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  conscious_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  conscious_plus_plan_description:
    'Vi knytter sammen alle gravene dine på ett sted og tilbyr kontinuerlig oppfølging av familien din.',
  total_user_count:
    ' For {subscriptionTotalCount} medlemmer er den årlige betalingen:',
  per_month: 'per måned',
  current_plan: 'Gjeldende plan',
  got_started: 'Kom i gang',
  free_feature_heading: 'Alle funksjoner inkludert i gratisplanen',
  conscious_feature_heading:
    'Alle funksjoner inkludert i gratisplanen, pluss ekstra funksjoner',
  conscious_plus_feature_heading:
    'Alle funksjoner inkludert i bevissthetsplanen, pluss ekstra funksjoner',
  // Adjust the feature texts according to the requirements.//
  feature_memory_album: 'Minnealbum',
  feature_purposes: 'Formål',
  feature_end_of_time: 'End of Time Coin',
  feature_family_tree: 'Slektstre',
  feature_testament: 'Testament',
  feature_groups: 'Grupper (Samfunn)',
  feature_Shop: 'Butikk',
  feature_Sparks: 'Gnister',
  feature_life_page: 'Livsside',
  feature_search_grave: 'Søk etter graver',
  feature_create_grave: 'Opprette nye Nettgraver',
  feature_life_archive: 'Livsarkiv',
  feature_anniversary_calendar: 'Merkedagskalender',
  feature_memory_wall: 'Minnetavle',
  feature_value_archive: 'Verdiarkiv',
  question_answer: 'Spørsmål og svar',
  benefits_1_title: 'Nærhet',
  benefits_1_description:
    'Opplev, motta og vis nærhet til dine når du føler det, uansett hvor du er.',
  benefits_2_title: 'Varig gravsted',
  benefits_2_description: 'Nettgrav.no er her til evig tid.',
  benefits_3_title: 'Bevisst',
  benefits_3_description:
    'Se livet fra et nytt perspektiv med Nettgrav.no, og gjør mer av det gode.',
  benefits_4_title: 'Kontroll over graven',
  benefits_4_description:
    'Se, opplev, og bygg graven på nett. Graveier har alltid full kontroll og oversikt.',
  benefits_title: 'Å være litt i forkant',
  faqs_1_question: 'Hvilke betalingsmetoder aksepterer dere?',
  faqs_1_answer: 'Vi aksepterer kreditt-/debetkort (Visa, Mastercard) og Vipps',
  faqs_2_question: 'Er det ekstra gebyrer som jeg må være klar over?',
  faqs_2_answer:
    'Det er ingen skjulte gebyrer. Her kan du samle alle gravene og gravlagte uten ekstra gebyrer.',
  faqs_3_question: 'Hvordan oppdaterer jeg betalingsdetaljene mine?',
  faqs_3_answer:
    'For å oppdatere betalingsdetaljene dine, vennligst gå til kontoinnstillingene og velg abonnementsfanen.',
  faqs_4_question: 'Hva skjer etter betalingen er gjennomført?',
  faqs_4_answer:
    'Når betalingen er gjennomført vil du motta et varsel fra Nettgrav som bekrefter at ditt medlemskap er aktivert.',
  faqs_5_question: 'Hva er forskjellen på Fri og Bevisst?',
  faqs_5_answer:
    'Fri-abonnementet gir deg mulighet til å søke etter graver. Bevisst-abonnementet gir deg full tilgang til alle funksjoner uten begrensninger.',
  faqs_6_question: 'Hvem er Bevisst for?',
  faqs_6_answer:
    'Medlemskapet er for de som ønsker en tilgjenglig og meningsfull grav, og som støtter opp om en sterkere gravetikk gjennom opprettelsen av End Of Time-senteret.',
  update_modal_change_plan: 'Endring av {name}-plan',
  waite_for_validation: 'Vennligst vent mens vi validerer abonnementet',
  add_members: 'Legg til medlemmer',
  remove_members: 'Fjern medlemmer',
  how_many_add: 'Hvor mange medlemmer ønsker du å legge til?',
  how_many_remove: 'Hvor mange medlemmer vil du fjerne?',
  not_remove_all: 'Du kan ikke fjerne alle medlemmene.',
  updated_price: 'Oppdatert pris etter endringene',
  remaining_members: 'Totalt gjenværende medlemmer',
  total_price: 'Totalpris',
  extra_user:
    'Det påløper en ekstra kostnad for {subscriptionTotalCount} ekstra medlem(mer)',
  process_payment: 'Fortsett til betaling',
  continue: 'Fortsette',
  subscription_members: 'Medlemmer av abonnementet',
  remove_subscription_members: 'Fjern abonnementsmedlemmer ved å klikke fjern',
  subscription_invitation_members: 'Invitasjoner til abonnement',
  remove_invitation_members:
    'Fjern abonnementsinvitasjonen ved å klikke på fjern',
  downgrade_plan: 'Nedgrader plan',
  upgrade_plan: 'Oppgrader plan',
  downgrade_message:
    'Deaktiver alle abonnementsmedlemmer og invitasjoner for å kunne nedgradere abonnementet ditt.',
  upgrade_message:
    'Fjern alle abonnementsmedlemmer og invitasjoner for å oppgradere planen',
  close_message: 'Du kan nå lukke og fortsette videre',
  close: 'Lukk',
  active_members: 'Aktive medlemmer av abonnementet ditt',
  active_members_message:
    'Du må deaktivere medlemmene for å kunne fortsette til Fri-planen.',
  active_members_empty_message:
    'Ingen aktive medlemmer funnet i ditt abonnement',
  active_invitations: 'Aktive invitasjoner til ditt abonnement',
  active_invitations_message:
    'Du må deaktivere medlemsinvitasjonene for å kunne fortsette til Fri-planen.',
  active_invitations_empty_message:
    'Ingen utestående invitasjoner knyttet til abonnementet ditt.',
  check_out: 'Utsjekk',
  select_payment_method: 'Velg betalingsmetode',
  order_details: 'Ordredetaljer',
  subscription_number: 'Antall abonnementer',
  taxes: 'Skatter',
  total: 'Total',
  sub_total: 'Delsum',
  discount_title: 'Faktureringssyklus',
  discount: 'Rabatt',
  renewal: 'Automatisk fornyelse',
  renewal_details: `Du har valgt {subscriptionCount} brukere, noe som resulterer i en total betaling på NOK {total} {periodName}. Du kan kansellere abonnementet ditt når som helst fra delen Mitt medlemskap på profilen din.`,
  payment_page_terms: 'Ved å klikke "Abonnér" godtar du',
  terms_conditions: 'vilkårene og betingelsene.',
  change_free_plan: 'Fri-planen er kostnadsfri',
  free_plan_message:
    'Med Fri-planen kan du utforske, finne og opprette Nettgrav.',
  ending_with: 'slutter på',
  add_payment_method: 'Legg til betalingsmåte',
  name_on_card: 'Kortholders navn',
  expiry_date: 'Utløpsdato',
  secure_payment_message: 'Sikker betaling med SSL-kryptering',
  billing_address: 'Fakturaadresse',
  enter_address: 'Skriv inn adresse',
  billing_info_message:
    'Denne informasjonen brukes til å utstede den første fakturaen. Du kan oppdatere disse detaljene for fremtidige fakturaer i kontoprofilen din.',
  subscribe: 'Abonnér',
  select_language: 'Velg språk',
  payment_error: 'Betalingsfeil',
  subscription_waiting_message: 'Betalingen er under behandling',
  success_message: 'Abonnementet ble kansellert.',
  reactivate_success_message: 'Abonnementet er reaktivert',
  choose_plan: 'Velg en plan før du fortsetter',
  canceling_waite_message: 'Avbryter abonnementet, vennligst vent...',
  subscription_overview: 'Abonnementsoversikt',
  members: 'Medlemmer',
  billing_cycle: 'Faktureringssyklus',
  amount: 'Beløp',
  cancelSubscriptionInfo:
    'Ved å kansellere {planName}-abonnementet ditt, vil du miste tilgang til alle funksjoner knyttet til det. Din nåværende faktureringssyklus vil ende på abonnementets sluttdato, og du vil ikke bli belastet ytterligere. Vennligst merk at data knyttet til premiumfunksjoner kan bli utilgjengelige. Du kan når som helst abonnere på nytt.',
  confirm_billing_cycle: 'Bekreft valg',
  different_cycle: 'Velg en annen faktureringssyklus',
  update_billing: 'Faktureringssyklusen er oppdatert',
  waiting_update_billing_cycle:
    'Bare et øyeblikk, vi oppdaterer faktureringssyklusen din...',
  add_members_message: 'Medlemmer er lagt til',
  remove_members_message: 'Medlemmer er fjernet',
  your_current_plan: 'Din nåværende plan',
  upgrade_to: 'Oppgrader til {planName}',
  downgrade_to: 'Nedgrader til {planName}',
  members_removed: 'Abonnementsmedlemmer er fjernet',
  invitation_removed: 'Abonnementsinvitasjon er fjernet',
  settings_gender: 'Kjønn',
  mann: 'mann',
  kvinne: 'kvinne',
  annen: 'annet',
  life_page_description:
    'Her finner du alle gravene du er tilknyttet og din egen livsside.',
  settings: 'Innstillinger',
  settings_description:
    'Din kontoinformasjon som passord, medlemskap og personlige opplysninger.',
  logout: 'Logg ut',
  logout_description:
    'Takk for at du bruker Nettgrav. Vi gleder oss til å se deg igjen.',
  create_new_grave: 'Opprett ny grav',
  create_grave_description: 'Opprett en ny Nettgrav',
  new: 'Ny',
  read: 'Lest',
  empty_notification_message: 'Ingen nye varsler',
  public_anniversaries: 'Offentlige minnedager',
  grave_anniversaries: 'Personlige minnedager',
  public_anniversary: 'Offentlig minnedag',
  grave_anniversary: 'Personlig minnedag',
  anniversary: 'Merkedag',
  anniversary_delete: 'Minnedagen har blitt slettet.',
  anniversary_created: 'Minnedagen har blitt opprettet.',
  anniversary_updated: 'Minnedagen har blitt oppdatert.',
  anniversary_time_end: 'Minnedagen er over',
  empty_anniversaries: 'Ingen merkedager er opprettet ennå.',
  create: 'Opprett',
  add_dates: 'Opprett personlig merkedag',
  date: 'Dato',
  time: 'Tid',
  title: 'Tittel',
  reset_time: 'Nullstill tid',
  reset_date: 'Tilbakestill dato',
  home: 'Hjem',
  about: 'Om',
  howTo: 'Hvordan',
  plans: 'Planer',
  find_a_grave: 'Finn en grav',
  create_grave: 'Opprett ny grav',
  take_control: 'Ta eierskap over graven',
  pricing: 'Priser',
  get_started: 'Kom i gang',
  create_new_account:
    'En Nettgrav konto er alt man trenger for graver og gode gnister',
  did_you_know: 'Visste du at',
  registration_description_one:
    'Nettgrav er et alternativ eller et supplement til den fysiske graven.',
  registration_description_two:
    'Et alternativ for de som ikke synes den fysiske graven representerer den avdøde',
  registration_description_three:
    'Eller et supplement for de som ønsker å bruke Nettgrav som en forlengelse av graven på kirkegården',
  enter_your_password: 'Skriv inn passordet ditt',
  confirm_your_password: 'Bekreft passordet ditt',
  type_your_email: 'Skriv inn e-postadressen',
  type_email: 'Skriv inn e-post',
  hi: 'Hei {name}',
  decline: 'Avslå',
  accept: 'Godta',
  read_only: '(Skrivebeskyttet)',
  accepted_message: 'Invitasjon akseptert',
  invited_title: 'Du er invitert til et abonnement',
  open: 'Åpne',
  subscription_invitation_message:
    'Du er invitert til {subscription}-abonnementet fra {name}',
  email_error: 'Vennligst skriv inn en gyldig e-postadresse',
  password_length:
    'Passordet må være på minst 8 tegn og inneholde store bokstaver, små bokstaver og tall.',
  filled_out: 'Alle felt må fylles ut',
  must_agree: 'Du må godta vilkårene og betingelsene',
  inactive: 'Inaktiv',
  delete_payment_method: 'Sletter betalingsmåte, vennligst vent...',
  payment_method_deleted: 'Betalingsmåten er slettet',
  payment_method_inactive:
    'Din nåværende betalingsmåte er enten slettet eller ikke lenger aktiv.',
  subscription_changes_title:
    'Du har bedt om følgende endringer i abonnementet',
  period: 'Du ba om å endre faktureringssyklusen fra {previous} til {new}',
  user_count: 'Du ba om å redusere medlemmer fra {previous} til {new}',
  product_id: 'Du ba om å nedgradere planen fra {previous} til {new}',
  remove_subscription_change: 'Abonnementsendringen tilbakestilles',
  remove_subscription_loading:
    'Vennligst vent, forespørselen om å slette endringer pågår...',
  revert: 'Gå tilbake',
  subscription_changes_subTitle:
    'Disse endringene vil bli effektuert etter at din nåværende faktureringssyklus avsluttes den {date}',
  add_new_paymentMethod: 'Legg til ny betalingsmåte',
  create: 'Opprett',
  filled_out_info:
    'Sørg for at du har fylt ut alle feltene i skjemaet før du fortsetter.',
  continue_new_card:
    'Velg en betalingsmetode eller legg til et nytt kort for å fortsette.',
  create_payment_method_waiting: 'Oppretter betalingsmåte. Vennligst vent...',
  login_description: 'Logg inn og gjør verden til et bedre sted.',
  login_with_email: 'Logg inn med e-post',
  login_with_phone: 'Logg inn med telefonnummer',
  account_created: `Velkommen. Din konto er opprettet.`,
  verify_account: 'Pålogging mislyktes på grunn av ubekreftet konto',
  verification_message: `Det ser ut til at e-postadressen din ikke er bekreftet ennå. Vennligst sjekk innboksen din for en bekreftelses-e-post og følg instruksjonene for å fullføre prosessen. Hvis du ikke finner e-posten, sjekk søppelpost- eller spam-mappen din, eller be om en ny bekreftelses-e-post.`,
  resend_email: 'Send bekreftelses-e-post på nytt',
  sending_verification_email:
    'Vennligst sjekk postkassen din for en bekreftelses-e-post',
  sending_verification_code:
    'En bekreftelseskode er sendt til telefonnummeret ditt.',
  email_verification: `Vi har sendt en bekreftelses-e-post. Sjekk innboksen din og klikk på lenken for å bekrefte e-posten din`,
  signup_message_heading: 'Suksess! Du er nesten der.',
  signup_message_subheading: 'Takk for at du registrerte deg, {name}!',
  signup_first_message:
    'Vi er glade for å ha deg med oss. Før du kan begynne å utforske alt Nettgrav har å tilby, må vi sørge for at det virkelig er deg. Vi har akkurat sendt en e-post til {email} med en lenke for å bekrefte kontoen din.',
  signup_second_message:
    'Neste steg: Sjekk innboksen din (og kanskje søppelpost-/spam-mappen, bare for sikkerhets skyld) og klikk på lenken i e-posten. Når du har bekreftet e-posten din, vil du være klar til å logge inn og begynne å bruke kontoen din.',
  signup_third_message:
    'Inntil da er kontoen din i et slags "venterom", så du vil ikke kunne logge inn ennå. Ikke bekymre deg, det er bare et raskt klikk unna!',
  signup_forth_message:
    'Hvis du ikke ser e-posten eller trenger hjelp, er du velkommen til å ta kontakt. Vi er her for å hjelpe.',
  signup_fifth_message: 'Vi gleder oss til du kommer i gang!',
  cheers: 'Hilsen',
  the_team: 'Nettgrav-teamet',
  waiting_for_account: `Vi oppretter kontoen din. Vennligst vent et øyeblikk...`,
  account_verification_waiting:
    'Vennligst vent, kontobekreftelsen din er i gang.',
  account_verification_heading: 'Kontoen din er bekreftet',
  account_verification_heading_error: 'Kontobekreftelse mislyktes',
  account_verification_description: 'Kontoen din har blitt bekreftet.',
  account_verification_description_error:
    'Det oppsto et problem med å bekrefte kontoen din. Vennligst prøv igjen, eller kontakt oss for hjelp.',
  update_payment_method_waiting: 'Oppdaterer betalingsmåte. Vennligst vent...',
  update_payment_method_success: 'Betalingsmåten er oppdatert',
  warning_payment_method: 'Velg en annen betalingsmåte eller legg til en ny',
  plans_card_payment_method: 'Hvis du ønsker å endre betalingsmåte, trykk:',
  forgot_password_heading: 'Glemt passordet ditt?',
  forgot_password_description: `Ikke bekymre deg, det skjer med oss alle. Skriv inn e-postadressen din nedenfor, så sender vi deg en lenke for å tilbakestille passordet ditt. Følg instruksjonene i e-posten for å få tilgang til kontoen din igjen.`,
  forgot_password_button: 'Send tilbakestillingslenke',
  forgot_password_message: `Vi har sendt en lenke for tilbakestilling av passord til e-postadressen du oppga. Vennligst sjekk innboksen din for å tilbakestille passordet ditt. Hvis du ikke finner den, husk å sjekke søppelpost- eller spam-mappen.`,
  forgot_password_title: 'Tilbakestillingslenke sendt',
  forgot_password_waiting:
    'Vennligst vent mens vi sender tilbakestillingslenken for passordet. Dette kan ta noen øyeblikk.',
  set_new_password: 'Opprett et nytt passord',
  set_new_password_description:
    'Vennligst skriv inn et nytt passord for kontoen din. Sørg for at det er sterkt og sikkert. Etter å ha bekreftet, kan du logge inn med ditt nye passord.',
  set_new_password_error: 'Vennligst skriv inn passordet ditt',
  set_new_password_success: 'Passordet ditt har blitt oppdatert',
  set_new_password_waiting:
    'Vennligst vent mens vi oppdaterer passordet ditt. Dette tar bare et øyeblikk.',
  action_required: 'Handling kreves: ',
  email_verification_alert: `Legg til og verifiser e-posten for å fortsette å logge inn med e-post eller telefon. Kontoer uten en verifisert e-postadresse vil ikke kunne logge inn.`,
  goTo_settings_page: 'Gå til innstillingssiden',
  go_to_home_page: 'Gå til hjemmesiden',
  verify_now: 'Bekreft nå',
  authCode_verified: 'Telefonnummeret er bekreftet',
  personal_information_updated: 'Personlig informasjon oppdatert',
  reset_date: 'Tilbakestill dato',
  sending_verification_waiting:
    'En bekreftelseskode har blitt sendt til telefonnummeret ditt. Vennligst vent...',
  sending_verification_email_waiting:
    'En bekreftelseslenke er sendt til e-posten din. Vennligst vent...',
  local_name: 'Lokalt navn',
  create_anniversary_waiting: 'opprett jubileum pågår vennligst vent...',
  hello: 'Hei',
  coins: 'Mynter',
  set_color: 'Hva er din farge i dag?',
  set_color_button: 'Still inn fargen din',
  sparks: 'Gnister',
  community: 'Fellesskap',
  purpose: 'Hensikt',
  talents: 'Talenter',
  profile_image_changed: 'Bildet ble oppdatert',
  waiting_profile_upload: 'profilbilde oppdateres vennligst vent...',
  graves: 'Graver',
  wisdom: 'Visdom',
  sparks_description:
    'Send en vekkende gnist gjennom hele samfunnet og lær noe nytt!',
  text_area_placeholder: 'Hvordan vil du gjøre verden litt bedre?',
  crop_image: 'Beskjær bildet',
  create_anniversary_waiting: 'Oppretter merkedag, vennligst vent...',
  create_a_grave: 'Lag en grav',
  recurring_anniversary: 'Gjenta hvert år',
  nick_name: 'Kallenavn',
  birth_name: 'Fødselsnavn',
  date_of_birth: 'Fødselsdato',
  date_of_death: 'Dødsdato',
  time_of_birth: 'Fødselstidspunkt',
  time_of_death: 'Dødstidspunkt',
  place_of_birth: 'Fødested',
  place_of_death: 'Dødssted',
  cause_of_death: 'Dødsårsak',
  municipality: 'Kommune',
  country: 'Land',
  cemetery: 'Gravlund',
  grave_number: 'Gravnummer',
  grave_info_updating: 'Oppdaterer gravinformasjon. Vennligst vent...',
  deceased_name: 'Oppgi navnet på den avdøde/gravlagte',
  deceased_placeholder: 'Navn på den avdøde',
  deceased_gender: 'Avdødes kjønn',
  i_am: 'Jeg er',
  buried_is_mine: 'Gravlagte er min',
  select_relationship: 'Skriv inn eller trykk for å velge relasjon',
  upload_document: 'Last opp dokument',
  upload_document_description:
    'Last opp et dokument som validerer eierskapet til graven.',
  death_certificate: 'Dødsattest',
  birth_certificate: 'Fødselsattest',
  invoice: 'Faktura for stell/feste av grav',
  power_of_attorney: 'Brev fra advokatfullmektig',
  driving_license: 'Avdødes ID',
  proof_of_person: 'Last opp dokumentasjon for personen som gravsiden gjelder',
  proof_of_person_description:
    'Dokumentet kan være i PDF-, PNG- eller JPG-format og være mindre enn 5 MB.',
  replace_files: 'Erstatt filer',
  browse_files: 'Bla gjennom filer',
  drag_image: 'eller dra og slipp filen her',
  dear: 'Kjære',
  new_grave_first_description:
    'Velkommen til Nettgrav. Vårt mål er å tilby et meningsfullt og trygt gravsted på nett. Derfor er det viktig for oss å sikre at de som forvalter disse nettgravene, faktisk er de rettmessige eierne eller autoriserte representantene.',
  new_grave_second_description:
    'Når du oppretter et gravsted på nett, bekrefter du din status som graveier eller godkjent representant. Ved å fortsette med å opprette en Nettgrav, bekrefter du at du har myndighet til dette.',
  new_grave_third_description:
    'Vennligst les og godta følgende: "Jeg bekrefter at jeg er den rettmessige eieren eller en godkjent representant for den gravlagte som jeg oppretter en Nettgrav for. Jeg forstår at ved å gå videre med denne handlingen, får jeg digitalt eierskap til graven. Jeg er klar over at ethvert misbruk eller uriktig fremstilling kan føre til fjerning av den gravlagte på Nettgrav.no.',
  new_grave_fourth_description:
    'Klikk "Godta" for å bekrefte din forståelse og fortsette. Takk for at du bidrar til å opprettholde integriteten og respekten for de gravlagte og alle som har tillit til Nettgrav.no.',
  new_grave_agree_terms: 'Jeg godtar de ovennevnte vilkårene',
  enter_name_error: 'Vennligst oppgi personens navn',
  select_gender_error: 'Vennligst velg den avdødes kjønn',
  select_relation_error: 'Vennligst velg din relasjon til den avdøde',
  select_date_error: 'Vennligst velg en dato',
  select_reason_error: 'Vennligst velg en dødsårsak',
  reason_of_death: 'Dødsårsak',
  other_reason: 'Skriv inn en annen årsak',
  other_reason_error: 'Vennligst skriv inn annen dødsårsak',
  select_country: 'Velg land',
  select_municipality: 'Velg kommune',
  select_cemetery: 'Velg kirkegård',
  create_the_grave: 'Opprett graven',
  create_the_message: 'Oppretter grav, vennligst vent....',
  death_date_error: 'Vennligst velg en gyldig dødsdato',
  birth_date_error: 'Vennligst velg en gyldig fødselsdato',
  month_0: 'Januar',
  month_1: 'Februar',
  month_2: 'Mars',
  month_3: 'April',
  month_4: 'Mai',
  month_5: 'Juni',
  month_6: 'Juli',
  month_7: 'August',
  month_8: 'September',
  month_9: 'Oktober',
  month_10: 'November',
  month_11: 'Desember',
  weekday_0: 'Søndag',
  weekday_1: 'Mandag',
  weekday_2: 'Tirsdag',
  weekday_3: 'Onsdag',
  weekday_4: 'Torsdag',
  weekday_5: 'Fredag',
  weekday_6: 'Lørdag',
  father: 'Far',
  mother: 'Mor',
  son: 'Sønn',
  husband: 'Ektemann',
  wife: 'Kone',
  brother: 'Bror',
  sister: 'Søster',
  grandfather: 'Bestefar',
  grandmother: 'Bestemor',
  great_grandfather: 'Oldefar',
  great_grandmother: 'Oldemor',
  grandson: 'Barnebarn (gutt)',
  granddaughter: 'Barnebarn (jente)',
  uncle: 'Onkel',
  uncle_child: 'Søskenbarn',
  aunt: 'Tante',
  aunt_child: 'Søskenbarn',
  nephew: 'Nevø',
  niece: 'Niese',
  stepbrother: 'Stebror',
  stepsister: 'Stesøster',
  stepfather: 'Stefar',
  stepmother: 'Stemor',
  stepson: 'Stesønn',
  stepdaughter: 'Stedatter',
  father_in_law: 'Svigerfar',
  mother_in_law: 'Svigermor',
  brother_in_law: 'Svoger',
  sister_in_law: 'Svigerinne',
  son_in_law: 'Svigersønn',
  daughter_in_law: 'Svigerdatter',
  boyfriend: 'Kjæreste (mann)',
  girlfriend: 'Kjæreste (kvinne)',
  adopt_son: 'Adoptivsønn',
  adopt_daughter: 'Adoptivdatter',
  friends: 'Venner',
  colleagues: 'Kollegaer',
  friend: 'Venn',
  other: 'Annet',
  daughter: 'Datter',
  partner: 'Partner',
  colleague: 'Kollega',
  great_granddaughter: 'Oldebarn (jente)',
  great_grandson: 'Oldebarn (gutt)',
  great_grandmother: 'Oldemor',
  great_grandfather: 'Oldefar',
  great_great_granddaughter: 'Tippoldebarn (jente)',
  great_great_grandson: 'Tippoldebarn (gutt)',
  great_great_grandmother: 'Tippoldemor',
  great_great_grandfather: 'Tippoldefar',
  great_great_great_granddaughter: 'Tipp-tippoldebarn (jente)',
  great_great_great_grandson: 'Tipp-tippoldebarn (gutt)',
  great_great_great_grandmother: 'Tipp-tippoldemor',
  great_great_great_grandfather: 'Tipp-tippoldefar',
  cohabitant: 'Samboer',
  friend: 'Venn',
  free_description: 'gir deg muligheten til å opprette og søke etter graver',
  conscious_description:
    'gir deg full tilgang til et bredt spekter av funksjoner, som Minnealbum, Verdiarkiv og Livsarkiv, slik at du kan skape en levende grav som er tilgjengelig når som helst.',
  consciousPlus_description:
    'Tilgang som Bevisst, med ekstra trykk på gravens etikk.',
  feature_life_archive: 'Livsarkiv',
  feature_anniversary_calendar: 'Merkedagskalender',
  feature_memory_wall: 'Minnetavle',
  feature_value_archive: 'Verdiarkiv',
  contact_person: 'Din kontaktperson',
  invited_person_name: 'Invitert persons navn',
  monthly: 'Månedlig',
  semi_annual: 'Halvårlig',
  annually: 'Årlig',
  biennial: 'Annenhvert år',
  auth_modal_description: `Opprett en gratis konto eller logg inn nå for å finne og besøke gravstedene du leter etter, samt oppdage flere funksjoner.`,
  auth_modal_heading: 'Registrer / Logg inn',
  complete_pending_payment: `Fullfør betalingsprosessen.`,
  subscription_base_price: 'Basispris for abonnement',
  membership: 'medlemskap',
  relation_to_grave: `Gravlagtes relasjon til personen du inviterer`,
  relation_to_person: 'Invitert person sin relasjon til gravlagte',
  administrator_access: 'Administratortilgang',
  select_role: 'Velg rolle',
  administrator: 'Ja, sett som administrator',
  normal: 'Nei, ikke sett som administrator',
  select_role_error: 'Vennligst velg brukerrollen',
  user_name: 'Brukernavn',
  remove_all_grave_user: 'Viktig melding: Oppdatering av gravforvaltning',
  remove_all_grave_user_message:
    'Du kan ikke fjerne alle medlemmer eller administratorer fra graven. Hvis du ønsker å fjerne alle medlemmer, må du enten overføre eierskapet til graven eller slette den helt. En grav må alltid være knyttet til en bruker eller administrator for riktig forvaltning.',
  send_it: 'Send den',
  public_view: 'Offentlig visning',
  constellation: 'Konstellasjon',
  moons_only: 'Bare måner',
  question: 'spørsmål',
  insight: 'innsikt',
  experience: 'erfaring',
  discovery: 'oppdagelse',
  feeling: 'følelse',
  value: 'verdi',
  energy: 'energi',
  select_category: 'Velg kategori',
  spark_delete: 'Gnisten ble ødelagt',
  spark_create: 'Spark opprettet vellykket',
  spark_update: 'Spark-oppdateringen er vellykket',
  ENG: 'Engelsk',
  NOR: 'Norsk',
  added_members: 'Medlemmer',
  removed_members: 'Antall medlemmer som skal fjernes',
  allowed_members: 'Tillatt antall medlemmer',
  loading: 'Laster...',
  in_your_mind: `Hvilken gnist har du i deg, {name}?`,
  create_spark: 'Skap gnist',
  empty_graves: 'Ingen tilknyttet graver. Søk på navn for å finne gravene.',
  all: 'Alle',
  owner: 'Eier',
  administrator: 'Administrator',
  affiliated: 'Tilknyttet',
  view_all: 'Vis alle',
  close_relation: 'Dine nærmeste relasjoner kommer her',
  no_relation_set: 'Ingen relasjoner er satt',
  love_ones: '{name} sine nærmeste',
  set_relation: 'Sett relasjon',
  relation_request_send: 'Din relasjonsforespørsel har blitt sendt.',
  relation_connection:
    '{name} er min {reciprocalRelation}, og jeg er {relation} til {name}.',
  request_send: 'Forespørsel er sendt',
  empty_sparks:
    'Ingen Gnister enda. Send en Gnist som kan inspirere til refleksjon og samhold.',
  relatives: 'Slektninger',
  account_heading: 'Sletting av Nettgrav-kontoen din',
  delete_account: 'Slett konto',
  delete_account_pending: 'Kontosletting pågår',
  deleted_message_pending:
    'Forespørselen om å slette kontoen din er sendt. All tilhørende data vil bli permanent slettet fra våre servere. Du kan fortsatt avbryte forespørselen om sletting før kl. 23:50 i dag. Hvis du ønsker å avbryte forespørselen, eller har spørsmål eller bekymringer, vennligst kontakt vår kundestøtte.',
  deleted_message:
    'Slett kontoen din og all tilhørende data permanent fra våre servere. Denne handlingen kan ikke angres etter at angrefristen er utløpt. Du kan avbryte forespørselen om sletting når som helst før kl. 23:50 samme dag som forespørselen ble gjort. Hvis du har spørsmål eller bekymringer, vennligst kontakt vår kundestøtte.',
  confirm_delete_account: 'Er du sikker på at du vil slette kontoen din?',
  confirm_cancel_account:
    'Er du sikker på at du vil avbryte forespørselen om å slette kontoen din?',
  phone_number_error: 'Telefonnummer/Passord stemmer ikke',
  email_error_login: 'E-post/Passord stemmer ikke',
  set_relation_free_heading:
    'Sett relasjonen til {grave} og aktiver medlemskap',
  set_relation_heading: 'Bli med på siden',
  set_relation_free_description: `Ved å bekrefte din relasjon til {grave} sørger du for at graven er nær når du ønsker det. Din knytning viser ditt engasjement for ivaretakelse av gravlagte og alle berørte. Relasjoner åpner opp og ivaretar graven med:`,
  set_relation_description:
    'Send inn forespørsel om å bli med siden som en slektning, venn eller en velønsker',
  set_relation_info_message: `Når du har satt din relasjon, blir graven lagret på din livsside. Medlemskapet ditt dekker alle graver i vårt fellesskap.`,
  feature_title_1: 'Minnealbum',
  feature_description_1: 'Last opp og del kjære bilder.',
  feature_title_2: 'Minnetavle',
  feature_description_2: 'Se og bidra med minner fra andre.',
  feature_title_3: 'Merkedagskalender',
  feature_description_3: 'Motta påminnelser om spesielle datoer.',
  feature_title_4: 'Verdiarkiv',
  feature_description_4: 'Bevar viktige verdier og lærdommer.',
  feature_title_5: 'Livsarkiv',
  feature_description_5: 'Dokumenter viktige livsøyeblikk.',
  choose_relation: 'Velg relasjon her',
  relation_label: 'Vennligst velg din relasjon til {grave}',
  reciprocal_label: 'Vennligst velg {grave}s relasjon til deg',
  set_relation_note: `Merk at ved å bli med på siden godtar du å følge og overholde reglene satt av administratoren for graven.`,
  set_relation_other:
    'For andre bekjente av den gravlagte, som kanskje ikke er kjent for graveieren.',
  set_relation_placeholder: `Eksempel, jeg var venn med {grave} på universitetet i Oslo...`,
  confirm_relation_member: 'Bekreft relasjon og bli medlem',
  confirm_membership: 'Bekreft medlemskap',
  enlightened: 'Opplyst',
  inspired: 'Inspirert',
  reflected: 'Reflektert',
  comments: 'kommentarer',
  comment: 'Kommentar',
  empty_comments: 'Ingen kommentarer funnet',
  comment_placeholder: 'skriv kommentar...',
};

export default NOR;
