import React from 'react';
import styles from './TermsAndCondition.module.css';
import { useSelector } from 'react-redux';

const TermsAndCondition = ({ user, isCheckedTerms, isChecked }) => {
  return (
    <div>
      <div className={styles['terms']}>
        <div className={styles['name']}>Kjære {user ? user.full_name : ''}</div>
        <div className={styles['text']}>
          Velkommen til Nettgrav. Siden vi har som mål å tilby et meningsfullt
          og respektfullt digitalt rom for å hedre våre avdøde kjære, er det
          viktig for oss å sikre at individene som forvalter disse nettgravene
          faktisk er de rettmessige eierne eller godkjente representantene.
        </div>
        <div className={styles['text']}>
          Vennligst forstå at når du oppretter et digitalt gravsted på
          plattformen vår, hevder du din status som graveier eller godkjent
          representant. Ved å gå videre med kravet om digital grav bekrefter du
          at du har myndighet til å gjøre det.
        </div>
        <div className={styles['text']}>
          Vennligst les og godta følgende: "Jeg bekrefter at jeg er den
          rettmessige eieren eller en godkjent representant for graven som jeg
          oppretter på Nettgrav. Jeg forstår at ved å gå videre med denne
          handlingen, får jeg digitalt eierskap til graven. Jeg er klar over at
          ethvert misbruk eller uriktig fremstilling kan føre til fjerning av
          den digitale graven fra Nettgrav.
        </div>
        <div className={styles['text']}>
          Klikk "Godta" for å bekrefte forståelsen og fortsette. Takk for
          samarbeidet for å opprettholde integriteten og respekten til
          plattformen vår.
        </div>
      </div>
      <div className={styles['accept-container']}>
        <input
          type='checkbox'
          onChange={() => isCheckedTerms(!isChecked)}
          checked={isChecked}
        />
        <div className={styles['label']}>Jeg godtar vilkårene ovenfor</div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
