import React, { useEffect, useState } from 'react';
import styles from './GraveAdministrator.module.css';
import EditSmallIcon from '../../../../img/EditSmallIcon.svg';
import { useSelector } from 'react-redux';
import Admin from './Admin/Admin';
import GraveAdministratorModal from './GraveAdministratorModal/GraveAdministratorModal';

const GraveAdministrator = () => {
  const grave = useSelector((state) => state.grave.grave);
  const [admins, setAdmins] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [graveOwner, setGraveOwner] = useState(false);
  const currentUser = useSelector((state) => state.user.user);

  useEffect(() => {
    if (grave.grave_users.length > 0) {
      const admins = grave.grave_users.filter((user) => user.admin);
      const user = grave.grave_users.find(
        (user) => user.user_id === currentUser.id
      );
      setAdmins(admins);
      if (user && user.grave_owner) {
        setGraveOwner(true);
      } else {
        setGraveOwner(false);
      }
    }
  }, [grave]);

  return (
    <div className={styles['administrator-page']}>
      <div className={styles['heading-wrapper']}>
        <div className={styles['heading']}>Administratorer</div>
        {graveOwner && (
          <div
            className={styles['action-btn']}
            onClick={() => setOpenModal(true)}
          >
            <img src={EditSmallIcon} alt='Edit' />
            <button>Redigere</button>
          </div>
        )}
      </div>
      <div className={styles['sub-heading']}>
        Administratorer har full tilgang til å endre sensitiv informasjon på graven.
        De kan godkjenne eller avslå innkommende relasjoner, samt administrere oppdateringer på graven.
      </div>
      <div className={styles['admin-wrapper']}>
        {admins.length > 0 &&
          admins.map((user, index) => {
            return <Admin user={user} key={index} />;
          })}
      </div>
      {openModal && (
        <GraveAdministratorModal
          onOpen={openModal}
          onClose={() => setOpenModal(false)}
          admins={admins}
        />
      )}
    </div>
  );
};

export default GraveAdministrator;
