import React, { useState } from 'react';
import styles from './CommentInput.module.css';
import { Avatar } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import RichTextEditor from '../../../../../../../components/RichTextEditor/RichTextEditor';

const CommentInput = ({
  handelCreateComment,
  spark,
  description = '',
  saveEditComment,
  isEdit = false,
  onCloseEdit,
}) => {
  const user = useSelector((state) => state.user.user);
  const [comment, setComment] = useState(description || null);

  const onSend = () => {
    if (comment) {
      if (!isEdit) {
        handelCreateComment(comment, spark.id);
        setComment(null);
      } else {
        saveEditComment(comment);
      }
    }
  };

  const setCommentVale = (value) => {
    setComment(value);
  };

  return (
    <div className={styles['container']}>
      <Avatar src={user?.avatar} className={styles['avatar']} />
      <div style={{ width: '100%' }}>
        <div className={styles['input-container']}>
          <RichTextEditor
            placeholderKey='comment_placeholder'
            value={comment}
            onInput={setCommentVale}
            editable={true}
          />
          <div className={styles['send-icon']}>
            <SendIcon
              style={{
                color: '#fff',
                fontSize: '18px',
                cursor: comment ? 'pointer' : 'not-allowed',
              }}
              onClick={onSend}
            />
          </div>
        </div>
        {isEdit && (
          <div className={styles['cancel']} onClick={onCloseEdit}>
            <FormattedMessage id='settings_cancel' />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentInput;
