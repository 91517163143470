import styles from './Comment.module.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Avatar, Button, Fade, Menu, MenuItem } from '@mui/material';
import Dots from '../../../img/DotsMenu.svg';
import Reply from '../../../img/Reply.svg';
import ReplyCreateForm from '../ReplyCreateForm';
import ReplyComponent from '../Reply';
import { formatDistanceToNow } from 'date-fns';
import { Link } from 'react-router-dom';
import MenuItemBlock from '../../../containers/NewDeceased/atoms/MemoriesPostDetails/icon/MenuItemBlock';
import MenuItemBlockLast from '../../../containers/NewDeceased/atoms/MemoriesPostDetails/icon/MenuItemBlockLast';
import EditPen from '../../../img/EditPen.svg';
import MenuBin from '../../../img/MenuBin.svg';

const Comment = ({
  comment,
  onCommentEdit,
  onCommentDelete,
  onReplyCreate,
  onReplyEdit,
  onReplyDelete,
}) => {
  const commentAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find((u) => u.user_id === comment.user_id)
  );
  const userId = useSelector((state) => state.user.user.id);
  const [menuAnchorElem, setMenuAnchorElem] = useState(null);
  const [edit, setEdit] = useState(false);
  const [newValueOfComment, setNewValueOfComment] = useState(
    comment.description ? comment.description : ''
  );
  const [text, setText] = useState('');
  const [showReplies, setShowReplies] = useState(false);
  const commentTime = comment.updated_at
    ? comment.updated_at
    : comment.created_at
    ? comment.created_at
    : '';
  const toggleReplies = () => setShowReplies(!showReplies);

  const handleOpenEdit = () => setEdit(true);
  const handleCloseEdit = () => setEdit(false);

  const handleOpenMenu = (e) => setMenuAnchorElem(e.currentTarget);
  const handleCloseMenu = () => setMenuAnchorElem(null);

  const openEdit = () => {
    handleOpenEdit();
    handleCloseMenu();
  };

  useEffect(() => {
    setText(comment.description);
  }, []);

  const editComment = (e) => {
    try {
      e.preventDefault();
      setText(newValueOfComment);
      onCommentEdit(newValueOfComment);
      handleCloseEdit();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteComment = () => {
    try {
      onCommentDelete();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className={styles['memories_comment']}>
      <div className={styles['memories_comment-content']}>
        <div className={styles['memories-avatar-wrapper']}>
          {commentAuthor?.user_avatar ? (
            <Avatar src={commentAuthor?.user_avatar} />
          ) : (
            <Avatar>{commentAuthor?.user_name?.charAt(0)}</Avatar>
          )}
        </div>
        <div className={styles['memories_comment-content_main']}>
          <div className={styles['memories_comment-content_main-white']}>
            <div className={styles['memories_comment-content_main-header']}>
              {commentAuthor?.slug ? (
                <Link to={`/member/${commentAuthor.slug}`}>
                  <span
                    className={
                      styles['memories_comment-content_main-header_user']
                    }
                  >
                    {commentAuthor?.user_name}
                  </span>
                </Link>
              ) : commentAuthor?.user_name ? (
                <span
                  className={
                    styles['memories_comment-content_main-header_user']
                  }
                >
                  {commentAuthor?.user_name}
                </span>
              ) : null}
              <span
                className={styles['memories_comment-content_main-header_time']}
              >
                {commentTime
                  ? formatDistanceToNow(Date.parse(commentTime), {
                      addSuffix: true,
                    })
                  : ''}
              </span>
              {(userId === comment.user_id ||
                comment.can_destroy ||
                comment.can_edit) && (
                <Button
                  className={
                    styles['memories_comment-content_main-header_dots']
                  }
                  onClick={handleOpenMenu}
                >
                  <img loading='lazy' src={Dots} alt='' />
                </Button>
              )}
            </div>
            <div className={styles['memories_comment-content_main-text']}>
              {!edit && <span>{text}</span>}
              {edit && (
                <form
                  onSubmit={editComment}
                  className={styles['music-post_content-title_form']}
                >
                  <textarea
                    value={newValueOfComment}
                    placeholder='Skriv her'
                    onChange={(e) => setNewValueOfComment(e.target.value)}
                    className={
                      styles['music-post_content-title_textarea_editing']
                    }
                  />
                  <div className={styles['grave-btn-container']}>
                    <div className={styles['grave-information_content-verify']}>
                      <button
                        type='button'
                        className={styles['rootDiscard']}
                        onClick={() => {
                          handleCloseEdit();
                        }}
                      >
                        Avbryt
                      </button>
                      <button className={styles['rootVerify']} type='submit'>
                        Lagre
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
          <div className={styles['memories_comment-content_main-options']}>
            <Button
              onClick={toggleReplies}
              className={styles['memories_comment-content_main-options_button']}
            >
              <img
                loading='lazy'
                src={Reply}
                alt=''
                className={
                  styles['memories_comment-content_main-options_button-image']
                }
              />
              Svar
            </Button>
            {comment.replies?.length > 0 && (
              <span onClick={toggleReplies}>{comment.replies.length} Svar</span>
            )}
          </div>
          {showReplies && (
            <>
              <ReplyCreateForm onCreate={onReplyCreate} />
              {comment.replies?.map((rep, i) => {
                return (
                  <ReplyComponent
                    reply={rep}
                    key={rep.id}
                    onEdit={onReplyEdit}
                    onDelete={onReplyDelete}
                  />
                );
              })}
            </>
          )}
        </div>
      </div>
      <Menu
        id='fade-menu'
        anchorEl={menuAnchorElem}
        keepMounted
        open={Boolean(menuAnchorElem)}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {comment.can_edit && (
          <MenuItem onClick={openEdit}>
            <MenuItemBlock
              image={EditPen}
              title='Rediger'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
        {comment.can_destroy && (
          <MenuItem onClick={deleteComment}>
            <MenuItemBlockLast
              image={MenuBin}
              title='Slett'
              description='Legg til, endre eller fjern tekst'
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default Comment;
