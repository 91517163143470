import React, { useState, useEffect } from 'react';
import TotalReactions from '../../Reactions/TotalReactions/TotalReactions';
import TotalComments from '../../Comments/TotalComments/TotalComments';
import styles from './SparkFooter.module.css';
import FooterButtons from './FooterButtons';
import CommentsDrawer from '../../Comments/CommentsDrawer/CommentsDrawer';
import { useCreateCommentMutation } from '../../../../../../../graphql/generated/sparkHooks.ts';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSparksComments,
  setSparks,
} from '../../../../../../../redux/actions.js';
import CommentsModal from '../../Comments/CommentsModal/CommentsModal.jsx';

const SparkFooter = ({
  spark,
  handelReaction,
  reactionList,
  handelRemoveReaction,
  handelUpdateReaction,
  sparkCategories,
  user,
  renderSparkModal,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const sparksComments = useSelector((state) => state.spark.sparksComments);
  const sparks = useSelector((state) => state.spark.sparksList);
  const [createComment] = useCreateCommentMutation({
    onCompleted: (data) => {
      if (data && data.createComment?.comment) {
        const newComment = data.createComment.comment;
        const existingSparkComments = sparksComments[spark.id] || {
          comments: {},
        };

        const existingComments = existingSparkComments.comments || {};

        const updatedComments = {
          [newComment.id]: newComment,
          ...existingComments,
        };

        const updatedSparks = [...sparks];
        const index = updatedSparks.findIndex((s) => s.id === spark.id);
        updatedSparks.splice(index, 1, {
          ...spark,
          commentsCount: spark.commentsCount + 1,
        });

        const updatedSparkComments = {
          ...existingSparkComments,
          comments: updatedComments,
        };

        const updatedSparksComments = {
          ...sparksComments,
          [spark.id]: updatedSparkComments,
        };

        dispatch(setSparksComments(updatedSparksComments));
        dispatch(setSparks(updatedSparks));
      }
    },
  });

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 540);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const handelCreateComment = async (description, sparkId) => {
    try {
      await createComment({
        variables: {
          description,
          commentableId: sparkId,
          commentableType: 'Spark',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handelOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handelCloseDrawer = () => {
    setOpenDrawer(false);
  };

  return (
    <React.Fragment>
      <div className={styles['container']}>
        <TotalReactions
          spark={spark}
          reactionList={reactionList}
          isMobile={isMobile}
        />
        <TotalComments
          renderSparkModal={renderSparkModal}
          totalComments={spark.commentsCount}
          handelOpenDrawer={handelOpenDrawer}
        />
      </div>
      <div className={renderSparkModal ? styles['footer-container'] : ''}>
        <FooterButtons
          renderSparkModal={renderSparkModal}
          handelReaction={handelReaction}
          reactionList={reactionList}
          handelOpenDrawer={handelOpenDrawer}
          spark={spark}
          handelRemoveReaction={handelRemoveReaction}
          handelUpdateReaction={handelUpdateReaction}
        />
      </div>
      {openDrawer && isMobile ? (
        <CommentsDrawer
          openDrawer={openDrawer}
          handelCloseDrawer={handelCloseDrawer}
          handelOpenDrawer={handelOpenDrawer}
          spark={spark}
          handelCreateComment={handelCreateComment}
        />
      ) : (
        openDrawer && (
          <CommentsModal
            open={openDrawer}
            handelCloseModal={handelCloseDrawer}
            spark={spark}
            handelCreateComment={handelCreateComment}
            user={user}
            sparkCategories={sparkCategories}
          />
        )
      )}
    </React.Fragment>
  );
};

export default SparkFooter;
