import React, { useEffect, useRef, useState } from 'react';
import showUserNotification from '../../../../../components/UserNotification/showUserNotification';
import styles from './UploadDocumentFile.module.css';
import validateDeathCertificate from '../../../../../service/validateDeathCertificateFile';
import isEmpty from 'lodash.isempty';

const UploadDocumentFile = ({ handelSetDocumentFile, selectedFile }) => {
  const inputFile = useRef();
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const onCertificateChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (validateDeathCertificate(file)) {
        handelSetDocumentFile(file);
        showUserNotification('Dokumentet er lastet opp og lagt til', 'success');
      } else
        showUserNotification(
          'Filtyper vi støtter er: .doc, .pdf, .docx, .odt, .xls, .xlsx, .ods, .txt, .jpg, .png, .jpeg',
          'warning'
        );
    }
  };

  const uploadDeathCertificate = (certificate) => {
    const reader = new FileReader();
    const file = certificate.target.files[0];
    if (file) {
      if (validateDeathCertificate(file)) {
        reader.readAsDataURL(file);
        reader.addEventListener('load', () => {
          handelSetDocumentFile({
            image: reader.result,
            id: Date.now().toString(),
            file: file,
          });
          showUserNotification('Dokumentet ble opplastet', 'success');
        });
      } else
        showUserNotification(
          'Filtyper vi støtter er .doc, .pdf, .docx, .odt, .xls, .xlsx, .ods, .txt, .jpg, .png, .jpeg',
          'warning'
        );
    }
  };

  const clickOnHideInput = () => {
    inputFile.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    uploadDeathCertificate({ target: { files: droppedFiles } });
  };

  return (
    <>
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            ref={inputFile}
            type='file'
            accept='.doc, .pdf, .docx, .odt, .xls, .xlsx, .ods, .txt, .jpg, .png, .jpeg'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={uploadDeathCertificate}
          />
          <div className={styles['upload-image']}>
            <div className={styles['upload-image-text']}>
              Last opp dødsbeviset til personen gravsiden er for.
            </div>
            <div className={styles['upload-image-sub-heading']}>
              Dokumentet kan være PDF-, png-, jpg-format og mindre enn 5 MB
            </div>
            {selectedFile.file?.name && (
              <div className={styles['file-name']}>
                {selectedFile.file.name}
              </div>
            )}
            <button className={styles['file-btn']} onClick={clickOnHideInput}>
              {selectedFile ? `Erstatt filer` : `Bla gjennom filer`}
            </button>
            <div className={styles['image-text-message']}>
              eller Dra og slipp filen her
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadDocumentFile;
