import React, { useState } from 'react';
import Questions from '../../containers/AddNewGrave/Questions';
import AddStepper from '../../containers/AddNewGrave/AddStepper';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import BackIcon from '../../img/BackIcon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import ProcessModal from '../../containers/Payment/atoms/ProcessModal';
import './AddNewGrave.css';

const AddNewGrave = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  return (
    <React.Fragment>
      {loading ? (
        <ProcessModal open={loading} messageKey='create_the_message' />
      ) : (
        <div className='add-new-grave'>
          <div className='header'>
            <div
              className='header-container'
              onClick={() => {
                if (step > 0) {
                  setStep(step - 1);
                } else if (location.key) {
                  navigate(-1);
                } else {
                  navigate(`/graves`);
                }
              }}
            >
              <img src={BackIcon} alt='Back' />
              <FormattedMessage id='auth_back' />
            </div>
          </div>
          {!props.isAuthorized && (
            <Navigate
              to={{
                pathname: '/auth/sign-in',
              }}
            />
          )}
          <AddStepper
            handelIsLoading={(value) => setLoading(value)}
            step={step}
            setStep={setStep}
          />
          <Questions />
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  isAuthorized: state.auth.isAuthorized,
  choosenGrave: state.grave.grave,
});

export default connect(mapStateToProps)(AddNewGrave);
