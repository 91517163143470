import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import { useDispatch } from 'react-redux';
import './SetRelationOrClaimDialog.css';
import { TextField } from '@mui/material';
import { fetch_request } from '../../../../service/request';
import { request } from '../../../../service/request';
import { updateCurrentUserInRelationQueue } from '../../../../redux/actions';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { CONSTANTS } from '../../../../service/constants';
import Input from '../../../../components/Input/Input';
import { FormattedMessage, useIntl } from 'react-intl';
import PrimaryButton from '../../../../components/PrimaryButton/index';
import { useNavigate } from 'react-router-dom';

const RelationTabs = ({ label, onClick, active }) => {
  const buttonClassName = active ? 'active-button' : 'inactive-button';
  return (
    <button className={buttonClassName} onClick={onClick}>
      <FormattedMessage id={label} />
    </button>
  );
};

const SetRelationOrClaimDialog = (props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const { grave, user } = props;
  const [relations, setRelations] = useState([]);
  const [chosenRelation, setChosenRelation] = useState({});
  const [reciprocalRelation, setReciprocalRelation] = useState({});
  const [directionsList, setDirectionsList] = useState([]);
  const [activeTab, setActiveTab] = useState('relative');
  const [friendNote, setFriendNote] = useState('');
  const [buttonText, setButtonText] = useState('confirm_relation_member');

  const updateButtonText = () => {
    if (window.innerWidth > 520) {
      setButtonText('confirm_relation_member');
    } else {
      setButtonText('confirm_membership');
    }
  };

  useEffect(() => {
    updateButtonText();
    window.addEventListener('resize', updateButtonText);

    return () => {
      window.removeEventListener('resize', updateButtonText);
    };
  }, []);

  useEffect(() => {
    request('/relation_directions')
      .then((res) => {
        setDirectionsList(res.data?.list || []);
      })
      .catch((error) => console.log(error));
  }, []);

  const getRelationId = (type) =>
    relations.find((item) => item.key.toLowerCase() === type.toLowerCase())?.id;

  const handleOnSelectRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setChosenRelation({});
    } else if (reason === 'selectOption') {
      setChosenRelation(value);
    }
    setReciprocalRelation({});
  };

  const handleOnSelectReciprocalRelation = (event, value, reason) => {
    if (reason === 'clear') {
      setReciprocalRelation({});
    } else if (reason === 'selectOption') {
      setReciprocalRelation(value);
    }
  };

  const handleSetRelation = async () => {
    const formData = new FormData();
    formData.append('grave_user[grave_id]', grave.id);

    if (activeTab === 'friend' || activeTab === 'other') {
      const relationId = getRelationId(activeTab);
      if (relationId) {
        formData.append('grave_user[relation_id]', getRelationId(activeTab));
        formData.append(
          'grave_user[reciprocal_relation_id]',
          getRelationId(activeTab)
        );
      }
    } else {
      formData.append('grave_user[relation_id]', chosenRelation.id);
      formData.append(
        'grave_user[reciprocal_relation_id]',
        reciprocalRelation.id
      );
    }
    if (activeTab === 'friend') {
      formData.append(
        'grave_user[notes_attributes][][description]',
        friendNote
      );
    }

    await request('/grave_relations', formData, 'post')
      .then((res) => res.data)
      .then((data) => {
        request(`/graves/${grave.slug}`)
          .then((response) => response.data)
          .then((data) => {
            dispatch(updateCurrentUserInRelationQueue(data));
            props.handleCloseRelationDialog();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => {
        showUserNotification('Kan ikke oppdatere relasjonen', 'error');
        console.log(error);
      });
  };

  const filterDirectionList = (selectedRelation) => {
    if (isEmpty(selectedRelation)) return [];

    let filteredList = [];

    directionsList.map((item) => {
      if (item.from_relation.id === selectedRelation.id) {
        filteredList.push(item.to_relation);
      }

      return false;
    });

    if (filteredList.length === 0) {
      return [selectedRelation];
    } else {
      return filteredList;
    }
  };

  let formInputs = [
    {
      label: 'relation_label',
      extraLabels: { grave: grave.name },
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: chosenRelation,
      options: relations,
      autoComplete: 'off',
      error: false,
      errorKey: 'select_relation_error',
      disabled: false,
      labelStyle: {
        fontSize: '18px',
      },
      action: handleOnSelectRelation,
    },
    {
      label: 'reciprocal_label',
      extraLabels: { grave: grave.name },
      type: 'autocomplete',
      placeholder: 'select_relationship',
      value: reciprocalRelation,
      options: filterDirectionList(chosenRelation),
      autoComplete: 'off',
      error: false,
      errorKey: 'select_relation_error',
      disabled: false,
      labelStyle: {
        fontSize: '18px',
      },
      action: handleOnSelectReciprocalRelation,
    },
  ];

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    fetch_request('/api/relations', requestOptions)
      .then((response) => response.json())
      .then((relations) => {
        const excludedKeys = new Set(['other', 'friend']);
        const filteredRelations = relations.filter(
          (item) => !excludedKeys.has(item.key)
        );
        setRelations(relations);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFriendNoteChange = (event) => {
    setFriendNote(event.target.value);
  };

  const isDisabled = () => {
    return (
      (chosenRelation.id &&
        reciprocalRelation.id &&
        activeTab === 'relative') ||
      activeTab === 'friend' ||
      activeTab === 'other'
    );
  };

  return (
    <React.Fragment>
      <div className='set-relation-and-claim-modal_display_image_wrapper'>
        <div>
          <React.Fragment>
            <p className='choose-relation-text'>
              <FormattedMessage id='choose_relation' />
            </p>
            <div className='tab-container'>
              <RelationTabs
                label='relative'
                onClick={() => handleTabClick('relative')}
                active={activeTab === 'relative'}
              />
              <RelationTabs
                label='friend'
                onClick={() => handleTabClick('friend')}
                active={activeTab === 'friend'}
              />
              <RelationTabs
                label='auth_other'
                onClick={() => handleTabClick('other')}
                active={activeTab === 'other'}
              />
            </div>
          </React.Fragment>
          <div className='tab-content'>
            {activeTab === 'relative' && (
              <div className='tab-content_body'>
                {formInputs.map((item, index) => (
                  <Input key={index} item={item} />
                ))}
              </div>
            )}
            {activeTab === 'friend' && (
              <div>
                <TextField
                  placeholder={intl.formatMessage(
                    {
                      id: 'set_relation_placeholder',
                    },
                    { grave: grave.name }
                  )}
                  variant='outlined'
                  multiline
                  minRows={4}
                  fullWidth
                  value={friendNote}
                  onChange={handleFriendNoteChange}
                />
              </div>
            )}
            {activeTab === 'other' && (
              <div className='other-relation-text'>
                <FormattedMessage id='set_relation_other' />
              </div>
            )}
          </div>
        </div>
      </div>
      {user.is_subscribed && (
        <p className='message-text'>
          <FormattedMessage id='set_relation_note' />
        </p>
      )}
      <div className='submit-button-wrapper'>
        <PrimaryButton
          children={<FormattedMessage id='settings_cancel' />}
          onClick={() => {
            if (user.is_subscribed) {
              props.handleCloseRelationDialog();
            } else {
              navigate(-1);
            }
          }}
          style={{
            background: '#fff',
            border: '1px solid #404D56',
            color: '#404D56',
            padding: '7px 16px',
          }}
        />
        {user.is_subscribed ? (
          <PrimaryButton
            disabled={!isDisabled()}
            onClick={handleSetRelation}
            children={<FormattedMessage id='auth_send' />}
          />
        ) : (
          <PrimaryButton
            onClick={() =>
              navigate('/pricing/payment?payment_method_type=vipps')
            }
            children={<FormattedMessage id={buttonText} />}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default SetRelationOrClaimDialog;
