import "./UserNotification.css";
import React from 'react'
import WarningMessageSign from '../../img/WarningMessageSign.svg';
import SuccessMessageSign from '../../img/SuccessMessageSign.svg';
import ErrorMessageSign from '../../img/ErrorMessageSign.svg';
import { toast } from 'react-toastify';

//variats = ["warning", "success", "default"]
const showUserNotification = (message, variant = "default") => {
    toast.configure();
    toast(<div className="user-notification-toast_message">
        {variant === "warning"
            ? <img src={WarningMessageSign} alt="error-sign" />
            : variant === "success"
                ? <img src={SuccessMessageSign} alt="success-sign" />
                : variant === "error"
                    ? <img src={ErrorMessageSign} alt="success-sign" />
                    : <img src={ErrorMessageSign} alt="success-sign" />
        }
        {message}
    </div>, {
        autoClose: 5000,
        closeOnClick: true,
        hideProgressBar: true,
        type: toast.TYPE.DEFAULT,
        position: toast.POSITION.BOTTOM_RIGHT,
        className: `user-notification-toast ${variant}`
    })
}

export default showUserNotification;
