import React, { useState, useEffect } from 'react';
import styles from './GravesUserJoined.module.css';
import { CONSTANTS } from '../../../../service/constants';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar } from '@mui/material';
import { MenuItem, FormControl, Select } from '@mui/material';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loader from '../../../../components/Loader/Loader';

const GravesUserJoined = ({ setSelectedMenuKey, selectedMenuKey }) => {
  const [gravesList, setGravesList] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [currentGraveList, setCurrentGraveList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { slug } = useParams();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthorized);

  useEffect(() => {
    if (isAuth && slug) {
      fetchList().then((apiResponse) => {
        if (apiResponse) {
          if (selectedMenuKey === 2) {
            setCurrentGraveList(apiResponse.graves);
          } else {
            const displayedGraveList = apiResponse.graves.slice(0, 6);
            setCurrentGraveList(displayedGraveList);
          }
          setGravesList(apiResponse.graves);
          setLoading(false);
        }
      });
    }
  }, [slug]);

  const fetchList = async () => {
    setLoading(true);
    const params = {
      slug: slug,
    };

    const stringQueryParams = new URLSearchParams(params).toString();

    const response = await fetch(
      `${CONSTANTS.baseUrl}/api/graves_joined_by_users?${stringQueryParams}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (response.status === 200) {
      const apiResponse = await response.json();
      return apiResponse;
    } else {
      console.log(response.error);
    }
  };

  const handleGraveAvatarClicked = (item) => {
    const path = `/deceased/${item.grave_slug}`;
    navigate(path);
  };

  const onFilterChange = (event) => {
    const value = event.target.value;
    setSelectedFilter(() => value);

    if (value === 'all') {
      const displayedGraveList = gravesList.slice(0, 6);
      setCurrentGraveList(displayedGraveList);
    }

    if (value === 'admin') {
      const newGraveList = gravesList.filter((item) => {
        if (item.admin) {
          return true;
        }
        return false;
      });

      const displayedGraveList = newGraveList.slice(0, 6);
      setCurrentGraveList(displayedGraveList);
    }

    if (value === 'Graveier') {
      const newGraveList = gravesList.filter((item) => {
        if (item.grave_owner) {
          return true;
        }
        return false;
      });
      const displayedGraveList = newGraveList.slice(0, 6);
      setCurrentGraveList(displayedGraveList);
    }

    if (value === 'Slektning') {
      const newGraveList = gravesList.filter((item) => {
        if (!item.grave_owner && !item.admin) {
          return true;
        }
        return false;
      });
      const displayedGraveList = newGraveList.slice(0, 6);
      setCurrentGraveList(displayedGraveList);
    }
  };

  return (
    <div className={styles['joined-graves-container']}>
      <div className={styles['joined-graves-header']}>
        <div className={styles['joined-graves-header_title']}>
          <span>
            <FormattedMessage id='graves' />
          </span>
          <div className={styles['select-roles']}>
            <FormControl
              value={selectedFilter}
              className={styles['privacy-select-roles']}
            >
              <Select
                className={styles['select-roles-filter_form_control']}
                value={selectedFilter}
                onChange={onFilterChange}
                disabled={
                  currentGraveList.length === 0 && gravesList.length === 0
                }
              >
                <MenuItem value='all'>
                  <FormattedMessage id='all' />
                </MenuItem>
                <MenuItem value='Graveier'>
                  <FormattedMessage id='owner' />
                </MenuItem>
                <MenuItem value='admin'>
                  <FormattedMessage id='Administrator' />
                </MenuItem>
                <MenuItem value='Slektning'>
                  <FormattedMessage id='affiliated' />
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>
      {currentGraveList.length === 0 && !loading ? (
        <div className={styles['empty-grave-container']}>
          <div className={styles['empty-grave']}>
            <FormattedMessage id='empty_graves' />
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={styles['joined_graves_grid']}>
            {currentGraveList.length > 0 &&
              currentGraveList.map((item, index) => {
                return (
                  <div
                    className={styles['joined_graves_item']}
                    onClick={() => handleGraveAvatarClicked(item)}
                    key={index}
                  >
                    <div className={styles['grave-container']}>
                      <Avatar
                        src={item?.avatar}
                        className={styles['avatar']}
                        s
                      />
                      <div className={styles['grave_info']}>
                        <div className={styles['grave_name']}>{item?.name}</div>
                        <div className={styles['grave_relation']}>
                          <FormattedMessage id={item?.relation_key} />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {selectedMenuKey === 1 && currentGraveList.length === 6 && (
            <div
              className={styles['view-all']}
              onClick={() => setSelectedMenuKey(2)}
            >
              <FormattedMessage id='view_all' />
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default GravesUserJoined;
