import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './RichInput.module.css';

const RichTextEditor = ({
  onInput,
  value,
  editable = true,
  placeholderKey = '',
}) => {
  const intl = useIntl();
  const textEditorRef = useRef();
  const [content, setContent] = useState(value);
  const [caretPosition, setCaretPosition] = useState(null);

  useEffect(() => {
    if (value !== undefined && value !== content) {
      setContent(value);
    }
  }, [value, content]);

  const saveCaretPosition = () => {
    const selection = window.getSelection();
    const textEditor = textEditorRef.current;

    if (selection && textEditor && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      const preRange = range.cloneRange();
      preRange.selectNodeContents(textEditor);
      preRange.setEnd(range.startContainer, range.startOffset);
      return preRange.toString().length;
    }
    return 0;
  };

  const restoreCaretPosition = (position) => {
    const textEditor = textEditorRef.current;
    if (!textEditor) return;

    const range = document.createRange();
    let charIndex = 0;
    let node = textEditor;
    let stack = [textEditor];

    while (stack.length > 0) {
      node = stack.shift() || null;
      if (node && node.nodeType === Node.TEXT_NODE) {
        const nextCharIndex = charIndex + (node.textContent?.length || 0);
        if (nextCharIndex >= position) {
          range.setStart(node, position - charIndex);
          range.collapse(true);
          break;
        }
        charIndex = nextCharIndex;
      } else if (node && node.nodeType === Node.ELEMENT_NODE) {
        stack.unshift(...node.childNodes);
      }
    }

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  const handleInput = async () => {
    const textEditor = textEditorRef.current;
    if (!textEditor) return;

    const updatedContent = textEditorRef.current?.innerHTML || '';
    const newCaretPosition = saveCaretPosition();

    if (updatedContent !== content) {
      setContent(updatedContent);
      onInput(updatedContent);
      setCaretPosition(newCaretPosition);
    }
  };

  const onKeydown = (event) => {
    if (event.key === 'Enter') {
      setTimeout(() => {
        const selection = window.getSelection();
        const range = selection.getRangeAt(0);
        let currentNode = range.startContainer.lastChild;

        if (currentNode && currentNode?.nodeName === 'DIV') {
          range.setStart(currentNode, 0);
          range.collapse(true);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 0);
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');

    const selection = window.getSelection();
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);

      range.deleteContents();
      const textNode = document.createTextNode(pastedText);
      range.insertNode(textNode);

      range.setStartAfter(textNode);
      range.collapse(true);

      selection.removeAllRanges();
      selection.addRange(range);

      const updatedContent = textEditorRef.current?.innerHTML || '';
      setContent(updatedContent);
      onInput(updatedContent);
    }
  };

  useEffect(() => {
    restoreCaretPosition(caretPosition);
  }, [caretPosition]);

  return (
    <div className={styles['container']}>
      {editable && !content && (
        <div className={styles['placeholder']}>
          <FormattedMessage id={placeholderKey} />
        </div>
      )}
      <div
        ref={textEditorRef}
        className={styles['richInput']}
        contentEditable={editable}
        aria-placeholder={intl.formatMessage({ id: 'comment_placeholder' })}
        aria-label={intl.formatMessage({ id: 'comment_placeholder' })}
        onInput={handleInput}
        onPaste={handlePaste}
        onKeyDown={onKeydown}
        spellCheck='false'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  );
};

export default RichTextEditor;
