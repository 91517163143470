import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Upload from '../../../../img/UploadImageIcon.svg';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import { addPhotoForCropping } from '../../../../redux/actions';
import styles from './UploadImage.module.css';
import UpgradePlanModal from '../../../../components/Modals/UpgradePlanModal';
import isEmpty from 'lodash.isempty';

const UploadImage = ({ handleShowImages, singleImage }) => {
  const inputFile = useRef();
  const dispatch = useDispatch();
  const [openUpgradePlanModal, setOpenUpgradePlanModal] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const canAddPhotos = useSelector(
    (state) => state.user.activeSubscription?.can_add_timeline_photos
  );
  const photoForCropping = useSelector(
    (state) => state.photoReducer.photoForCropping
  );
  const biggerImage = () =>
    showUserNotification(
      'Last opp et bilde som ikke er større enn 50MB.',
      'warning'
    );

  const choseCoverPhotoFromList = (photos) => {
    if (!isEmpty(photoForCropping)) {
      const newPhotos = [...photoForCropping, ...photos];
      dispatch(addPhotoForCropping(newPhotos));
    } else {
      dispatch(addPhotoForCropping(photos));
    }
  };

  const selectTheImages = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imagesArray = Array.from(e.target.files);
      const selectedImages = [];

      const processImage = (file, result) => {
        if (file.size / (1024 * 1024) <= 20) {
          selectedImages.push({
            file_url: result,
            id: Date.now().toString(),
            file: file,
            privacy: 'relatives',
            category: singleImage ? 'cover' : '',
          });
        } else {
          biggerImage();
        }
        if (selectedImages.length === imagesArray.length) {
          choseCoverPhotoFromList(selectedImages);
        }
      };

      imagesArray.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => processImage(file, event.target.result);
        reader.readAsDataURL(file);
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(photoForCropping)) {
      handleShowImages();
    }
  }, [photoForCropping]);

  const clickOnHideInput = () => {
    if (canAddPhotos) {
      inputFile.current.click();
    } else {
      setOpenUpgradePlanModal(true);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const droppedFiles = e.dataTransfer.files;
    selectTheImages({ target: { files: droppedFiles } });
  };

  return (
    <>
      <UpgradePlanModal
        isOpen={openUpgradePlanModal}
        handleCloseUpgradePlansModal={() => setOpenUpgradePlanModal(false)}
        body='For å legge til flere bilder i albumer trenger du å oppgradere abonnementet ditt.'
        title='Hei! Velg familie eller familie + for å legge til ubegrenset med bilder'
      />
      <div
        className={
          isDraggingOver
            ? styles['drop-image']
            : styles['cropper-modal_content-input-image']
        }
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onDragLeave={() => setIsDraggingOver(false)}
      >
        <div className={styles['cropper-modal_content-input-image_image']}>
          <input
            {...(singleImage ? {} : { multiple: true })}
            ref={inputFile}
            type='file'
            accept='image/jpeg, image/jpg, image/png'
            className={styles['cropper-modal_content-input-image_image-input']}
            onChange={selectTheImages}
          />
          <div className={styles['upload-image']}>
            <div
              className={styles['upload-image-input']}
              onClick={clickOnHideInput}
            >
              <img loading='lazy' src={Upload} alt='UploadImage' />
              <span className={styles['upload-image-text']}>
                Dra bilder hit eller bla gjennom filer
              </span>
            </div>
            <div className={styles['image-text-message']}>
              Bildet bør ikke være større enn 20MB
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadImage;
