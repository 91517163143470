import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  ISO8601Date: { input: any; output: any; }
  ISO8601DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

/** Autogenerated return type of AttachFile. */
export type AttachFilePayload = {
  __typename?: 'AttachFilePayload';
  errors: Array<Scalars['String']['output']>;
  spark?: Maybe<Spark>;
};

export type Comment = {
  __typename?: 'Comment';
  commentReplies?: Maybe<Array<Comment>>;
  commentableId?: Maybe<Scalars['ID']['output']>;
  commentableType?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  parentId?: Maybe<Scalars['ID']['output']>;
  repliesCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
  userId: Scalars['ID']['output'];
};

export type CommentPagination = {
  __typename?: 'CommentPagination';
  items: Array<Comment>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  previousCursor?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of CreateComment. */
export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated return type of CreateGrave. */
export type CreateGravePayload = {
  __typename?: 'CreateGravePayload';
  grave?: Maybe<Grave>;
};

/** Autogenerated return type of CreateGroupMember. */
export type CreateGroupMemberPayload = {
  __typename?: 'CreateGroupMemberPayload';
  errors: Array<Scalars['String']['output']>;
  groupMember?: Maybe<GroupMember>;
};

/** Autogenerated return type of CreateGroup. */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  errors: Array<Scalars['String']['output']>;
  group?: Maybe<Group>;
};

/** Autogenerated return type of CreateMoon. */
export type CreateMoonPayload = {
  __typename?: 'CreateMoonPayload';
  errors: Array<Scalars['String']['output']>;
  moon?: Maybe<Moon>;
};

/** Autogenerated return type of CreatePost. */
export type CreatePostPayload = {
  __typename?: 'CreatePostPayload';
  errors: Array<Scalars['String']['output']>;
  post?: Maybe<Post>;
};

/** Autogenerated return type of CreateReaction. */
export type CreateReactionPayload = {
  __typename?: 'CreateReactionPayload';
  errors: Array<Scalars['String']['output']>;
  reaction?: Maybe<Reaction>;
};

/** Autogenerated return type of CreateSignedUrl. */
export type CreateSignedUrlPayload = {
  __typename?: 'CreateSignedUrlPayload';
  objectKey: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

/** Autogenerated return type of CreateSpark. */
export type CreateSparkPayload = {
  __typename?: 'CreateSparkPayload';
  errors: Array<Scalars['String']['output']>;
  spark?: Maybe<Spark>;
};

export type DeathCertificate = {
  __typename?: 'DeathCertificate';
  certificate?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  graveId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

/** Autogenerated input type of DeletePost */
export type DeletePostInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of DeletePost. */
export type DeletePostPayload = {
  __typename?: 'DeletePostPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  errors: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of DestroyComment. */
export type DestroyCommentPayload = {
  __typename?: 'DestroyCommentPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroyGroupMember. */
export type DestroyGroupMemberPayload = {
  __typename?: 'DestroyGroupMemberPayload';
  errors: Array<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated return type of DestroyGroup. */
export type DestroyGroupPayload = {
  __typename?: 'DestroyGroupPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroyMoon. */
export type DestroyMoonPayload = {
  __typename?: 'DestroyMoonPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroyReaction. */
export type DestroyReactionPayload = {
  __typename?: 'DestroyReactionPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

/** Autogenerated return type of DestroySpark. */
export type DestroySparkPayload = {
  __typename?: 'DestroySparkPayload';
  errors: Array<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
};

export type Grave = {
  __typename?: 'Grave';
  burial?: Maybe<Scalars['ISO8601Date']['output']>;
  category?: Maybe<GraveCategory>;
  countryId?: Maybe<Scalars['Int']['output']>;
  dateOfBirth?: Maybe<Scalars['ISO8601Date']['output']>;
  dateOfDeath?: Maybe<Scalars['ISO8601Date']['output']>;
  deathCertificate?: Maybe<DeathCertificate>;
  gender?: Maybe<GraveGender>;
  graveStatus?: Maybe<GraveStatus>;
  graveUsers?: Maybe<Array<GraveUser>>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  municipality?: Maybe<Scalars['String']['output']>;
  municipalityId?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  otherReasonOfDeath?: Maybe<Scalars['String']['output']>;
  reasonOfDeathId?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum GraveCategory {
  /** physical */
  Physical = 'physical',
  /** virtual */
  Virtual = 'virtual'
}

export enum GraveGender {
  /** annen */
  Annen = 'annen',
  /** kvinne */
  Kvinne = 'kvinne',
  /** mann */
  Mann = 'mann'
}

export enum GraveStatus {
  /** accepted */
  Accepted = 'accepted',
  /** claimed */
  Claimed = 'claimed',
  /** pending_review */
  PendingReview = 'pending_review',
  /** rejected */
  Rejected = 'rejected'
}

export type GraveUser = {
  __typename?: 'GraveUser';
  admin?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  deathCertificate?: Maybe<DeathCertificate>;
  graveId?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  relationId?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<GraveUserStatus>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  userId?: Maybe<Scalars['Int']['output']>;
};

export enum GraveUserStatus {
  /** accepted */
  Accepted = 'accepted',
  /** pending_review */
  PendingReview = 'pending_review',
  /** rejected */
  Rejected = 'rejected'
}

export type Group = {
  __typename?: 'Group';
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  groupCategoryId?: Maybe<Scalars['ID']['output']>;
  groupMembersCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isPaid?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['ID']['output']>;
  scheduledDeletionDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  uuid?: Maybe<Scalars['String']['output']>;
};

export type GroupMember = {
  __typename?: 'GroupMember';
  createdAt: Scalars['ISO8601DateTime']['output'];
  groupId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  invitationAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invitationToken?: Maybe<Scalars['String']['output']>;
  invitedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invitedById?: Maybe<Scalars['ID']['output']>;
  joiningDate?: Maybe<Scalars['ISO8601DateTime']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
};

export type Moon = {
  __typename?: 'Moon';
  createdAt: Scalars['ISO8601DateTime']['output'];
  followerId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  invitationAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  invitationToken?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  toUserId: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Attaches an uploaded file to a Spark */
  attachFileToSpark: AttachFilePayload;
  /** Create a new comment */
  createComment: CreateCommentPayload;
  createGrave?: Maybe<CreateGravePayload>;
  /** Creates a new group */
  createGroup: CreateGroupPayload;
  createGroupMember: CreateGroupMemberPayload;
  /** Create a new moon */
  createMoon: CreateMoonPayload;
  /** Create a new post */
  createPost: CreatePostPayload;
  /** Creates a new reaction */
  createReaction: CreateReactionPayload;
  /** Generates a signed URL for direct file upload */
  createSignedUrl: CreateSignedUrlPayload;
  /** Creates a new spark */
  createSpark: CreateSparkPayload;
  deletePost: DeletePostPayload;
  /** Destroy a comment */
  destroyComment: DestroyCommentPayload;
  /** Deletes an existing group */
  destroyGroup: DestroyGroupPayload;
  destroyGroupMember: DestroyGroupMemberPayload;
  /** Destroy a moon */
  destroyMoon: DestroyMoonPayload;
  /** Destroy a reaction */
  destroyReaction: DestroyReactionPayload;
  /** Deletes an existing spark */
  destroySpark: DestroySparkPayload;
  /** Updates an existing comment */
  updateComment: UpdateCommentPayload;
  /** Updates an existing group */
  updateGroup: UpdateGroupPayload;
  updateGroupMember: UpdateGroupMemberPayload;
  /** Updates an existing moon */
  updateMoon: UpdateMoonPayload;
  updatePost: UpdateResponse;
  /** Updates an existing reaction */
  updateReaction: UpdateReactionPayload;
  /** Updates an existing spark */
  updateSpark: UpdateSparkPayload;
};


export type MutationAttachFileToSparkArgs = {
  sparkId: Scalars['ID']['input'];
  storageKey: Scalars['String']['input'];
};


export type MutationCreateCommentArgs = {
  commentableId: Scalars['ID']['input'];
  commentableType: Scalars['String']['input'];
  description: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['ID']['input']>;
};


export type MutationCreateGraveArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateGroupArgs = {
  description: Scalars['String']['input'];
  groupCategoryId: Scalars['ID']['input'];
  isPaid: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateGroupMemberArgs = {
  groupId: Scalars['ID']['input'];
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['ID']['input'];
};


export type MutationCreateMoonArgs = {
  userId: Scalars['ID']['input'];
};


export type MutationCreatePostArgs = {
  body: Scalars['String']['input'];
  groupId: Scalars['ID']['input'];
};


export type MutationCreateReactionArgs = {
  reactableId: Scalars['ID']['input'];
  reactableType: Scalars['String']['input'];
  reactionType: Scalars['String']['input'];
};


export type MutationCreateSignedUrlArgs = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};


export type MutationCreateSparkArgs = {
  description: Scalars['String']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeletePostArgs = {
  input: DeletePostInput;
};


export type MutationDestroyCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyGroupArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyGroupMemberArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyMoonArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroyReactionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDestroySparkArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCommentArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};


export type MutationUpdateGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  groupCategoryId?: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  isPaid?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateGroupMemberArgs = {
  id: Scalars['ID']['input'];
  joiningDate?: InputMaybe<Scalars['ISO8601DateTime']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateMoonArgs = {
  moonId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};


export type MutationUpdatePostArgs = {
  input: UpdatePostInput;
};


export type MutationUpdateReactionArgs = {
  id: Scalars['ID']['input'];
  reactionType: Scalars['String']['input'];
};


export type MutationUpdateSparkArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  location?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
};

export type Post = {
  __typename?: 'Post';
  authorId?: Maybe<Scalars['ID']['output']>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  groupId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type Query = {
  __typename?: 'Query';
  comment?: Maybe<Comment>;
  commentReplies?: Maybe<Array<Comment>>;
  comments: CommentPagination;
  followedMoons?: Maybe<Array<Moon>>;
  followerMoons?: Maybe<Array<Moon>>;
  group?: Maybe<Array<Group>>;
  groupMember?: Maybe<GroupMember>;
  groupMembers?: Maybe<Array<GroupMember>>;
  groups?: Maybe<Array<Group>>;
  moon?: Maybe<Moon>;
  moons?: Maybe<Array<Moon>>;
  post?: Maybe<Post>;
  posts?: Maybe<Array<Post>>;
  reaction?: Maybe<Reaction>;
  reactions?: Maybe<Array<Reaction>>;
  spark?: Maybe<Spark>;
  sparkCategories?: Maybe<Array<SparkCategory>>;
  sparkCategory?: Maybe<SparkCategory>;
  sparks: SparkPagination;
};


export type QueryCommentArgs = {
  id: Scalars['ID']['input'];
};


export type QueryCommentRepliesArgs = {
  commentId: Scalars['ID']['input'];
};


export type QueryCommentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  sparkId: Scalars['ID']['input'];
};


export type QueryFollowedMoonsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFollowerMoonsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGroupMemberArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGroupMembersArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryMoonArgs = {
  id: Scalars['ID']['input'];
};


export type QueryMoonsArgs = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryPostsArgs = {
  groupId: Scalars['ID']['input'];
};


export type QueryReactionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryReactionsArgs = {
  reactableId: Scalars['ID']['input'];
  reactableType: Scalars['String']['input'];
};


export type QuerySparkArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySparkCategoryArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySparksArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
};

export type Reaction = {
  __typename?: 'Reaction';
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  reactableId: Scalars['ID']['output'];
  reactableType: Scalars['ID']['output'];
  reactionType: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userId: Scalars['ID']['output'];
  userName: Scalars['String']['output'];
};

export type Spark = {
  __typename?: 'Spark';
  category: SparkCategory;
  commentsCount?: Maybe<Scalars['Int']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  reaction?: Maybe<Reaction>;
  reactionsCount?: Maybe<Scalars['JSON']['output']>;
  totalReactionsCount?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  user: User;
  visibility?: Maybe<Scalars['String']['output']>;
};

export type SparkCategory = {
  __typename?: 'SparkCategory';
  createdAt: Scalars['ISO8601DateTime']['output'];
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type SparkPagination = {
  __typename?: 'SparkPagination';
  items: Array<Spark>;
  nextCursor?: Maybe<Scalars['String']['output']>;
  previousCursor?: Maybe<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateComment. */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment?: Maybe<Comment>;
  errors: Array<Scalars['String']['output']>;
};

/** Autogenerated return type of UpdateGroupMember. */
export type UpdateGroupMemberPayload = {
  __typename?: 'UpdateGroupMemberPayload';
  errors: Array<Scalars['String']['output']>;
  groupMember?: Maybe<GroupMember>;
};

/** Autogenerated return type of UpdateGroup. */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  errors: Array<Scalars['String']['output']>;
  group?: Maybe<Group>;
};

export type UpdateInput = {
  body: Scalars['String']['input'];
};

/** Autogenerated return type of UpdateMoon. */
export type UpdateMoonPayload = {
  __typename?: 'UpdateMoonPayload';
  errors: Array<Scalars['String']['output']>;
  moon?: Maybe<Moon>;
};

/** Autogenerated input type of UpdatePost */
export type UpdatePostInput = {
  attributes?: InputMaybe<UpdateInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
};

/** Autogenerated return type of UpdateReaction. */
export type UpdateReactionPayload = {
  __typename?: 'UpdateReactionPayload';
  errors: Array<Scalars['String']['output']>;
  reaction?: Maybe<Reaction>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  errors: Array<Scalars['String']['output']>;
  post?: Maybe<Post>;
};

/** Autogenerated return type of UpdateSpark. */
export type UpdateSparkPayload = {
  __typename?: 'UpdateSparkPayload';
  errors: Array<Scalars['String']['output']>;
  spark?: Maybe<Spark>;
};

export type User = {
  __typename?: 'User';
  agreedTerms?: Maybe<Scalars['Boolean']['output']>;
  authCode?: Maybe<Scalars['Int']['output']>;
  backgroundColor?: Maybe<Scalars['String']['output']>;
  countryId?: Maybe<Scalars['Int']['output']>;
  coverPicture?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['ISO8601DateTime']['output'];
  dateOfBirth?: Maybe<Scalars['ISO8601DateTime']['output']>;
  displayPicture?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  encryptedPassword?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['Int']['output']>;
  graves: Array<Grave>;
  id: Scalars['ID']['output'];
  isEmailVerified?: Maybe<Scalars['Boolean']['output']>;
  isPhoneNumberVerified?: Maybe<Scalars['Boolean']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  rememberCreatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  resetPasswordSentAt?: Maybe<Scalars['ISO8601DateTime']['output']>;
  resetPasswordToken?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
};

export type CreateCommentMutationVariables = Exact<{
  description: Scalars['String']['input'];
  commentableId: Scalars['ID']['input'];
  commentableType: Scalars['String']['input'];
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', createComment: { __typename?: 'CreateCommentPayload', errors: Array<string>, comment?: { __typename?: 'Comment', id: string, description: string, repliesCount?: number | null, createdAt: any, updatedAt: any, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null }, commentReplies?: Array<{ __typename?: 'Comment', id: string, description: string, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null } }> | null } | null } };

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  description: Scalars['String']['input'];
}>;


export type UpdateCommentMutation = { __typename?: 'Mutation', updateComment: { __typename?: 'UpdateCommentPayload', errors: Array<string>, comment?: { __typename?: 'Comment', id: string, description: string, repliesCount?: number | null, createdAt: any, updatedAt: any, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null }, commentReplies?: Array<{ __typename?: 'Comment', id: string, description: string, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null } }> | null } | null } };

export type DestroyCommentMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DestroyCommentMutation = { __typename?: 'Mutation', destroyComment: { __typename?: 'DestroyCommentPayload', id?: string | null, errors: Array<string> } };

export type CommentsQueryVariables = Exact<{
  sparkId: Scalars['ID']['input'];
}>;


export type CommentsQuery = { __typename?: 'Query', comments: { __typename?: 'CommentPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Comment', id: string, description: string, repliesCount?: number | null, createdAt: any, updatedAt: any, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null }, commentReplies?: Array<{ __typename?: 'Comment', id: string }> | null }> } };

export type CommentQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type CommentQuery = { __typename?: 'Query', comment?: { __typename?: 'Comment', id: string, description: string, repliesCount?: number | null, createdAt: any, commentReplies?: Array<{ __typename?: 'Comment', id: string }> | null, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null } } | null };

export type CreateReactionMutationVariables = Exact<{
  reactionType: Scalars['String']['input'];
  reactableId: Scalars['ID']['input'];
  reactableType: Scalars['String']['input'];
}>;


export type CreateReactionMutation = { __typename?: 'Mutation', createReaction: { __typename?: 'CreateReactionPayload', errors: Array<string>, reaction?: { __typename?: 'Reaction', id: string, reactionType: string, userId: string, userName: string, avatar?: string | null, slug: string, reactableId: string, reactableType: string } | null } };

export type UpdateReactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  reactionType: Scalars['String']['input'];
}>;


export type UpdateReactionMutation = { __typename?: 'Mutation', updateReaction: { __typename?: 'UpdateReactionPayload', errors: Array<string>, reaction?: { __typename?: 'Reaction', id: string, reactionType: string } | null } };

export type DestroyReactionMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DestroyReactionMutation = { __typename?: 'Mutation', destroyReaction: { __typename?: 'DestroyReactionPayload', id?: string | null, errors: Array<string> } };

export type ReactionsQueryVariables = Exact<{
  reactableId: Scalars['ID']['input'];
  reactableType: Scalars['String']['input'];
}>;


export type ReactionsQuery = { __typename?: 'Query', reactions?: Array<{ __typename?: 'Reaction', id: string, reactionType: string, userId: string, userName: string, avatar?: string | null, slug: string, reactableId: string, reactableType: string, createdAt: any }> | null };

export type SparkCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type SparkCategoriesQuery = { __typename?: 'Query', sparkCategories?: Array<{ __typename?: 'SparkCategory', id: string, name?: string | null }> | null };

export type CreateSparkMutationVariables = Exact<{
  description: Scalars['String']['input'];
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateSparkMutation = { __typename?: 'Mutation', createSpark: { __typename?: 'CreateSparkPayload', spark?: { __typename?: 'Spark', id: string, description?: string | null, visibility?: string | null, location?: string | null, createdAt: any, updatedAt: any, reactionsCount?: any | null, totalReactionsCount?: number | null, commentsCount?: number | null, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null, dateOfBirth?: any | null }, category: { __typename?: 'SparkCategory', id: string, name?: string | null }, reaction?: { __typename?: 'Reaction', id: string, reactionType: string } | null } | null } };

export type UpdateSparkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  sparkCategoryId: Scalars['ID']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSparkMutation = { __typename?: 'Mutation', updateSpark: { __typename?: 'UpdateSparkPayload', spark?: { __typename?: 'Spark', id: string, description?: string | null, visibility?: string | null, location?: string | null, createdAt: any, updatedAt: any, reactionsCount?: any | null, totalReactionsCount?: number | null, commentsCount?: number | null, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null, dateOfBirth?: any | null }, category: { __typename?: 'SparkCategory', id: string, name?: string | null }, reaction?: { __typename?: 'Reaction', id: string, reactionType: string } | null } | null } };

export type DestroySparkMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type DestroySparkMutation = { __typename?: 'Mutation', destroySpark: { __typename?: 'DestroySparkPayload', id?: string | null, errors: Array<string> } };

export type SparksQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  after?: InputMaybe<Scalars['String']['input']>;
}>;


export type SparksQuery = { __typename?: 'Query', sparks: { __typename?: 'SparkPagination', nextCursor?: string | null, items: Array<{ __typename?: 'Spark', id: string, description?: string | null, visibility?: string | null, location?: string | null, createdAt: any, updatedAt: any, reactionsCount?: any | null, totalReactionsCount?: number | null, commentsCount?: number | null, user: { __typename?: 'User', id: string, fullName?: string | null, slug?: string | null, displayPicture?: string | null, dateOfBirth?: any | null }, category: { __typename?: 'SparkCategory', id: string, name?: string | null }, reaction?: { __typename?: 'Reaction', id: string, reactionType: string } | null }> } };


export const CreateCommentDocument = gql`
    mutation CreateComment($description: String!, $commentableId: ID!, $commentableType: String!) {
  createComment(
    description: $description
    commentableId: $commentableId
    commentableType: $commentableType
  ) {
    comment {
      id
      description
      repliesCount
      user {
        id
        fullName
        slug
        displayPicture
      }
      commentReplies {
        id
        description
        user {
          id
          fullName
          slug
          displayPicture
        }
      }
      createdAt
      updatedAt
    }
    errors
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      description: // value for 'description'
 *      commentableId: // value for 'commentableId'
 *      commentableType: // value for 'commentableType'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($id: ID!, $description: String!) {
  updateComment(id: $id, description: $description) {
    comment {
      id
      description
      repliesCount
      user {
        id
        fullName
        slug
        displayPicture
      }
      commentReplies {
        id
        description
        user {
          id
          fullName
          slug
          displayPicture
        }
      }
      createdAt
      updatedAt
    }
    errors
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const DestroyCommentDocument = gql`
    mutation DestroyComment($id: ID!) {
  destroyComment(id: $id) {
    id
    errors
  }
}
    `;
export type DestroyCommentMutationFn = Apollo.MutationFunction<DestroyCommentMutation, DestroyCommentMutationVariables>;

/**
 * __useDestroyCommentMutation__
 *
 * To run a mutation, you first call `useDestroyCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyCommentMutation, { data, loading, error }] = useDestroyCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyCommentMutation(baseOptions?: Apollo.MutationHookOptions<DestroyCommentMutation, DestroyCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyCommentMutation, DestroyCommentMutationVariables>(DestroyCommentDocument, options);
      }
export type DestroyCommentMutationHookResult = ReturnType<typeof useDestroyCommentMutation>;
export type DestroyCommentMutationResult = Apollo.MutationResult<DestroyCommentMutation>;
export type DestroyCommentMutationOptions = Apollo.BaseMutationOptions<DestroyCommentMutation, DestroyCommentMutationVariables>;
export const CommentsDocument = gql`
    query Comments($sparkId: ID!) {
  comments(first: 10, sparkId: $sparkId) {
    items {
      id
      description
      repliesCount
      user {
        id
        fullName
        slug
        displayPicture
      }
      commentReplies {
        id
      }
      createdAt
      updatedAt
    }
    nextCursor
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      sparkId: // value for 'sparkId'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables> & ({ variables: CommentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export function useCommentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsSuspenseQueryHookResult = ReturnType<typeof useCommentsSuspenseQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const CommentDocument = gql`
    query Comment($id: ID!) {
  comment(id: $id) {
    id
    description
    repliesCount
    commentReplies {
      id
    }
    createdAt
    user {
      id
      fullName
      slug
      displayPicture
    }
  }
}
    `;

/**
 * __useCommentQuery__
 *
 * To run a query within a React component, call `useCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommentQuery(baseOptions: Apollo.QueryHookOptions<CommentQuery, CommentQueryVariables> & ({ variables: CommentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
      }
export function useCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentQuery, CommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
        }
export function useCommentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CommentQuery, CommentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CommentQuery, CommentQueryVariables>(CommentDocument, options);
        }
export type CommentQueryHookResult = ReturnType<typeof useCommentQuery>;
export type CommentLazyQueryHookResult = ReturnType<typeof useCommentLazyQuery>;
export type CommentSuspenseQueryHookResult = ReturnType<typeof useCommentSuspenseQuery>;
export type CommentQueryResult = Apollo.QueryResult<CommentQuery, CommentQueryVariables>;
export const CreateReactionDocument = gql`
    mutation createReaction($reactionType: String!, $reactableId: ID!, $reactableType: String!) {
  createReaction(
    reactionType: $reactionType
    reactableId: $reactableId
    reactableType: $reactableType
  ) {
    reaction {
      id
      reactionType
      userId
      userName
      avatar
      slug
      reactableId
      reactableType
    }
    errors
  }
}
    `;
export type CreateReactionMutationFn = Apollo.MutationFunction<CreateReactionMutation, CreateReactionMutationVariables>;

/**
 * __useCreateReactionMutation__
 *
 * To run a mutation, you first call `useCreateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReactionMutation, { data, loading, error }] = useCreateReactionMutation({
 *   variables: {
 *      reactionType: // value for 'reactionType'
 *      reactableId: // value for 'reactableId'
 *      reactableType: // value for 'reactableType'
 *   },
 * });
 */
export function useCreateReactionMutation(baseOptions?: Apollo.MutationHookOptions<CreateReactionMutation, CreateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReactionMutation, CreateReactionMutationVariables>(CreateReactionDocument, options);
      }
export type CreateReactionMutationHookResult = ReturnType<typeof useCreateReactionMutation>;
export type CreateReactionMutationResult = Apollo.MutationResult<CreateReactionMutation>;
export type CreateReactionMutationOptions = Apollo.BaseMutationOptions<CreateReactionMutation, CreateReactionMutationVariables>;
export const UpdateReactionDocument = gql`
    mutation updateReaction($id: ID!, $reactionType: String!) {
  updateReaction(id: $id, reactionType: $reactionType) {
    reaction {
      id
      reactionType
    }
    errors
  }
}
    `;
export type UpdateReactionMutationFn = Apollo.MutationFunction<UpdateReactionMutation, UpdateReactionMutationVariables>;

/**
 * __useUpdateReactionMutation__
 *
 * To run a mutation, you first call `useUpdateReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReactionMutation, { data, loading, error }] = useUpdateReactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reactionType: // value for 'reactionType'
 *   },
 * });
 */
export function useUpdateReactionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReactionMutation, UpdateReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReactionMutation, UpdateReactionMutationVariables>(UpdateReactionDocument, options);
      }
export type UpdateReactionMutationHookResult = ReturnType<typeof useUpdateReactionMutation>;
export type UpdateReactionMutationResult = Apollo.MutationResult<UpdateReactionMutation>;
export type UpdateReactionMutationOptions = Apollo.BaseMutationOptions<UpdateReactionMutation, UpdateReactionMutationVariables>;
export const DestroyReactionDocument = gql`
    mutation destroyReaction($id: ID!) {
  destroyReaction(id: $id) {
    id
    errors
  }
}
    `;
export type DestroyReactionMutationFn = Apollo.MutationFunction<DestroyReactionMutation, DestroyReactionMutationVariables>;

/**
 * __useDestroyReactionMutation__
 *
 * To run a mutation, you first call `useDestroyReactionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroyReactionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroyReactionMutation, { data, loading, error }] = useDestroyReactionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroyReactionMutation(baseOptions?: Apollo.MutationHookOptions<DestroyReactionMutation, DestroyReactionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyReactionMutation, DestroyReactionMutationVariables>(DestroyReactionDocument, options);
      }
export type DestroyReactionMutationHookResult = ReturnType<typeof useDestroyReactionMutation>;
export type DestroyReactionMutationResult = Apollo.MutationResult<DestroyReactionMutation>;
export type DestroyReactionMutationOptions = Apollo.BaseMutationOptions<DestroyReactionMutation, DestroyReactionMutationVariables>;
export const ReactionsDocument = gql`
    query reactions($reactableId: ID!, $reactableType: String!) {
  reactions(reactableId: $reactableId, reactableType: $reactableType) {
    id
    reactionType
    userId
    userName
    avatar
    slug
    reactableId
    reactableType
    createdAt
  }
}
    `;

/**
 * __useReactionsQuery__
 *
 * To run a query within a React component, call `useReactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReactionsQuery({
 *   variables: {
 *      reactableId: // value for 'reactableId'
 *      reactableType: // value for 'reactableType'
 *   },
 * });
 */
export function useReactionsQuery(baseOptions: Apollo.QueryHookOptions<ReactionsQuery, ReactionsQueryVariables> & ({ variables: ReactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReactionsQuery, ReactionsQueryVariables>(ReactionsDocument, options);
      }
export function useReactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReactionsQuery, ReactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReactionsQuery, ReactionsQueryVariables>(ReactionsDocument, options);
        }
export function useReactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReactionsQuery, ReactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReactionsQuery, ReactionsQueryVariables>(ReactionsDocument, options);
        }
export type ReactionsQueryHookResult = ReturnType<typeof useReactionsQuery>;
export type ReactionsLazyQueryHookResult = ReturnType<typeof useReactionsLazyQuery>;
export type ReactionsSuspenseQueryHookResult = ReturnType<typeof useReactionsSuspenseQuery>;
export type ReactionsQueryResult = Apollo.QueryResult<ReactionsQuery, ReactionsQueryVariables>;
export const SparkCategoriesDocument = gql`
    query SparkCategories {
  sparkCategories {
    id
    name
  }
}
    `;

/**
 * __useSparkCategoriesQuery__
 *
 * To run a query within a React component, call `useSparkCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparkCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparkCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useSparkCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
      }
export function useSparkCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
        }
export function useSparkCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SparkCategoriesQuery, SparkCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SparkCategoriesQuery, SparkCategoriesQueryVariables>(SparkCategoriesDocument, options);
        }
export type SparkCategoriesQueryHookResult = ReturnType<typeof useSparkCategoriesQuery>;
export type SparkCategoriesLazyQueryHookResult = ReturnType<typeof useSparkCategoriesLazyQuery>;
export type SparkCategoriesSuspenseQueryHookResult = ReturnType<typeof useSparkCategoriesSuspenseQuery>;
export type SparkCategoriesQueryResult = Apollo.QueryResult<SparkCategoriesQuery, SparkCategoriesQueryVariables>;
export const CreateSparkDocument = gql`
    mutation CreateSpark($description: String!, $sparkCategoryId: ID!, $visibility: String, $location: String) {
  createSpark(
    description: $description
    sparkCategoryId: $sparkCategoryId
    visibility: $visibility
    location: $location
  ) {
    spark {
      id
      description
      visibility
      location
      createdAt
      updatedAt
      user {
        id
        fullName
        slug
        displayPicture
        dateOfBirth
      }
      category {
        id
        name
      }
      reactionsCount
      totalReactionsCount
      reaction {
        id
        reactionType
      }
      commentsCount
    }
  }
}
    `;
export type CreateSparkMutationFn = Apollo.MutationFunction<CreateSparkMutation, CreateSparkMutationVariables>;

/**
 * __useCreateSparkMutation__
 *
 * To run a mutation, you first call `useCreateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSparkMutation, { data, loading, error }] = useCreateSparkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      sparkCategoryId: // value for 'sparkCategoryId'
 *      visibility: // value for 'visibility'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useCreateSparkMutation(baseOptions?: Apollo.MutationHookOptions<CreateSparkMutation, CreateSparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSparkMutation, CreateSparkMutationVariables>(CreateSparkDocument, options);
      }
export type CreateSparkMutationHookResult = ReturnType<typeof useCreateSparkMutation>;
export type CreateSparkMutationResult = Apollo.MutationResult<CreateSparkMutation>;
export type CreateSparkMutationOptions = Apollo.BaseMutationOptions<CreateSparkMutation, CreateSparkMutationVariables>;
export const UpdateSparkDocument = gql`
    mutation UpdateSpark($id: ID!, $description: String, $sparkCategoryId: ID!, $visibility: String, $location: String) {
  updateSpark(
    id: $id
    description: $description
    sparkCategoryId: $sparkCategoryId
    visibility: $visibility
    location: $location
  ) {
    spark {
      id
      description
      visibility
      location
      createdAt
      updatedAt
      user {
        id
        fullName
        slug
        displayPicture
        dateOfBirth
      }
      category {
        id
        name
      }
      reactionsCount
      totalReactionsCount
      reaction {
        id
        reactionType
      }
      commentsCount
    }
  }
}
    `;
export type UpdateSparkMutationFn = Apollo.MutationFunction<UpdateSparkMutation, UpdateSparkMutationVariables>;

/**
 * __useUpdateSparkMutation__
 *
 * To run a mutation, you first call `useUpdateSparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSparkMutation, { data, loading, error }] = useUpdateSparkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      sparkCategoryId: // value for 'sparkCategoryId'
 *      visibility: // value for 'visibility'
 *      location: // value for 'location'
 *   },
 * });
 */
export function useUpdateSparkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSparkMutation, UpdateSparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSparkMutation, UpdateSparkMutationVariables>(UpdateSparkDocument, options);
      }
export type UpdateSparkMutationHookResult = ReturnType<typeof useUpdateSparkMutation>;
export type UpdateSparkMutationResult = Apollo.MutationResult<UpdateSparkMutation>;
export type UpdateSparkMutationOptions = Apollo.BaseMutationOptions<UpdateSparkMutation, UpdateSparkMutationVariables>;
export const DestroySparkDocument = gql`
    mutation DestroySpark($id: ID!) {
  destroySpark(id: $id) {
    id
    errors
  }
}
    `;
export type DestroySparkMutationFn = Apollo.MutationFunction<DestroySparkMutation, DestroySparkMutationVariables>;

/**
 * __useDestroySparkMutation__
 *
 * To run a mutation, you first call `useDestroySparkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDestroySparkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [destroySparkMutation, { data, loading, error }] = useDestroySparkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDestroySparkMutation(baseOptions?: Apollo.MutationHookOptions<DestroySparkMutation, DestroySparkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroySparkMutation, DestroySparkMutationVariables>(DestroySparkDocument, options);
      }
export type DestroySparkMutationHookResult = ReturnType<typeof useDestroySparkMutation>;
export type DestroySparkMutationResult = Apollo.MutationResult<DestroySparkMutation>;
export type DestroySparkMutationOptions = Apollo.BaseMutationOptions<DestroySparkMutation, DestroySparkMutationVariables>;
export const SparksDocument = gql`
    query Sparks($first: Int!, $after: String) {
  sparks(first: $first, after: $after) {
    items {
      id
      description
      visibility
      location
      createdAt
      updatedAt
      user {
        id
        fullName
        slug
        displayPicture
        dateOfBirth
      }
      category {
        id
        name
      }
      reactionsCount
      totalReactionsCount
      reaction {
        id
        reactionType
      }
      commentsCount
    }
    nextCursor
  }
}
    `;

/**
 * __useSparksQuery__
 *
 * To run a query within a React component, call `useSparksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparksQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useSparksQuery(baseOptions: Apollo.QueryHookOptions<SparksQuery, SparksQueryVariables> & ({ variables: SparksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
      }
export function useSparksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SparksQuery, SparksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
        }
export function useSparksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SparksQuery, SparksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SparksQuery, SparksQueryVariables>(SparksDocument, options);
        }
export type SparksQueryHookResult = ReturnType<typeof useSparksQuery>;
export type SparksLazyQueryHookResult = ReturnType<typeof useSparksLazyQuery>;
export type SparksSuspenseQueryHookResult = ReturnType<typeof useSparksSuspenseQuery>;
export type SparksQueryResult = Apollo.QueryResult<SparksQuery, SparksQueryVariables>;