import React, { useRef, useState, useEffect } from 'react';
import SparksList from '../SparksList/SparksList.jsx';
import { useSparkCategoriesQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import { useSparksQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import { useDispatch, useSelector } from 'react-redux';
import { setSparks, setNextCursor } from '../../../../../../redux/actions.js';
import TopHeader from './TopHeader.jsx';
import GravesUserJoined from '../../../UserGraves/GravesUserJoined.jsx';
import styles from './Sparks.module.css';
import UserConnections from '../../../Relations/UserConnections.jsx';

const Sparks = ({ user, setSelectedMenuKey, selectedMenuKey }) => {
  const dispatch = useDispatch();
  const userContainerRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const sparksList = useSelector((state) => state.spark.sparksList);
  const nextCursor = useSelector((state) => state.spark.nextCursor);
  const [sparkCategories, setSparkCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { loading: loadingCategories } = useSparkCategoriesQuery({
    onCompleted: (data) => {
      if (data?.sparkCategories) setSparkCategories(data.sparkCategories);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const { fetchMore, refetch } = useSparksQuery({
    variables: {
      first: 10,
    },
    onCompleted: (data) => {
      if (data && data.sparks && data.sparks.items.length > 0) {
        dispatch(setSparks(data.sparks.items));
        dispatch(setNextCursor(data.sparks.nextCursor));
      }
      setLoading(false);
    },
    onError: (error) => {
      setLoading(false);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const loadMoreSparks = () => {
    setLoading(true);

    fetchMore({
      variables: {
        after: nextCursor,
        first: 10,
      },
      updateQuery: (prevResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prevResult;

        const newSparks = fetchMoreResult.sparks.items;
        let updatedSparksList = [];
        updatedSparksList = [...sparksList, ...newSparks];

        dispatch(setSparks(updatedSparksList));
        dispatch(setNextCursor(fetchMoreResult.sparks.nextCursor));
        setLoading(false);

        return {
          ...prevResult,
          sparks: {
            ...prevResult.sparks,
            items: updatedSparksList,
            nextCursor: fetchMoreResult.sparks.nextCursor,
            previousCursor: fetchMoreResult.sparks.previousCursor,
          },
        };
      },
    });
  };

  const handleIntersection = (entries) => {
    const isIntersecting = entries[0]?.isIntersecting;
    const currentDirection = entries[0].target.dataset.direction;

    if (!isIntersecting) return;

    if (currentDirection === 'bottom') {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '',
      threshold: 0.0,
    };
    const observer = new IntersectionObserver(handleIntersection, options);

    if (userContainerRef.current) {
      observer.observe(userContainerRef.current);
    }

    return () => {
      if (userContainerRef.current)
        observer.unobserve(userContainerRef.current);
    };
  }, [isSticky]);

  const isUserCurrentUser = () => {
    return (
      user.slug === localStorage.getItem('user-slug') ||
      user.slug === sessionStorage.getItem('user-slug')
    );
  };

  return (
    <div className={styles['sparks-container']}>
      <div className={styles['sparks-list']}>
        {isUserCurrentUser() && (
          <TopHeader
            user={user}
            sparkCategories={sparkCategories}
            loading={loadingCategories}
          />
        )}
        <SparksList
          sparkCategories={sparkCategories}
          sparksList={sparksList}
          loadMoreSparks={loadMoreSparks}
          loading={loading}
          user={user}
          nextCursor={nextCursor}
        />
      </div>
      <div
        className={`${styles['user-container']} ${
          isSticky ? styles['sticky'] : ''
        }`}
      >
        <GravesUserJoined
          user={user}
          setSelectedMenuKey={setSelectedMenuKey}
          selectedMenuKey={selectedMenuKey}
        />
        <UserConnections
          user={user}
          setSelectedMenuKey={setSelectedMenuKey}
          selectedMenuKey={selectedMenuKey}
        />
        <div ref={userContainerRef} data-direction='bottom' />
      </div>
    </div>
  );
};

export default Sparks;
