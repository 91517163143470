import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './GravePictureGallery.css';
import WhiteX from '../../../../img/WhiteX.svg';
import { Avatar, Button, Menu, MenuItem, Modal } from '@mui/material';
import OrangeDots from '../../../../img/OrangeDotsMenu.svg';
import NextPhotoPointer from '../../../../img/OrangePointerNext.svg';
import PrevPhotoPointer from '../../../../img/OrangePointerPrev.svg';
import Memorial from '../../../../img/Memorialize_2.svg';
import Comments from './Comments';
import Edit from '../../../../img/EditCoverPicture.svg';
import Delete from '../../../../img/DeleteCoverPhoto.svg';
import loadingAnimation from '../../../../service/Loading_animation.gif';
import {
  saveBackgroundPhoto,
  saveListOfPhotosFromCropper,
  setCurrentGravePhotoIndex,
  goToChosenGrave,
  setEditGravePhotoIndex,
  addProfilePhotoList,
} from '../../../../redux/actions';
import DeleteModal from '../GravePictureGallery/DeleteModal';
import showUserNotification from '../../../../components/UserNotification/showUserNotification';
import EditMenu from '../ProfilePhotoGallery/EditMenu';
import Pointer from '../../../../img/Pointer1.svg';
import Cropper from 'react-cropper';
import SmallX from '../../../../img/SmallX.svg';
import { request } from '../../../../service/request';
// import MemorializeActive from '../../../../img/MemorializeActive.svg';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash.isempty';
import { makeFileFromURL } from '../../../../service/makeFileFromBlob';

const GravePictureGallery = (props) => {
  const cropperRef = useRef(null);
  const photos = useSelector((state) => state.photoReducer.listOfPhotos);
  const currentPhoto = useSelector(
    (state) => state.photoReducer.currentGravePhoto
  );
  //const can_edit = useSelector(state => state.grave.grave.full_name);
  const grave = useSelector((state) => state.grave.grave);
  const dispatch = useDispatch();
  const [updatingImage, setUpdatingImage] = useState(false);
  const [clickPosition, setClickPositon] = useState();
  // const [currentPhoto, setCurrentPhoto] = useState(0);
  const [hideNext, setHideNext] = useState(false);
  const [hidePrev, setHidePrev] = useState(true);
  const [openComments, setOpenComments] = useState(true);
  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [group, setGroup] = useState(props.backgroundImage.privacy);
  const [description, setDescription] = useState(
    photos[currentPhoto].description
  );
  const [address, setAddress] = useState(photos[currentPhoto].address);
  const [openSetAge, setOpenSetAge] = useState(false);
  const [initialAgesArray, setInitialAgesArray] = useState(
    photos.length > 0
      ? photos[currentPhoto].ages
        ? photos[currentPhoto].ages
        : []
      : []
  );
  const [age, setAge] = useState('');
  const photoAuthor = useSelector((state) =>
    state.grave.grave.grave_users.find(
      (u) => u.user_id === photos[currentPhoto]?.user_id
    )
  );

  // useEffect(() => {
  //   if (photos[currentPhoto]) {
  //     for (let i = 0; i < photos.length; i++) {
  //       if (photos[i].id === photos[currentPhoto].id) {
  //         dispatch(setCurrentGravePhotoIndex(i));
  //         break;
  //       }
  //     }
  //   }
  // }, [photos, props.backgroundImage]);

  const handleOpenMenu = (event) => setAnchorMenu(event.currentTarget);

  const handleCloseMenu = () => setAnchorMenu(null);

  const openOrHideComments = () => setOpenComments(!openComments);

  const handleOpenDeleteModal = () => setOpenDeleteModal(true);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  useEffect(() => {
    if (currentPhoto < photos.length) {
      setHideNext(false);
    }
    if (currentPhoto === photos.length - 1) {
      setHideNext(true);
    }
    if (currentPhoto === 0) {
      setHidePrev(true);
    }
    if (currentPhoto > 0) {
      setHidePrev(false);
    }
    setDescription(photos[currentPhoto].description);
    setAddress(photos[currentPhoto].address);
  }, [currentPhoto, photos]);

  useEffect(() => {
    if (photos[currentPhoto]?.image_ages?.[0]?.age) {
      const arrayOfTags = [];
      const ageArray = photos[currentPhoto].image_ages[0].age.split(',');
      const leftPositions =
        photos[currentPhoto]?.image_ages?.[0]?.position?.left?.split(',') || [];
      const topPositions =
        photos[currentPhoto]?.image_ages?.[0]?.position?.top?.split(',') || [];

      for (let i = 0; i < ageArray.length; i++) {
        const age = ageArray[i];
        const position = {
          left: leftPositions[i] || '',
          top: topPositions[i] || '',
        };
        const id = Date.now() + i;
        if (age && position) {
          arrayOfTags.push({ id: id, position: position, age: age });
        }
      }
      setInitialAgesArray(arrayOfTags);
    } else {
      setInitialAgesArray([]);
    }
  }, [currentPhoto, photos]);

  // const prevPhoto = () => setCurrentPhoto((prev) => prev - 1);
  // const nextPhoto = () => setCurrentPhoto((prev) => prev + 1);

  const prevPhoto = () => {
    if (currentPhoto === 0) {
      dispatch(setCurrentGravePhotoIndex(photos.length - 1));
    } else {
      dispatch(setCurrentGravePhotoIndex(currentPhoto - 1));
    }
  };

  const nextPhoto = () => {
    if (currentPhoto === photos.length - 1) {
      dispatch(setCurrentGravePhotoIndex(0));
      return 0;
    } else {
      dispatch(setCurrentGravePhotoIndex(currentPhoto + 1));
    }
  };

  const deleteCoverPhoto = async () => {
    const formData = new FormData();
    try {
      await request(`/grave_images/${photos[currentPhoto].id}`, null, 'DELETE');
      if (grave.cover_image_id === photos[currentPhoto].id) {
        formData.append('grave[cover_image_id]', null);
        const newInfo = await request(`/graves/${grave.slug}`, formData, 'put');
        dispatch(goToChosenGrave(newInfo.data));
      }
      const newArray = photos.filter(
        (photo) => photo.id !== photos[currentPhoto].id
      );
      if (photos.length - 1 === 0) {
        dispatch(saveBackgroundPhoto(''));
        props.closeModal();
      } else if (photos.length - 1 !== 0) {
        dispatch(setCurrentGravePhotoIndex(0));
      }
      dispatch(saveListOfPhotosFromCropper(newArray));
      showUserNotification('Bildet er slettet', 'success');
    } catch (err) {
      document.location.reload();
      console.log(err);
    }
  };

  const saveChangesFromEdit = async () => {
    // if (!address || !description) {showUserNotification("Please, set address and description to the photo", "warning")}
    try {
      const arrayOfLeftPositions = [],
        arrayOfTopPositions = [],
        arrayOfAges = [];
      if (initialAgesArray.length > 0) {
        initialAgesArray.forEach((age) => {
          arrayOfLeftPositions.push(age.position.left.toString());
          arrayOfTopPositions.push(age.position.top.toString());
          arrayOfAges.push(age.age.toString());
        });
      }
      const formdata = new FormData();
      if (
        cropperRef.current?.cropper &&
        cropperRef.current?.cropper.getCroppedCanvas()
      ) {
        const croppedImage = cropperRef.current.cropper
          .getCroppedCanvas()
          .toDataURL();
        console.log(croppedImage);
        const updatedImage = await makeFileFromURL(croppedImage, 'grave_image');
        formdata.append('grave_image[file]', updatedImage);
      }
      if (arrayOfLeftPositions.length > 0)
        formdata.append(
          'grave_image[image_ages_attributes][][position][left]',
          arrayOfLeftPositions
        );
      if (arrayOfTopPositions.length > 0)
        formdata.append(
          'grave_image[image_ages_attributes][][position][top]',
          arrayOfTopPositions
        );
      if (arrayOfAges.length > 0)
        formdata.append(
          'grave_image[image_ages_attributes][][age]',
          arrayOfAges
        );
      if (
        arrayOfAges.length > 0 &&
        arrayOfTopPositions.length > 0 &&
        arrayOfLeftPositions.length > 0 &&
        photos[currentPhoto].image_ages
      )
        formdata.append(
          'grave_image[image_ages_attributes][][id]',
          photos[currentPhoto].image_ages[0].id
        );

      if (address) formdata.append('grave_image[address]', address);
      if (description) formdata.append('grave_image[description]', description);

      group !== '' &&
        formdata.append(
          'grave_image[privacy_attributes][setting]',
          group.toLocaleLowerCase()
        );

      if (
        !arrayOfLeftPositions.length > 0 &&
        !arrayOfTopPositions.length > 0 &&
        !arrayOfAges.length > 0 &&
        !arrayOfAges.length > 0 &&
        !arrayOfTopPositions.length > 0 &&
        !arrayOfLeftPositions.length > 0 &&
        !photos[currentPhoto].image_ages &&
        !address &&
        !description &&
        group === ''
      ) {
        setOpenEdit(false);
        setOpenSetAge(false);
      } else {
        const headers = {
          'Content-Type': 'multipart/form-data',
        };
        const response = await request(
          `/grave_images/${photos[currentPhoto].id}`,
          formdata,
          'put',
          headers
        );

        // const newData = await request(
        //   `/grave_images?grave_image[grave_id]=${grave.id}`
        // );
        // const timelinePhotos = newData.data.grave_images.filter(
        //   (image) => image.category === 'timeline'
        // );
        const updatedImages = [...photos];
        const index = updatedImages.findIndex(
          (image) => image.id === response.data.id
        );
        dispatch(setEditGravePhotoIndex(index));
        updatedImages.splice(index, 1, response.data);
        dispatch(saveListOfPhotosFromCropper(updatedImages));
        // if (!isEmpty(findEditedImage)) {
        //   const updatedImage = newData.data.grave_images.find(
        //     (image) => image.id === photos[currentPhoto].id
        //   );
        //   if (!isEmpty(updatedImage)) {
        //     const imagesList = [...mediaPhotos];
        //     const index = imagesList.findIndex(
        //       (image) => image.id === updatedImage.id
        //     );
        //     imagesList.splice(index, 1, updatedImage);
        //     dispatch(setListOfMediaPhotos(imagesList));
        //   }
        // }
        showUserNotification('Bildet er redigert', 'success');
        setOpenEdit(false);
        setOpenSetAge(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const pointOnThePicture = (event) => {
    const rect = event.target.getBoundingClientRect();
    setClickPositon({
      left: (100 * event.nativeEvent.offsetX) / rect.width,
      top: (100 * event.nativeEvent.offsetY) / rect.height,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    setInitialAgesArray((prev) => [
      ...prev,
      { position: clickPosition, age: age, id: Date.now().toString() },
    ]);
    setClickPositon(null);
    setAge('');
  };

  const handleDeleteAgeFromList = (id) => {
    const newArray = initialAgesArray.filter((elem) => elem.id !== id);
    setInitialAgesArray(newArray);
  };

  const setDisplayImage = async (id) => {
    if (id) {
      handleCloseMenu();
      const formdata = new FormData();
      if (grave.cover_image_id === id) {
        formdata.append('grave[cover_image_id]', null);
      } else {
        formdata.append('grave[cover_image_id]', id);
      }
      try {
        const newInfo = await request(`/graves/${grave.slug}`, formdata, 'put');
        dispatch(goToChosenGrave(newInfo.data));
        const profilePhotos = newInfo.data.grave_images
          .filter((image) => image.category === 'display')
          .map((el) => ({
            ...el,
            image_comments: el.image_comments?.map((com) => ({
              ...com,
              description: com.comment,
            })),
          }));
        dispatch(saveListOfPhotosFromCropper(newInfo.data.grave_images));
        dispatch(addProfilePhotoList(profilePhotos));
        setUpdatingImage(false);
        showUserNotification('Bildet er satt som et forsidebilde', 'success');
        handleCloseMenu();
      } catch (err) {
        console.log(err);
      }
    } else
      showUserNotification(
        'Husk å velge hvem du ønsker å dele bildet med',
        'warning'
      );
  };

  const handelCheckBox = (id) => {
    setUpdatingImage(true);
    setDisplayImage(id);
  };

  return (
    <div className='grave-picture-gallery'>
      {openDeleteModal && (
        <DeleteModal
          deleteCoverPhoto={deleteCoverPhoto}
          closeModal={handleCloseDeleteModal}
          open={openDeleteModal}
        />
      )}
      <div
        className='grave-picture-gallery_photos'
        style={{
          backgroundImage: !updatingImage
            ? `url(${photos[currentPhoto].file_url})`
            : 'none',
        }}
      >
        {updatingImage && (
          <div className='loading'>
            <img src={loadingAnimation} alt='Loading....' />
          </div>
        )}
        {!openEdit && !updatingImage && (
          <img
            src={photos[currentPhoto].file_url}
            alt='grave_image'
            className='grave-picture-gallery_photos-photo'
            loading='lazy'
          />
        )}
        {!openSetAge && openEdit && (
          <Cropper
            ref={cropperRef}
            className='grave-picture-gallery_photos-cropper'
            src={photos[currentPhoto].file_url}
            viewMode={1}
            guides={true}
            zoomOnWheel={false}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            background={false}
            responsive={true}
            autoCropArea={1}
            aspectRatio={16 / 9}
            checkOrientation={false}
          />
        )}
        {openEdit && openSetAge && (
          <div className='grave-picture-gallery_photos'>
            <img
              loading='lazy'
              src={photos.length > 0 ? photos[currentPhoto].file_url : null}
              onClick={pointOnThePicture}
              alt='gallery-img'
              className='grave-picture-gallery_photos-photo'
            />
            {clickPosition && (
              <div
                className='second-modal_content-cropped-image_form'
                style={{
                  borderRadius: '4px',
                  position: 'absolute',
                  top: `${parseFloat(clickPosition.top) + 2}%`,
                  left: `${parseFloat(clickPosition.left) - 2}%`,
                  width: '59px',
                }}
              >
                <form
                  onSubmit={handleFormSubmit}
                  className='second-modal_content-cropped-image_form-form'
                >
                  <input
                    type='number'
                    required
                    value={age}
                    onChange={(e) =>
                      e.target.value >= 0 && setAge(e.target.value)
                    }
                    autoFocus
                  />
                  <Button
                    className='second-modal_content-cropped-image_form-form_button-submit'
                    type='submit'
                  >
                    Set
                  </Button>
                </form>
              </div>
            )}
          </div>
        )}
        {initialAgesArray.length > 0 &&
          initialAgesArray.map((age) => {
            return (
              <div
                className={
                  !openEdit
                    ? 'grave-picture-gallery_photos-age'
                    : 'grave-picture-gallery_photos-age edit'
                }
                style={{
                  position: 'absolute',
                  top: `${age.position.top}%`,
                  left: `${age.position.left - 2}%`,
                  opacity: '0',
                }}
              >
                <span>{age.age}</span>
                {openEdit && openSetAge && (
                  <img
                    loading='lazy'
                    onClick={() => handleDeleteAgeFromList(age.id)}
                    src={SmallX}
                    alt=''
                    className='grave-picture-gallery_photos-age_close'
                  />
                )}
              </div>
            );
          })}
        <div
          className={
            !openEdit
              ? 'grave-picture-gallery_photos-details show-hover'
              : 'grave-picture-gallery_photos-details edit'
          }
        >
          {!openEdit &&
            (photos[currentPhoto]?.can_edit ||
              photos[currentPhoto]?.can_destroy) && (
              <Button onClick={handleOpenMenu}>
                <img loading='lazy' src={OrangeDots} alt='OrangeDots' />
              </Button>
            )}
          {!openEdit &&
            !photos[currentPhoto]?.can_edit &&
            !photos[currentPhoto]?.can_destroy && <div> </div>}
          <Menu
            anchorEl={anchorMenu}
            keepMounted
            open={Boolean(anchorMenu)}
            onClose={handleCloseMenu}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                setOpenEdit(true);
                handleCloseMenu();
              }}
              disabled={!photos[currentPhoto].can_edit}
            >
              <div className='grave-picture-gallery_photos-details_menu-item'>
                <img loading='lazy' src={Edit} alt='smth' />
                <span>Redigere oversiktsbildet</span>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleOpenDeleteModal();
                handleCloseMenu();
              }}
              disabled={!photos[currentPhoto].can_destroy}
            >
              <div className='grave-picture-gallery_photos-details_menu-item'>
                <img loading='lazy' src={Delete} alt='Delete' />
                <span>Slett oversiktsbildet</span>
              </div>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handelCheckBox(photos[currentPhoto].id);
              }}
            >
              <div className='set-cover-image'>
                <input
                  type='checkbox'
                  checked={grave.cover_image_id === photos[currentPhoto].id}
                />
                <label>Sett som visningsbilde</label>
              </div>
            </MenuItem>
          </Menu>
          {!openEdit && address && (
            <div className='grave-picture-gallery_photos-details_address'>
              <span>{address && `Address: ${address}`}</span>
              <div />
            </div>
          )}
          <img
            loading='lazy'
            src={WhiteX}
            alt='WhiteX'
            onClick={props.closeModal}
          />
        </div>
        {!openEdit && photos.length > 1 && (
          <>
            <div className='grave-picture-gallery_photos-buttons show-hover'>
              <Button
                disabled={hidePrev}
                onClick={prevPhoto}
                className={
                  hidePrev
                    ? 'grave-picture-gallery_photos-buttons_prev hide'
                    : 'grave-picture-gallery_photos-buttons_prev'
                }
              >
                <img
                  loading='lazy'
                  src={PrevPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </Button>
              <Button
                disabled={hideNext}
                onClick={nextPhoto}
                className={
                  hideNext
                    ? 'grave-picture-gallery_photos-buttons_next hide'
                    : 'grave-picture-gallery_photos-buttons_next'
                }
              >
                <img
                  loading='lazy'
                  src={NextPhotoPointer}
                  alt='PrevPhotoPointer'
                />
              </Button>
            </div>
          </>
        )}
      </div>

      <div className='grave-picture-gallery_content'>
        <div className='grave-picture-gallery_content-header'>
          {photoAuthor ? (
            <div className='grave-picture-gallery_content-header_profile'>
              {photoAuthor.user_avatar ? (
                <Avatar src={photoAuthor.user_avatar} />
              ) : (
                <Avatar>{photoAuthor.user_name?.charAt(0)}</Avatar>
              )}
              <div className='grave-picture-gallery_content-header_profile-name'>
                {photoAuthor.slug && (
                  <Link to={`/member/${photoAuthor.slug}`}>
                    <span>{photoAuthor.user_name}</span>
                  </Link>
                )}
              </div>
            </div>
          ) : null}
          {openEdit && <EditMenu changeGroup={setGroup} group={group} />}
        </div>
        {!openEdit && description && (
          <div className='grave-picture-gallery_content-post'>
            <span>{description}</span>
          </div>
        )}
        {openEdit && photos.length > 0 && (
          <div className='grave-picture-gallery_edit-post'>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={3}
            />
            <div className='grave-picture-gallery_edit-post_second'>
              <input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder='Enter address'
              />
              <Button
                onClick={() => setOpenSetAge(!openSetAge)}
                className='grave-picture-gallery_edit-post_second-button'
              >
                <span>
                  Hvis det er bildet av en person kan du legge til alder
                </span>
                <img loading='lazy' src={Pointer} alt='pointer' />
              </Button>
            </div>
            <Button
              className='grave-picture-gallery_edit-post_save'
              onClick={saveChangesFromEdit}
            >
              Lagre
            </Button>
          </div>
        )}
        {!openEdit && (
          <Button
            onClick={openOrHideComments}
            className={
              !openComments
                ? 'profile-photo-gallery-mobile_comments-button'
                : 'profile-photo-gallery-mobile_comments-button active'
            }
          >
            {photos.length > 0
              ? photos[currentPhoto]?.image_comments
                ? photos[currentPhoto].image_comments.length
                : '0'
              : '0'}{' '}
            Kommentarer
          </Button>
        )}
      </div>
      {openComments && (
        <Comments
          currentPhoto={currentPhoto}
          closeComments={openOrHideComments}
          photoCategory='timeline'
        />
      )}
      {!openEdit && (
        <div
          className='grave-picture-gallery_buttons'
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <Button
            className='grave-picture-gallery_buttons-memorial'
            onClick={openOrHideComments}
          >
            <img loading='lazy' src={Memorial} alt='Memorial' />
            {`${!openComments ? 'Se kommentarfelt' : 'Lukk kommentarfeltet'}`}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GravePictureGallery;
