import React, { useEffect, useState } from 'react';
import X from '../../../../img/X.svg';
import { Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './InviteRelativesModal.module.css';
import InvitesRelatives from '../InviteRelatives/InviteRelatives';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { CONSTANTS } from '../../../../service/constants';

const InviteRelativesModal = ({ onOpen, onClose, grave }) => {
  const useStyles = makeStyles(( ) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    modalContent: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      width: '900px',
      height: 'calc(100% - 32px)',
      overflowY: 'auto',
      backgroundColor: '#fff',
      padding: '32px',
      borderRadius: 0,
      outline: 'none',
      '@media (max-width: 800px)': {
        width: '100%',
        height: 'calc(100% - 32px)',
        overflow: 'auto',
      },
    },
    modalBackground: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  }));
  const classes = useStyles();
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const [currentSubscription, setCurrentSubscription] = useState({});

  useEffect(() => {
    if (!isAuth) return;
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          setCurrentSubscription(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      className={classes.modal}
      BackdropProps={{
        className: classes.modalBackground,
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <div className={classes.modalContent}>
        <div>
          <div className={styles['workspace-content_header']}>
            <span>Relasjoner</span>
            <img loading='lazy' src={X} alt='x' onClick={onClose} />
          </div>
          <div className={styles['workspace-content_subheader']}>
            <span>
              Her ser du hvilke relasjoner som er satt på graven og muligheten
              til å invitere flere.
            </span>
          </div>
        </div>
        <InvitesRelatives
          grave={grave}
          currentSubscription={currentSubscription}
          onClose={onClose}
        />
      </div>
    </Modal>
  );
};

export default InviteRelativesModal;
