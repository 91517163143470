import React from 'react';
import { useIntl } from 'react-intl';
import styles from './TextArea.module.css';

const TextArea = ({ setText, text }) => {
  const intl = useIntl();
  return (
    <div>
      <textarea
        placeholder={intl.formatMessage({ id: 'text_area_placeholder' })}
        className={styles['text-area']}
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
    </div>
  );
};

export default TextArea;
