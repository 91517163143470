import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import '../App.css';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { makeStyles } from '@mui/styles';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';
import { fetch_request } from '../service/request';
import { useNavigate, useLocation } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  rootCancel: {
    marginRight: '58px',
    borderRadius: '10px',
    backgroundColor: '#d8d8d8',
    width: '299px',
    height: '51px',
    color: '#fff',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'center',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#d8d8d8',
    },
    '@media (max-width: 750px)': {
      marginRight: '0px',
      marginTop: '16px',
    },
  },
  //responsive_margin_due_fixed_header
  body: {
    minHeight: 'calc(100vh - 160px)',
    marginTop: '90px',
    '@media (max-width: 532px)': {
      marginTop: '65px',
    },
    // '& > div > div:nth-child(2)': {
    //   display: 'none',
    // },
  },
  container: {
    display: 'flex',
    gap: '8px',
    background: 'lightgoldenrodyellow',
    textAlign: 'left',
    padding: '16px 24px',
  },
  alertMessage: {
    color: '#232323',
    fontSize: '14px',
    lineHeight: '24px',
    display: 'inline !important',
  },
  action: {
    fontWeight: '500',
  },
  page: {
    color: '#fea65a',
    cursor: 'pointer',
    marginLeft: '4px',
  },
}));

const Layout = ({ children }) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.user || {});
  const isAuth = localStorage.getItem('auth');

  const hideFooterOnRoutes = ['/settings', '/deceased', '/member'];

  // Check if the current path is in the hideFooterOnRoutes array
  const shouldHideFooter = hideFooterOnRoutes.some((route) =>
    location.pathname.startsWith(route)
  );

  const handleClick = async () => {
    const flow = location.pathname.split('/');
    flow.length === 3 &&
      flow[1] === 'deceased' &&
      flow[2] !== 'null' &&
      flow[2] !== null &&
      flow[2] !== 'undefined' &&
      flow[2] !== undefined &&
      localStorage.setItem('slug_for_redirect', flow[2]);

    navigate('/auth');
  };

  const defaultDescription = `Are you looking to find or create a meaningful grave? Nettgrav.no is a private and personal network that gives modern families the opportunity to continue the love they have for deceased with pictures, films, words and music. Find or create a virtual grave here - nettgrav.no.`;
  const [title, setTitle] = useState('Nettgrav.no');
  const [description, setDescription] = useState(defaultDescription);

  useEffect(() => {
    fetch_request(
      `/admin_panel/search_engine_optimisations?url=${location.pathname}`,
      { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.content !== null && data.content !== undefined) {
          setTitle(data.content?.title);
          setDescription(data.content?.description);
        } else {
          setTitle('Nettgrav.no');
          setDescription(defaultDescription);
        }
      })
      .catch((err) => console.log(err));
  }, [location.pathname]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title}</title>
        <meta name='description' content={description} />
      </Helmet>
      <Header onClick={handleClick} />
      <div className={classes.body}>
        {isAuth === 'true' && !isEmpty(user) && !user.is_email_verified && (
          <div className={classes.container}>
            <WarningAmberRoundedIcon
              style={{ color: '#232323', fontSize: '20px' }}
            />
            <div className={classes.alertMessage}>
              <span className={classes.action}>
                <FormattedMessage id='action_required' />
              </span>
              <FormattedMessage id='email_verification_alert' />
              <span
                className={classes.page}
                onClick={() => navigate('/settings')}
              >
                <FormattedMessage id='goTo_settings_page' />
              </span>
            </div>
          </div>
        )}
        {children}
      </div>
      {!shouldHideFooter && <Footer />}
    </div>
  );
};

export default Layout;
