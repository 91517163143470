import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PrimaryButton from '../../../../components/PrimaryButton';
import Commas1 from '../../../../img/Commas1.svg';
import Popover from '@mui/material/Popover';
import Commas2 from '../../../../img/Commas2.svg';
import OrangeMenuDots from '../../../../img/OrangeDotsMenu.svg';
import styles from './Personality.module.css';
import isEmpty from 'lodash.isempty';

const useStyle = makeStyles(() => ({
  rootSuggestionButton: {
    padding: '10px !important',
    backgroundColor: '#9AA8B2 !important',
    border: 'none !important',
    borderRadius: '5px !important',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px !important',
    lineHeight: '16px !important',
    textTransform: 'none !important',
    color: '#E4F3FD !important',
    '&:hover': {
      backgroundColor: '#36434B',
      opacity: '0.9 !important',
      transition: '0.3s !important',
    },
  },
  rootDots: {
    border: 'none',
    minWidth: '0px',
  },
}));

const Personality = (props) => {
  const {
    isAuth,
    suggestions = [],
    handleOpenVirtueModal,
    chosenSuggestion,
    anchorEl,
    openBillingMenu,
    handleCloseMenu,
    handleOpenEditVirtueModal,
    handleOpenDeleteVirtueModal,
    setChosenSuggestion,
    can_add_virtue,
    setAnchorEl,
    setOpenBillingMenu,
  } = props;

  const classes = useStyle();
  const [anchorElement, setAnchorElement] = useState(null);

  const [suggestionList, setSuggestionsList] = React.useState([]);
  React.useEffect(() => {
    suggestions.length !== 0 && setSuggestionsList(suggestions);
  }, [suggestions]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenBillingMenu(true);
  };

  const clickOnSuggestion = (suggestion, event) => {
    setChosenSuggestion(suggestion);
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  const open = Boolean(anchorElement);
  const id = open ? 'simple-popover' : undefined;

  const expandedSuggestion = () => {
    return (
      <React.Fragment>
        {!isEmpty(chosenSuggestion) && (
          <div className={styles['deceased-content_suggestions-text_block']}>
            <div
              className={styles['deceased-content_suggestions-text_block-menu']}
            >
              {(chosenSuggestion?.can_edit ||
                chosenSuggestion?.can_destroy) && (
                <Button className={classes.rootDots} onClick={handleOpenMenu}>
                  <img loading='lazy' src={OrangeMenuDots} alt='orange-menu' />
                </Button>
              )}
              <Menu
                id='simple-menu'
                elevation={1}
                anchorEl={anchorEl}
                keepMounted
                open={openBillingMenu}
                onClose={handleCloseMenu}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem
                  onClick={() => {
                    handleOpenEditVirtueModal();
                    handleClose();
                  }}
                  disabled={!chosenSuggestion?.can_edit}
                >
                  Rediger
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteVirtueModal();
                    handleClose();
                  }}
                  disabled={!chosenSuggestion?.can_destroy}
                >
                  Fjern
                </MenuItem>
              </Menu>
            </div>
            <div
              className={styles['deceased-content_suggestions-text_block-text']}
            >
              <img loading='lazy' src={Commas1} alt='' />
              <div>
                {
                  suggestionList.filter((s) => s.id === chosenSuggestion?.id)[0]
                    ?.comment
                }
              </div>
              <img loading='lazy' src={Commas2} alt='' />
            </div>
            <div
              className={
                styles['deceased-content_suggestions-text_block-author']
              }
            >
              {chosenSuggestion?.user?.slug && (
                <Link to={`/member/${chosenSuggestion.user.slug}`}>
                  <span>{chosenSuggestion.user.full_name}</span>
                </Link>
              )}
            </div>
          </div>
        )}
      </React.Fragment>
    );
  };

  return can_add_virtue && isAuth ? (
    <React.Fragment>
      {suggestions.length !== 0 && (
        <div className={styles['deceased-suggestions']}>
          <div className={styles['message']}>Verdiarkivet</div>
          <div className={styles['deceased-content_suggestions-content']}>
            {suggestionList.map((suggestion, index) => (
              <>
                <Button
                  size='small'
                  key={index}
                  className={classes.rootSuggestionButton}
                  onClick={(event) => clickOnSuggestion(suggestion, event)}
                >
                  {suggestion.personality}
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorElement}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {expandedSuggestion()}
                </Popover>
              </>
            ))}

            {suggestions.length !== 0 && (
              <PrimaryButton onClick={handleOpenVirtueModal}>
                + Legg til verdi
              </PrimaryButton>
            )}
          </div>
        </div>
      )}

      {suggestions.length === 0 && (
        <React.Fragment>
          <div className={styles['message']}>Verdiarkivet</div>
          <PrimaryButton onClick={handleOpenVirtueModal}>
            + Legg til verdi
          </PrimaryButton>
        </React.Fragment>
      )}
    </React.Fragment>
  ) : (
    <div />
  );
};

export default Personality;
