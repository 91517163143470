import React, { useState } from 'react';
import styles from './GravePendingRelations.module.css';
import Relations from '../../../../components/Relations/Relations';
import PrimaryButton from '../../../../components/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import ConnectRelationsModal from '../ConnectRelationsModal/ConnectRelationsModal';

const GravePendingRelations = ({ isAuth, grave, user }) => {
  const navigate = useNavigate();
  const [openRelationDialog, setOpenRelationDialog] = useState(false);

  const handleClick = () => {
    navigate('/auth/sign-in');
  };

  const handleOpenRelationDialog = () => {
    setOpenRelationDialog(true);
  };

  const handleCloseRelationDialog = () => {
    setOpenRelationDialog(false);
  };

  return (
    <React.Fragment>
      {openRelationDialog && (
        <ConnectRelationsModal
          grave={grave}
          openRelationDialog={openRelationDialog}
          handleCloseRelationDialog={handleCloseRelationDialog}
        />
      )}
      <div className={styles['pending-relations-section']}>
        <div className={styles['heading-container']}>
          <div className={styles['heading']}>Relasjoner</div>
          <div>
            {!isAuth && grave.can_set_relations && (
              <PrimaryButton onClick={handleClick}>Sett relasjon</PrimaryButton>
            )}
            {isAuth &&
              grave.can_set_relations &&
              !grave.current_user_in_set_relation_queue && (
                <PrimaryButton onClick={handleOpenRelationDialog}>
                  Sett relasjon
                </PrimaryButton>
              )}
          </div>
        </div>
        {grave?.grave_users && grave.grave_users.length > 0 && isAuth && (
          <div className={styles['relationship-container']}>
            {grave.grave_users.map((user, index) => (
              <Relations user={user} key={index} />
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default GravePendingRelations;
