import { Avatar, Button, Menu, MenuItem, Tab, Tabs } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import styles from './MemoriesPost.module.css';
import Comment from '../../../../components/Comments/Comment';
import CommentsArea from '../../../../components/Comments/CommentCreateForm';
import TabIcon from './icon/MusicIcon';
import PhotoPost from './PhotoPost';
import VideoPost from './VideoPost';
import MusicPost from './MusicPost';
import Fade from '@mui/material/Fade';
import MenuItemBlock from './icon/MenuItemBlock';
import MenuItemBlockLast from './icon/MenuItemBlockLast';
import Tooltip from '@mui/material/Tooltip';
import HugModal from './modals/HugModal/HugModal';
import moreIcon from '../../../../img/VerticalDots.svg';
import { request } from '../../../../service/request';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  addCommentToMemory,
  addReplyToComment,
  deleteCommentFromMemory,
  deleteReplyFromComment,
  editCommentToMemory,
  editMemory as editMemoryRedux,
  editReplyToComment,
} from '../../../../redux/actions';

import Dots from '../../../../img/DotsMenu.svg';
import MusicTab from '../../../../img/MusicTab.svg';
import VideoTab from '../../../../img/VideoTab.svg';
import PictureTab from '../../../../img/PictureTab.svg';
import Memorialize from '../../../../img/Memorialize_2.svg';
import noGender from '../../../../img/SexType.svg';
import EditPen from '../../../../img/EditPen.svg';
import MenuBin from '../../../../img/MenuBin.svg';
import MemorializeActive from '../../../../img/MemorializeActive.svg';
import OrangeDots from '../../../../img/OrangeDotsMenu.svg';
import OpenDeleteModal from './OpenDeleteModal';
import { Modal } from '@mui/material';
import { timeSinceCreated } from '../../../../service/Utils';

const useStyle = makeStyles(() => ({
  rootDotsButton: {
    marginLeft: 'auto',
    minWidth: '40px',
  },
  rootSongButton: {
    textTransform: 'none',
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 25px',
  },
  rootSongButtonChoosen: {
    textTransform: 'none',
    width: '100%',
    boxSizing: 'border-box',
    padding: '5px 25px',
    backgroundColor: 'rgba(254, 166, 90, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(254, 166, 90, 0.1)',
    },
  },
  rootFinalButtons: {
    width: '50%',
    borderRadius: '10px',
    height: '47px',
  },
  rootIndicator: {
    backgroundColor: '#FEA65A',
    left: '96px',
    // minWidth: '70px',
  },
  rootFlexContainer: {
    display: 'flex !important',
    flexDirection: 'row',
    justifyContent: 'space-between !important',
    width: 'fit-content',
    margin: '0 auto',
  },
  tabItem: {
    margin: '0 auto',
  },
  rootMenuPaper: {
    width: '305px',
  },
  rootMenuItem: {
    padding: '0px',
  },
  rootTooltip: {
    backgroundColor: '#fff',
    boxShadow: '0px 4px 10px rgba(33, 32, 32, 0.18)',
    borderRadius: '6px',
    maxWidth: 'none',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '150%',
    color: '#36434B',
    boxSizing: 'border-box',
    padding: '19px 14px 18px 16px',
  },
  rootArrow: {
    color: '#fff',
  },
  large: {
    width: '55px',
    height: '55px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: 'auto',
    overflowY: 'auto',
    backgroundColor: '#fff',
    padding: '32px',
    borderRadius: 10,
    outline: 'none',
    '@media (max-width: 520px)': {
      width: '100%',
      overflow: 'auto',
    },
  },
  modalBackground: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
}));

const MemoriesPost = (props) => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.user.id);
  const graveUsers = useSelector((state) => state.grave.grave.grave_users);

  const currentPost = graveUsers.filter(
    (grave_user) => grave_user.user_id === props.post.user_id
  )[0];

  const [menuAnchorElem, setMenuAnchorElem] = React.useState(null);
  const [openHugModal, setOpenHugModal] = React.useState(false);
  const [commentsOpen, setCommentsOpen] = React.useState(true);
  const [photos, setPhotos] = React.useState([]);
  const [videos, setVideos] = React.useState([]);
  const [music, setMusic] = React.useState([]);
  const [value, setValue] = React.useState(2);
  const [editOpen, setEditOpen] = React.useState(false);
  const [newValueOfComment, setNewValueOfComment] = React.useState(
    props.post.contents[0].description ? props.post.contents[0].description : ''
  );
  const [currentVideo, setCurrentVideo] = useState(0);
  const [isButtonVisible, setButtonVisibility] = React.useState(false);
  const isRelative = props.is_relative;
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [postCreated, setPostCreated] = useState('');

  React.useEffect(() => {
    setButtonVisibility(
      (photos.length > 0 && music.length > 0) ||
        (videos.length > 0 && music.length > 0) ||
        (photos.length > 0 && videos.length > 0)
    );
  }, [music, photos, videos]);

  const createComment = async (messageText) => {
    try {
      const formdata = new FormData();
      formdata.append('comment[description]', messageText);
      formdata.append('comment[memory_id]', props.post.id);
      await request(`/comments`, formdata, 'post');
      await refreshComments();
    } catch (err) {
      console.log(err);
    }
  };
  const refreshComments = async () => {
    try {
      const response = await request(
        `/comments?comment[commentable_id]=${props.post.id}&comment[commentable_type]=Memory`
      );
      dispatch(
        addCommentToMemory(
          props.post.id,
          response.data.comments ? response.data.comments : []
        )
      );
    } catch (err) {
      console.log(err);
    }
  };
  const editComment = async (comment, newValueOfComment) => {
    try {
      const formdata = new FormData();
      formdata.append('comment[description]', newValueOfComment);
      await request(`/comments/${comment.id}`, formdata, 'put');
      dispatch(
        editCommentToMemory(comment.memory_id, {
          ...comment,
          description: newValueOfComment,
        })
      );
    } catch (err) {
      console.log(err);
    }
  };
  const deleteComment = async (comment) => {
    try {
      await request(`/comments/${comment.id}`, null, 'delete');
      dispatch(deleteCommentFromMemory(comment.memory_id, comment.id));
    } catch (error) {
      console.log(error);
    }
  };

  const createReply = async (comment, replyText) => {
    try {
      const formdata = new FormData();
      formdata.append('reply[comment_id]', comment.id);
      formdata.append('reply[description]', replyText);
      const respone = await request('/replies', formdata, 'post');
      //respone.data.reply_id   need to be saved
      dispatch(
        addReplyToComment(comment.id, comment.memory_id, {
          id: respone.data.reply_id,
          user_id: userId,
          description: replyText,
          comment_id: comment.id,
          can_edit: true,
          can_destroy: true,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const editReply = async (comment, reply, editedReplyText) => {
    const formdata = new FormData();
    formdata.append('reply[description]', editedReplyText);
    await request(`/replies/${reply.id}`, formdata, 'put');
    dispatch(
      editReplyToComment(comment.id, comment.memory_id, {
        ...reply,
        description: editedReplyText,
      })
    );
  };
  const deleteReply = async (comment, reply) => {
    await request(`/replies/${reply.id}`, null, 'delete');
    dispatch(deleteReplyFromComment(comment.id, comment.memory_id, reply.id));
  };

  const editMemory = async (e) => {
    try {
      e.preventDefault();
      const formdata = new FormData();
      formdata.append(
        'memory[contents_attributes][][description]',
        newValueOfComment
      );
      formdata.append(
        'memory[contents_attributes][][id]',
        props.post.contents[0].id
      );
      await request(`/memories/${props.post.id}`, formdata, 'put');
      dispatch(
        editMemoryRedux({
          ...props.post,
          contents: props.post.contents.map((el, i) =>
            i === 0 ? { ...el, description: newValueOfComment } : el
          ),
        })
      );
      setEditOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCloseOrOpenComments = () => setCommentsOpen(!commentsOpen);

  const handleCloseHugModal = () => setOpenHugModal(false);

  const handleOpenEdit = () => {
    if (props.post.user_id === userId) {
      setEditOpen(true);
      handleCloseMenu(null);
    }
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleOpenMenu = (e) => {
    setMenuAnchorElem(e.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorElem(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getActiveIndexOfTabs = () => {
    return photos.length ? 0 : videos.length ? 1 : music.length ? 2 : 0;
  };

  React.useEffect(() => {
    const postCreated = timeSinceCreated(props.post.created_at);
    setPostCreated(postCreated);
    // SET Music
    setMusic([]);
    const musicFromApi = props.post.contents.filter(
      (content) => content.category === 'audio'
    );

    musicFromApi.length > 0 ? setMusic(musicFromApi) : setMusic([]);
    // SET Photos
    setPhotos([]);
    const images = props.post.contents.filter(
      (content) => content.category === 'image'
    );
    images && images.length > 0 ? setPhotos(images) : setPhotos([]);

    // SET Videos
    setVideos([]);
    const videoFromAPI = props.post.contents.filter(
      (content) => content.category === 'video'
    );
    videoFromAPI && videoFromAPI.length > 0
      ? setVideos(videoFromAPI)
      : setVideos([]);
  }, [props.post.contents]);

  React.useEffect(() => {
    commentsOpen &&
      (async () => {
        try {
          await refreshComments();
        } catch (err) {
          console.log(err);
        }
      })();
  }, [commentsOpen]);

  React.useEffect(() => {
    setValue(getActiveIndexOfTabs());
  }, [photos, music, videos]);

  return (
    <div className={styles['music-post']}>
      <HugModal
        creator={props.post.user_id}
        open={openHugModal}
        onClose={handleCloseHugModal}
      />
      <div className={styles['music-post_content']}>
        {currentPost ? (
          <div className={styles['music-post_content-header']}>
            <div className={styles['memories-header']}>
              <Link to={`/member/${currentPost.slug}`}>
                <Avatar
                  className={classes.large}
                  src={currentPost.user_avatar}
                />
              </Link>

              <div className={styles['music-post_content-header_name']}>
                <Link to={`/member/${currentPost.slug}`}>
                  <span
                    className={styles['music-post_content-header_name-title']}
                  >
                    {currentPost.user_name}
                  </span>
                </Link>
                <span className={styles['music-post_content-header_name-text']}>
                  {postCreated}
                </span>
              </div>
            </div>
            {props.post.user_id === userId && (
              <button
                onClick={handleOpenMenu}
                className={styles['dots-button']}
              >
                <img loading='lazy' src={moreIcon} alt='dots' />
              </button>
            )}
          </div>
        ) : null}
        <div className={styles['music-post_content-title']}>
          {!editOpen &&
            props.post.contents.length > 0 &&
            props.post.contents[0].description && (
              <div className={styles['music-post_content-title_textarea']}>
                {props.post.contents[0].description}
              </div>
            )}
          {editOpen && (
            <form
              onSubmit={editMemory}
              className={styles['music-post_content-title_form']}
            >
              <textarea
                placeholder='Skriv her'
                value={newValueOfComment}
                onChange={(e) => setNewValueOfComment(e.target.value)}
                className={styles['music-post_content-title_textarea_editing']}
              />
              <div className={styles['grave-btn-container']}>
                <div className={styles['grave-information_content-verify']}>
                  <button
                    type='button'
                    className={styles['rootDiscard']}
                    onClick={() => {
                      setEditOpen(false);
                    }}
                  >
                    Avbryt
                  </button>
                  <button className={styles['rootVerify']} type='submit'>
                    Lagre
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        {value === 0 && photos.length > 0 && (
          <PhotoPost
            createComment={createComment}
            refreshComments={refreshComments}
            photos={photos}
            videos={videos}
            music={music}
            post={props.post}
            value={value}
            editComment={editComment}
            deleteComment={deleteComment}
            createReply={createReply}
            editReply={editReply}
            deleteReply={deleteReply}
          />
        )}
        {value === 1 && videos.length > 0 && (
          <VideoPost
            videos={videos}
            post={props.post}
            currentVideo={currentVideo}
            setCurrentVideo={(index) => setCurrentVideo(index)}
          />
        )}
        {value === 2 && music.length > 0 && (
          <MusicPost music={music} post={props.post} />
        )}

        {isButtonVisible && (
          <div className={styles['music-post_content-category']}>
            <Tabs
              classes={{
                indicator: classes.rootIndicator,
                flexContainer: classes.rootFlexContainer,
              }}
              onChange={handleChange}
              value={value}
            >
              {photos.length > 0 && (
                <Tab
                  value={0}
                  className={styles['music-post_content-category_tab']}
                  icon={<TabIcon img={PictureTab} />}
                  {...a11yProps(0)}
                />
              )}
              {videos.length > 0 && (
                <Tab
                  value={1}
                  className={styles['music-post_content-category_tab']}
                  icon={
                    <TabIcon
                      className={
                        styles['music-post_content-category_tab-center']
                      }
                      img={VideoTab}
                    />
                  }
                  {...a11yProps(1)}
                />
              )}
              {music.length > 0 && (
                <Tab
                  value={2}
                  className={styles['music-post_content-category_tab']}
                  icon={<TabIcon img={MusicTab} />}
                  {...a11yProps(2)}
                />
              )}
            </Tabs>
          </div>
        )}
        <div className={styles['music-post_content-commentary']}>
          <span>
            {props.post?.comments?.length > 0
              ? props.post?.comments?.length
              : props.post.comments_count}{' '}
            kommentarer
          </span>
          {commentsOpen && (
            <>
              <CommentsArea createComment={createComment} />
              <div className={styles['memories_comments-area_comments']}>
                {props.post?.comments?.length > 0 &&
                  props.post?.comments?.map((comment) => (
                    <Comment
                      comment={comment}
                      key={comment.id}
                      onCommentEdit={(newValue) =>
                        editComment(comment, newValue)
                      }
                      onCommentDelete={() => deleteComment(comment)}
                      onReplyCreate={(replyText) =>
                        createReply(comment, replyText)
                      }
                      onReplyEdit={(reply, editedReplyText) =>
                        editReply(comment, reply, editedReplyText)
                      }
                      onReplyDelete={(reply) => deleteReply(comment, reply)}
                    />
                  ))}
              </div>
            </>
          )}
          <div />
        </div>
        <div
          className={styles['music-post_content-buttons']}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          {/* Hide hug functionality just for now and added inline style above(delete them in the future) */}
          {/* {
                        !hugCompleted 
                        &&
                        <Tooltip arrow placement='bottom' title='To hug an author of this memory' classes={{tooltip: classes.rootTooltip, arrow: classes.rootArrow}}>
                            <Button className={classes.rootFinalButtons} onClick={handleOpenHugModal} onMouseEnter={() => setHugButtonHover(true)} onMouseLeave={() => setHugButtonHover(false)}><img loading='lazy' src={hugButtonHover ? ActiveHug : Hug} alt='hug'/></Button>
                        </Tooltip>
                    }
                    {
                        hugCompleted && <Button className={classes.rootFinalButtons}><img loading='lazy' src={AuthorIsHugged} alt='hug'/></Button>
                    } */}
          <Tooltip
            arrow
            placement='bottom'
            title='Åpne kommentarfeltet'
            classes={{ tooltip: classes.rootTooltip, arrow: classes.rootArrow }}
          >
            <Button
              onClick={handleCloseOrOpenComments}
              className={classes.rootFinalButtons}
            >
              <img
                loading='lazy'
                src={commentsOpen ? MemorializeActive : Memorialize}
                alt='Kommentarfelt'
              />
            </Button>
          </Tooltip>
        </div>
      </div>
      <Menu
        id='fade-menu'
        anchorEl={menuAnchorElem}
        keepMounted
        open={Boolean(menuAnchorElem)}
        onClose={handleCloseMenu}
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        classes={{
          paper: classes.rootMenuPaper,
        }}
      >
        <MenuItem
          onClick={handleOpenEdit}
          classes={{ root: classes.rootMenuItem }}
          disabled={!props.post.can_edit}
        >
          <MenuItemBlock
            image={EditPen}
            title='Rediger'
            description='Legg til, endre eller fjern tekst'
          />
        </MenuItem>
        {/* <MenuItem classes={{root: classes.rootMenuItem}}><MenuItemBlock image={MenuShare} title='SHARE ' description='Add, edit or remove text'/></MenuItem>
                <MenuItem classes={{root: classes.rootMenuItem}}><MenuItemBlock image={MenuFolder} title='ARCHIVE ' description='Archive memory '/></MenuItem>
                <MenuItem classes={{root: classes.rootMenuItem}}><MenuItemBlock image={MenuInvisible} title='HIDE' description='Hide memory from the grave memorial wall'/></MenuItem>
                <MenuItem classes={{root: classes.rootMenuItem}}><MenuItemBlock image={ProfitReport} title='REPORT' description='Report memory'/></MenuItem> */}
        <MenuItem
          onClick={handleOpenDeleteModal}
          classes={{ root: classes.rootMenuItem }}
          disabled={!props.post.can_destroy}
        >
          <MenuItemBlockLast
            image={MenuBin}
            title='Slett'
            description='Legg til, endre eller fjern tekst'
          />
        </MenuItem>
      </Menu>
      {openDeleteModal && (
        <OpenDeleteModal
          closeModal={handleCloseDeleteModal}
          post={props.post}
          handleCloseMenu={handleCloseMenu}
          open={openDeleteModal}
        />
      )}
    </div>
  );
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default MemoriesPost;
