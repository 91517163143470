import React, { useState } from 'react';
import styles from './Comment.module.css';
import { Avatar } from '@mui/material';
import CommentFooter from '../CommentFooter/CommentFooter';
import ActionButton from './ActionButton';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import {
  setSparksComments,
  setSparks,
} from '../../../../../../../redux/actions';
import CommentInput from '../CommentInput/CommentInput';
import {
  useDestroyCommentMutation,
  useUpdateCommentMutation,
} from '../../../../../../../graphql/generated/sparkHooks.ts';
import isEmpty from 'lodash.isempty';

const Comment = ({ comment, spark, sparksComments }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [editComment, setEditComment] = useState(false);
  const sparks = useSelector((state) => state.spark.sparksList);
  const [updateComment] = useUpdateCommentMutation({
    onCompleted: (data) => {
      if (data && data?.updateComment.comment) {
        const updatedComment = data.updateComment.comment;
        const existingComments = sparksComments[spark.id]?.comments || {};
        const updatedComments = {
          ...existingComments,
          [comment.id]: updatedComment,
        };

        const updatedSparkComments = {
          ...sparksComments[spark.id],
          comments: updatedComments,
        };
        const updatedSparksComments = {
          ...sparksComments,
          [spark.id]: updatedSparkComments,
        };

        dispatch(setSparksComments(updatedSparksComments));
        onCloseEdit();
      }
    },
  });

  const [deleteComment] = useDestroyCommentMutation({});

  const onClickEdit = () => {
    setEditComment(true);
  };

  const onCloseEdit = () => {
    setEditComment(false);
  };

  const onClickDelete = async (id) => {
    try {
      if (id) {
        await deleteComment({
          variables: {
            id,
          },
        });
        const deletedCommentId = id;

        const existingComments = sparksComments[spark.id]?.comments || {};

        const updatedComments = Object.fromEntries(
          Object.entries(existingComments).filter(
            ([id]) => id !== deletedCommentId
          )
        );

        const updatedSparksComments = { ...sparksComments };

        if (isEmpty(updatedComments)) {
          delete updatedSparksComments[spark.id];
        } else {
          updatedSparksComments[spark.id] = {
            ...sparksComments[spark.id],
            comments: updatedComments,
          };
        }
        const updatedSparks = sparks.map((s) =>
          s.id === spark.id ? { ...s, commentsCount: s.commentsCount - 1 } : s
        );

        dispatch(setSparksComments(updatedSparksComments));
        dispatch(setSparks(updatedSparks));
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveEditComment = async (description) => {
    try {
      if (comment.id && description) {
        await updateComment({
          variables: {
            id: comment.id,
            description,
          },
        });
      } else {
        return;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return editComment ? (
    <CommentInput
      description={comment.description}
      isEdit={editComment}
      saveEditComment={saveEditComment}
      onCloseEdit={onCloseEdit}
    />
  ) : (
    <div className={styles['container']}>
      <Avatar src={comment?.user.displayPicture} className={styles['avatar']} />
      <div className={styles['comment-container']}>
        <div className={styles['comment-outline']}>
          <div className={styles['comment']}>
            <div className={styles['user-info']}>
              <div className={styles['user-name']}>
                {comment?.user.fullName}
              </div>
              {comment?.user.id === user?.id.toString() && (
                <ActionButton
                  comment={comment}
                  onClickEdit={onClickEdit}
                  onClickDelete={onClickDelete}
                />
              )}
            </div>
            <div
              className={styles['description']}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(comment?.description),
              }}
            />
          </div>
        </div>
        <CommentFooter comment={comment} />
      </div>
    </div>
  );
};

export default Comment;
