import React, { useState } from 'react';
import styles from './TotalReactions.module.css';
import Reactions from '../Reactions/Reactions';
import SmallReactionIcon from '../../Components/SmallReactionIcon';
import isEmpty from 'lodash.isempty';
import { formatTotalCount } from '../../../../../../../service/Utils';

const TotalReactions = ({ spark, reactionList, isMobile }) => {
  const [openModal, setOpenModal] = useState(false);

  const handelOpen = () => {
    setOpenModal(true);
  };

  const handelClose = () => {
    setOpenModal(false);
  };

  return (
    <React.Fragment>
      <div className={styles['container']} onClick={handelOpen}>
        {spark?.reactionsCount && !isEmpty(spark.reactionsCount) && (
          <React.Fragment>
            <div className={styles['reaction-list']}>
              {Object.entries(spark.reactionsCount).map(([key, value]) => {
                const reaction = reactionList[key];
                return (
                  reaction && (
                    <SmallReactionIcon
                      key={key}
                      reactionType={reaction.type}
                      title={
                        <div>
                          {formatTotalCount(value)} {reaction.title}
                        </div>
                      }
                      handelOnClick={handelOpen}
                      icon={reaction.icon}
                      isClickAble={true}
                    />
                  )
                );
              })}
            </div>
            <div className={styles['reactions']}>
              {formatTotalCount(spark.totalReactionsCount)}
            </div>
          </React.Fragment>
        )}
      </div>
      {openModal && (
        <Reactions
          isMobile={isMobile}
          open={openModal}
          close={handelClose}
          handelOpen={handelOpen}
          spark={spark}
          reactionList={reactionList}
        />
      )}
    </React.Fragment>
  );
};

export default TotalReactions;
