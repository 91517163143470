import React, { useEffect, useRef } from 'react';
import SparkPost from './SparkPost.jsx';
import Loader from '../../../../../../components/Loader/Loader.jsx';
import styles from './SparksList.module.css';
import { FormattedMessage } from 'react-intl';

const SparksList = ({
  sparksList,
  sparkCategories,
  loadMoreSparks,
  loading,
  user,
  nextCursor,
}) => {
  const nextRef = useRef(null);

  const handleIntersection = (entries) => {
    const isIntersecting = entries[0]?.isIntersecting;
    const currentDirection = entries[0].target.dataset.direction;

    if (!isIntersecting || loading) return;

    if (nextCursor && currentDirection === 'bottom') {
      loadMoreSparks(true);
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '10px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleIntersection, options);

    if (nextRef.current) {
      observer.observe(nextRef.current);
    }

    return () => {
      if (nextRef.current) observer.unobserve(nextRef.current);
    };
  }, [nextCursor, loading]);

  return (
    <div>
      {sparksList &&
        sparksList.length > 0 &&
        sparksList.map((spark) => {
          return (
            <SparkPost
              key={spark.id}
              spark={spark}
              user={user}
              sparkCategories={sparkCategories}
            />
          );
        })}
      {sparksList.length === 0 && !loading && (
        <div className={styles['empty-grave-container']}>
          <div className={styles['empty-grave ']}>
            <FormattedMessage id='empty_sparks' />
          </div>
        </div>
      )}
      <div ref={nextRef} data-direction='bottom' />
      {loading && <Loader />}
    </div>
  );
};

export default SparksList;
