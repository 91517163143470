import React from 'react';
import X from '../../../../img/X.svg';
import './SetRelationOrClaimConfirmationDialog.css'
import { Link } from 'react-router-dom';

const SetRelationOrClaimConfirmationDialog = (props) => {
  const isClaiming = props.isClaiming;
  const claimText = "Tusen takk for din forespørsel som graveier.\n\n\n Når forespørselen er behandlet sender vi deg en notifikasjon."
  const joinText = "Din relasjonsforespørsel er sendt.\n.\n\nDu mottar en notifikasjon når relasjonen din er bekreftet.";
  
  return(
      <div className="set-relation-or-claim-confirmation_modal">
        <img loading='lazy' onClick={() => props.handleGraveUpdate()} src={X} alt='x' className="cancel-span"/>
        <h1>Din forespørsel er sendt</h1>
        <pre>{isClaiming? claimText : joinText}</pre>
      </div>
  )
}

export default SetRelationOrClaimConfirmationDialog;