import React, { useState, useEffect } from 'react';
import { request } from '../../service/request';
import { useParams } from 'react-router-dom';
import showUserNotification from '../../components/UserNotification/showUserNotification';
import isEmpty from 'lodash.isempty';
import Loader from '../../components/Loader/Loader';
import NotFoundPage from '../404/404';
import UserHeader from './Components/UserHeader';
import styles from './NewUserProfile.module.css';

const NewUserProfile = () => {
  let { slug } = useParams();
  const [loading, setLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (slug) {
      const requestOptions = {
        method: 'GET',
        redirect: 'follow',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      };
      setLoading(true);
      request(`/users/${slug}`, requestOptions, 'get')
        .then((response) => {
          // dispatch(setInformationAboutUser(response.data));
          setCurrentUser(response.data.user);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          showUserNotification(err.response.data.error || err.message, 'error');
          setLoading(false);
        });
    }
  }, [slug]);

  if (isEmpty(currentUser) && !loading) {
    return <NotFoundPage />;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={styles['user-profile']}>
      <UserHeader user={currentUser} setCurrentUser={setCurrentUser} />
    </div>
  );
};

export default NewUserProfile;
