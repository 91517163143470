import React from 'react';
import { FormattedMessage } from 'react-intl';
import styles from './ConnectionRelation.module.css';

const Description = ({ isSubscribed, grave }) => {
  return (
    <div className={styles['description']}>
      <FormattedMessage
        id={
          isSubscribed
            ? 'set_relation_description'
            : 'set_relation_free_description'
        }
        values={{ grave: grave.name }}
      />
    </div>
  );
};

export default Description;
