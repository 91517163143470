import React, { useState, useEffect } from 'react';
import ReactionPopover from '../../Reactions/ReactionPopover/ReactionPopover';
import styles from './ReactionButton.module.css';
import isEmpty from 'lodash.isempty';

const ReactionButton = ({
  reactionList,
  spark,
  handelReaction,
  handelRemoveReaction,
  handelUpdateReaction,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedReaction, setSelectedReaction] = useState(
    reactionList['inspired']
  );
  const [isMobile, setIsMobile] = useState(false);
  let holdTimer;
  let hoverTimer;

  useEffect(() => {
    if (!isEmpty(spark.reaction)) {
      const reaction = reactionList[spark.reaction.reactionType];
      setSelectedReaction(reaction);
    } else {
      setSelectedReaction(reactionList['inspired']);
    }
  }, [spark, reactionList]);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);
    return () => window.removeEventListener('resize', checkIsMobile);
  }, []);

  const handleHoldStart = (e) => {
    e.preventDefault();
    if (isMobile) {
      holdTimer = setTimeout(() => {
        setShowPopover(true);
      }, 500);
    }
  };

  const handleHoldEnd = () => {
    clearTimeout(holdTimer);
  };

  const handleMouseEnter = () => {
    if (!isMobile) {
      hoverTimer = setTimeout(() => {
        setShowPopover(true);
      }, 500);
    }
  };

  const handleMouseLeave = () => {
    if (!isMobile) {
      clearTimeout(hoverTimer);
      setShowPopover(false);
    }
  };

  const handleReactionClick = (value) => {
    if (!isEmpty(spark.reaction)) {
      if (spark.reaction.reactionType === value) {
        handelRemoveReaction(spark.reaction.id, spark.id, value);
      } else {
        handelUpdateReaction(spark.reaction.id, spark.id, value);
      }
    } else {
      handelReaction(value);
    }
    setShowPopover(false);
  };

  const handleContainerClick = () => {
    handleReactionClick(selectedReaction.type);
  };

  return (
    <button
      className={
        !isEmpty(spark.reaction)
          ? styles[`${spark.reaction.reactionType}-reaction`]
          : styles['reaction']
      }
      onClick={handleContainerClick}
      onMouseDown={isMobile ? undefined : handleHoldStart}
      onTouchStart={handleHoldStart}
      onTouchEnd={handleHoldEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onContextMenu={(e) => e.preventDefault()}
      style={{ userSelect: 'none' }}
    >
      {selectedReaction && (
        <React.Fragment>
          <img
            src={selectedReaction.icon}
            alt={selectedReaction.type}
            className={
              !isEmpty(spark.reaction)
                ? styles[`${selectedReaction.type}-active`]
                : styles[selectedReaction.type]
            }
          />
          {selectedReaction.title}
          {showPopover && (
            <div className={styles['reactions']}>
              <ReactionPopover
                reactionList={reactionList}
                onReactionClick={handleReactionClick}
              />
            </div>
          )}
        </React.Fragment>
      )}
    </button>
  );
};

export default ReactionButton;
