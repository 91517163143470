import React from 'react';
import styles from './Vipps.module.css';
import isEmpty from 'lodash.isempty';
import { FormattedMessage } from 'react-intl';

const VippsPhoneNumber = ({
  vippsPhoneNumber,
  selectedVippsMethod,
  handelOnChange,
  error,
}) => {
  const onChange = (event) => {
    const str = event.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters

    // Limit to the first 8 digits
    const eightDigitNumber = str.slice(0, 8);
    console.log(eightDigitNumber);

    handelOnChange(eightDigitNumber); 
  };

  // const handelReadOnly = () => {
  //   return (
  //     selectedVippsMethod &&
  //     !isEmpty(selectedVippsMethod) &&
  //     selectedVippsMethod.phone_number !== ''
  //   );
  // };

  return (
    <div className={styles['billing-details-container']}>
      <span>
        <FormattedMessage id='settings_telephoneNumber' />
      </span>
      <div className={styles['auth-welcome_input']}>
        <div
          className={`${styles['create-membership_first-input_block-inputs']} ${
            error ? styles['error'] : ''
          } `}
        >
          <span
            className={
              styles['create-membership_first-input_block-inputs_button']
            }
          >
            (+47){''}
          </span>
          <input
            className={styles['mobile']}
            type='tel'
            placeholder='00 00 00 00'
            value={vippsPhoneNumber}
            onChange={onChange}
            // readOnly={handelReadOnly()}
          />
          <input
            className={styles['desktop']}
            type='tel'
            placeholder='00 00 00 00'
            value={vippsPhoneNumber}
            // readOnly={handelReadOnly()}
            onChange={onChange}
          />
        </div>
        {error && (
          <div className={styles['error-message']}>
            <FormattedMessage id='add_validNumber' />
          </div>
        )}
      </div>
    </div>
  );
};

export default VippsPhoneNumber;
