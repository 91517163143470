import React from 'react';
import UserElement from './UserElement';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './GraveUserForm.module.css';

const useStyle = makeStyles(() => ({
  large: {
    width: '80px !important',
    height: '80px !important',
  },
}));

const UserInfo = ({ editRelation }) => {
  const classes = useStyle();
  const isOwner = editRelation.grave_owner
    ? 'graveier'
    : editRelation.admin.key === 'administrator'
    ? 'Admin'
    : '';

  return (
    <div className={styles['user-info-container']}>
      <Avatar src={editRelation.user_avatar} className={classes.large} />
      <div className={styles['user-info']}>
        <UserElement value={editRelation.user_name} />
        <UserElement
          value={editRelation.relation.name}
          style={{
            color: '#8d9093',
            fontSize: '14px',
            fontWeight: '400',
          }}
        />
        <UserElement
          value={isOwner}
          style={{
            color: '#404d56',
            fontSize: '14px',
            fontWeight: '400',
          }}
        />
      </div>
    </div>
  );
};

export default UserInfo;
