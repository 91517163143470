import React, { useState, useEffect } from 'react';
import styles from './NewPaymentPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import isEmpty from 'lodash.isempty';
import SelectPaymentMethod from './atoms/SelectedPaymentMethod/SelectPaymentMethod';
import {
  setCurrentSubscriptionPlan,
  setPaymentMethods,
  setProductInfo,
  setShowCardMethod,
  setSelectedCardMethod,
  setVippsPaymentMethods,
  getListOfCountries,
  setCurrentSubscriptionData,
  setShowVippsPaymentMethod,
  setSelectedVippsMethod,
  setVippsPhoneNumber,
  setSubscriptionPeriod,
  setSubscriptionDiscount,
  setPaymentMethod,
} from '../../redux/actions';
import CardPaymentMethod from './atoms/CardPaymentMethod/CardPaymentMethod';
import Vipps from './atoms/Vipps/Vipps';
import { request, fetch_request } from '../../service/request';
import {
  CONSTANTS,
  PaymentType,
  ProductKeys,
  SubscriptionPeriodKey,
} from '../../service/constants';
import Loader from '../../components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import BackButton from '../../components/BackButton/BackButton';

const customStyles = {
  padding: '24px 0px 0px 20px',
};

const NewPaymentPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [defaultPlan, setDefaultPlan] = useState({});
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isCardValue, setIsCardValue] = useState(false);
  const [agreementHash, setAgreementHash] = useState({});
  const [subscriptionPeriods, setSubscriptionPeriods] = useState([]);
  const [discountsList, setDiscountsList] = useState([]);
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const user = useSelector((state) => state.user.user);

  const subscriptionPeriod = useSelector(
    (state) => state.paymentMethods.subscriptionPeriod
  );

  const subscriptionDiscount = useSelector(
    (state) => state.paymentMethods.subscriptionDiscount
  );

  const selectedPaymentMethod = useSelector(
    (state) => state.paymentMethods.cardMethod || 'vipps'
  );
  const plans = useSelector((state) => state.products) || [];
  const selectedPlan = useSelector((state) => state.selectedPlan || null);
  const currentSubscriptionPlan = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionPlan || null
  );
  const currentSubscription = useSelector(
    (state) => state.currentSubscriptionPlan.currentSubscriptionData
  );
  const userSlug =
    localStorage.getItem('user-slug') || sessionStorage.getItem('user-slug');

  const params = new URLSearchParams(location.search).get(
    'payment_method_type'
  );

  useEffect(() => {
    if (params === 'vipps') {
      dispatch(setSelectedCardMethod('vipps'));
    }
    if (params === 'card') {
      dispatch(setSelectedCardMethod('card'));
    }
  }, [params]);

  const handelCardInputs = (value) => setIsCardValue(value);
  const handelSelectPayment = (method) => {
    navigate(`/pricing/payment?payment_method_type=${method}`);
    dispatch(setSelectedCardMethod(method));
  };

  // -------Fetch Countries-------//

  useEffect(() => {
    fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  //  ------- Fetch products------//
  useEffect(() => {
    setLoading(true);
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/products`, config)
      .then((res) => {
        dispatch(setProductInfo(res.data));
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [dispatch]);

  //  ------- Fetch Subscription periods------//
  const fetchSubscriptionPeriods = (periodKey) => {
    setLoading(true);
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_periods`)
      .then((res) => {
        setSubscriptionPeriods(res.data);
        if (isEmpty(subscriptionPeriod)) {
          let defaultPeriod = null;
          defaultPeriod = res.data.find(
            (period) => period.key === SubscriptionPeriodKey.annually
          );
          defaultPeriod = defaultPeriod ? defaultPeriod : res.data[0];
          dispatch(setSubscriptionPeriod(defaultPeriod));
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  //  ------- Fetch Subscription Discounts------//

  const fetchSubscriptionDiscount = () => {
    axios
      .get(`${CONSTANTS.baseUrl}/api/subscription_discounts`)
      .then((res) => {
        setDiscountsList(res.data);
        if (isEmpty(subscriptionDiscount)) {
          const discount = res.data.find(
            (discount) => discount.user_count === 1
          );
          dispatch(setSubscriptionDiscount(discount));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //  ------- Fetch Agreements------//
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/vipps/agreements`, config)
      .then((res) => {
        const hash = {};
        if (Array.isArray(res.data.agreement)) {
          res.data.agreement.map((agreement) => {
            hash[agreement.agreement_id] = agreement;
          });
        }
        setAgreementHash(hash);
      })
      .catch((err) => console.log(err));
  }, [userSlug]);

  // -----------------Fetch User Payments methods-------------------//

  const fetchPaymentMethods = async (id) => {
    if (userSlug) {
      try {
        request(`/users/${userSlug}/payment_methods`).then((res) => {
          let currentPaymentMethod = {};
          if (id) {
            currentPaymentMethod = res.data.find((method) => method.id === id);
            if (currentPaymentMethod.payment_type === PaymentType.vipps) {
              dispatch(setSelectedVippsMethod(currentPaymentMethod));
              dispatch(setVippsPhoneNumber(currentPaymentMethod.phone_number));
            } else {
              dispatch(setPaymentMethod(currentPaymentMethod));
            }
          }
          const vippsMethods = res.data.filter(
            (method) => method.payment_type === PaymentType.vipps
          );
          const cards = res.data.filter(
            (method) => method.payment_type === PaymentType.card
          );
          if (cards.length > 0) {
            dispatch(setPaymentMethods(cards));
          } else {
            dispatch(setPaymentMethods([]));
            dispatch(setShowCardMethod(true));
          }
          if (vippsMethods.length > 0) {
            dispatch(setVippsPaymentMethods(vippsMethods));
            dispatch(setShowVippsPaymentMethod(true));
          } else {
            dispatch(setVippsPaymentMethods([]));
            dispatch(setShowVippsPaymentMethod(true));
            dispatch(setSelectedVippsMethod({}));
          }
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  // ----------Fetch user current Subscription------------//

  useEffect(() => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        Accept: '*/*',
      },
      redirect: 'follow',
    };
    axios
      .get(`${CONSTANTS.baseUrl}/api/users/${userSlug}/subscription`, config)
      .then((res) => {
        if (res.data?.product) {
          dispatch(setCurrentSubscriptionPlan(res.data.product));
          dispatch(setCurrentSubscriptionData(res.data));
          fetchSubscriptionPeriods(res.data.period);
          fetchPaymentMethods(res.data.payment_method_id);
        }
      })
      .catch((err) => console.log(err));
  }, [dispatch, userSlug]);

  useEffect(() => {
    fetchSubscriptionDiscount();
  }, [userSlug]);

  useEffect(() => {
    const consciousPlan = plans.find(
      (plan) => plan.key === ProductKeys.conscious
    );
    if (!isEmpty(selectedPlan)) {
      setDefaultPlan(selectedPlan);
    } else if (!isEmpty(currentSubscriptionPlan)) {
      const isFreePlan = currentSubscriptionPlan.key === ProductKeys.free;
      if (isFreePlan) {
        setDefaultPlan(consciousPlan);
      } else {
        const subscription = plans.find(
          (plan) => plan.id === currentSubscriptionPlan.id
        );
        if (subscription) {
          setDefaultPlan(subscription);
        }
      }
    } else {
      setDefaultPlan(consciousPlan);
    }
  }, [selectedPlan, currentSubscriptionPlan, plans]);

  if (!isAuth) {
    navigate('/auth/sign-in');
  } else if (user && user.subscription?.key !== ProductKeys.free) {
    navigate('/settings/subscriptions');
  }

  if (
    loading ||
    isEmpty(plans) ||
    isEmpty(defaultPlan) ||
    isEmpty(subscriptionPeriod)
  ) {
    return <Loader />;
  }

  return (
    <div className={styles['payment-page']}>
      <BackButton fallBackUrl='/settings/subscriptions' style={customStyles} />
      <React.Fragment>
        <div className={styles['payment-page-heading']}>
          <span>
            <FormattedMessage id='check_out' />
          </span>
        </div>
        <div className={styles['payment-page-container']}>
          <SelectPaymentMethod
            handelSelectPayment={handelSelectPayment}
            selectedPaymentMethod={selectedPaymentMethod}
          />
        </div>
        {selectedPaymentMethod === 'card' && (
          <CardPaymentMethod
            subscriptionPeriods={subscriptionPeriods}
            defaultPlan={defaultPlan}
            plans={plans}
            selectedPlan={selectedPlan}
            selectedPaymentMethod={selectedPaymentMethod}
            currentSubscriptionPlan={currentSubscriptionPlan}
            currentSubscription={currentSubscription}
            countries={countries}
            handelCardInputs={handelCardInputs}
            isCardValue={isCardValue}
            discountsList={discountsList}
          />
        )}
        {selectedPaymentMethod === 'vipps' && (
          <Vipps
            subscriptionPeriods={subscriptionPeriods}
            defaultPlan={defaultPlan}
            plans={plans}
            selectedPlan={selectedPlan}
            selectedPaymentMethod={selectedPaymentMethod}
            currentSubscriptionPlan={currentSubscriptionPlan}
            currentSubscription={currentSubscription}
            agreementHash={agreementHash}
            discountsList={discountsList}
          />
        )}
      </React.Fragment>
    </div>
  );
};

export default NewPaymentPage;
