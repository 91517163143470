import React, { useEffect, useState } from 'react';
import Header from './Header';
import EditDescription from './EditDescription';
import styles from './SparksList.module.css';
import { privacyList } from '../CreateSparks/CreateSparks.jsx';
import {
  useUpdateSparkMutation,
  useCreateReactionMutation,
  useDestroyReactionMutation,
  useUpdateReactionMutation,
} from '../../../../../../graphql/generated/sparkHooks.ts';
import showUserNotification from '../../../../../../components/UserNotification/showUserNotification.js';
import { FormattedMessage, useIntl } from 'react-intl';
import { setSparks } from '../../../../../../redux/actions.js';
import { useDispatch, useSelector } from 'react-redux';
import SparkFooter from './SparkFooter/SparkFooter.jsx';
import Inspired from '../../../../../../img/Inspired.svg';
import Reflected from '../../../../../../img/Reflected.svg';
import Enlightened from '../../../../../../img/Enlightened.svg';

export const reactionList = {
  inspired: {
    title: <FormattedMessage id='inspired' />,
    icon: Inspired,
    type: 'inspired',
  },
  reflected: {
    title: <FormattedMessage id='reflected' />,
    icon: Reflected,
    type: 'reflected',
  },
  enlightened: {
    title: <FormattedMessage id='enlightened' />,
    icon: Enlightened,
    type: 'enlightened',
  },
};

const SparkPost = ({
  spark,
  sparkCategories,
  user,
  renderSparkModal = false,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const currentSparks = useSelector((state) => state.spark.sparksList);
  const [editSpark, setEditSpark] = useState(false);
  const [description, setDescription] = useState('');
  const [sparkCategory, setSparkCategory] = useState({});
  const [privacy, setPrivacy] = useState('');
  const [destroyReaction] = useDestroyReactionMutation();
  const [updateReaction] = useUpdateReactionMutation();
  const [createReaction] = useCreateReactionMutation({
    onCompleted: (data) => {
      if (data && data.createReaction.reaction) {
        const newReaction = data.createReaction.reaction;

        const sparks = currentSparks.map((spark) => {
          if (spark.id === newReaction.reactableId) {
            const currentReactionsCount = spark.reactionsCount || {};

            const updatedReactionsCount = {
              ...currentReactionsCount,
              [newReaction.reactionType]:
                (currentReactionsCount[newReaction.reactionType] || 0) + 1,
            };

            return {
              ...spark,
              reactionsCount: updatedReactionsCount,
              totalReactionsCount: spark?.totalReactionsCount
                ? spark.totalReactionsCount + 1
                : 1,
              reaction: {
                ...spark.reaction,
                reactionType: newReaction.reactionType,
                id: newReaction.id,
              },
            };
          } else {
            return spark;
          }
        });

        dispatch(setSparks(sparks));
      }
    },
  });

  const [updateSpark] = useUpdateSparkMutation({
    onCompleted: (data) => {
      if (data && data.updateSpark) {
        const updatedSparks = [...currentSparks];
        const newSpark = data.updateSpark?.spark;
        const findIndex = currentSparks.findIndex(
          (spark) => spark.id === newSpark?.id
        );
        updatedSparks.splice(findIndex, 1, newSpark);
        dispatch(setSparks(updatedSparks));
        setEditSpark(false);
        setDescription('');
        setSparkCategory({});
        setPrivacy('');
      }
    },
  });

  useEffect(() => {
    if (sparkCategories.length > 0) setSparkCategory(sparkCategories[0]);
  }, [sparkCategories]);

  const onSave = async () => {
    if (description.trim() === '') {
      showUserNotification(
        'The description field cannot be empty. Please provide some details.',
        'error'
      );
      return;
    }
    try {
      await updateSpark({
        variables: {
          id: spark.id,
          description: description,
          sparkCategoryId: sparkCategory.id,
          visibility: privacy.key,
        },
      });
      showUserNotification(
        intl.formatMessage({ id: 'spark_update' }),
        'success'
      );
    } catch (error) {
      if (error.graphQLErrors && error.graphQLErrors.length > 0) {
        error.graphQLErrors.forEach(({ message }) => {
          showUserNotification(message, 'error');
        });
      }
      if (error.networkError) {
        showUserNotification(
          'Network error: ' + error.networkError.message,
          'error'
        );
      }
      if (!error.graphQLErrors && !error.networkError) {
        showUserNotification(
          'An unexpected error occurred: ' + error.message,
          'error'
        );
      }
    }
  };

  const handelReaction = async (type) => {
    try {
      await createReaction({
        variables: {
          reactableId: spark.id,
          reactionType: type,
          reactableType: 'Spark',
        },
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const handelRemoveReaction = async (reactionId, sparkId) => {
    try {
      await destroyReaction({
        variables: {
          id: reactionId,
        },
      });
      const sparks = currentSparks.map((spark) => {
        if (spark.id === sparkId) {
          const currentReactionsCount = spark.reactionsCount || {};
          const previousReactionType = spark.reaction.reactionType;

          if (
            previousReactionType &&
            currentReactionsCount[previousReactionType]
          ) {
            currentReactionsCount[previousReactionType] -= 1;

            if (currentReactionsCount[previousReactionType] === 0) {
              delete currentReactionsCount[previousReactionType];
            }
          }
          const updatedReactionsCount =
            Object.entries(currentReactionsCount).length > 0
              ? currentReactionsCount
              : null;

          return {
            ...spark,
            reactionsCount: updatedReactionsCount,
            totalReactionsCount: spark.totalReactionsCount - 1,
            reaction: {},
          };
        } else {
          return spark;
        }
      });

      dispatch(setSparks(sparks));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handelUpdateReaction = async (reactionId, sparkId, newReactionType) => {
    try {
      await updateReaction({
        variables: {
          id: reactionId,
          reactionType: newReactionType,
        },
      });

      const sparks = currentSparks.map((spark) => {
        if (spark.id === sparkId) {
          const currentReactionsCount = { ...spark.reactionsCount } || {};
          const previousReactionType = spark.reaction.reactionType;

          if (
            previousReactionType &&
            currentReactionsCount[previousReactionType]
          ) {
            currentReactionsCount[previousReactionType] -= 1;
            if (currentReactionsCount[previousReactionType] === 0) {
              delete currentReactionsCount[previousReactionType];
            }
          }

          if (newReactionType) {
            currentReactionsCount[newReactionType] =
              (currentReactionsCount[newReactionType] || 0) + 1;
          }

          return {
            ...spark,
            reactionsCount: currentReactionsCount,
            reaction: {
              ...spark.reaction,
              reactionType: newReactionType,
            },
          };
        } else {
          return spark;
        }
      });
      dispatch(setSparks(sparks));
    } catch (error) {
      console.log(error.message);
    }
  };

  const onClickEdit = (spark) => {
    setEditSpark(true);
    setDescription(spark.description);
    const findCategory = sparkCategories.find(
      (category) => category.id === spark.sparkCategoryId
    );
    const findPrivacy = privacyList.find(
      (privacy) => privacy.key === spark.visibility
    );
    if (findCategory) {
      setSparkCategory(findCategory);
    }
    if (findPrivacy) {
      setPrivacy(findPrivacy);
    }
  };

  const onCloseEdit = () => {
    setEditSpark(false);
    setDescription('');
  };

  return (
    <div
      className={
        renderSparkModal
          ? styles['spark-container-modal']
          : styles['spark-container']
      }
    >
      <Header
        spark={spark}
        onClickEdit={onClickEdit}
        editSpark={editSpark}
        sparkCategories={sparkCategories}
        privacy={privacy}
        sparkCategory={sparkCategory}
        setPrivacy={setPrivacy}
        setSparkCategory={setSparkCategory}
        user={user}
      />
      {editSpark ? (
        <EditDescription
          description={description}
          setDescription={setDescription}
          onCloseEdit={onCloseEdit}
          onSave={onSave}
        />
      ) : (
        spark?.description && (
          <div className={styles['description']}>{spark?.description}</div>
        )
      )}
      <SparkFooter
        renderSparkModal={renderSparkModal}
        spark={spark}
        reactionList={reactionList}
        handelReaction={handelReaction}
        handelRemoveReaction={handelRemoveReaction}
        handelUpdateReaction={handelUpdateReaction}
        user={user}
        sparkCategories={sparkCategories}
      />
    </div>
  );
};

export default SparkPost;
