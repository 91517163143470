import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { useIntl, FormattedMessage } from 'react-intl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import WarningMessage from './WarningMessage';
import styles from './Input.module.css';

const InputField = ({ item }) => {
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState('');

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <TextField
        // id='filled-basic'
        name='name'
        placeholder={intl.formatMessage({
          id: item.placeholder,
        })}
        variant='filled'
        className={`${styles['auth-button']} ${
          item.error ? styles['error'] : ''
        }`}
        type={item.type === 'password' && showPassword ? 'text' : item.type}
        value={item.value}
        onBlur={() => {
          item?.onBlur && item.onBlur();
        }}
        autoComplete={item.autoComplete}
        required
        onChange={(e) => {
          const value = e.target.value;
          item.action(value);
        }}
        disabled={item.disabled}
        InputProps={{
          endAdornment: (
            <>
              {item.type === 'password' && (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )}
              {item.isVerified !== undefined &&
                item.value.length > 0 &&
                !item.error &&
                item.isVerified && (
                  <InputAdornment position='end'>
                    <IconButton aria-label='verified' disabled>
                      <VerifiedUserIcon style={{ color: '#04C600' }} />
                    </IconButton>
                  </InputAdornment>
                )}
            </>
          ),
        }}
      />
      {item.error && <WarningMessage messageKey={item.errorKey} />}
    </React.Fragment>
  );
};

export default InputField;
