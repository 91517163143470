import React from 'react';
import styles from './ConnectionRelation.module.css';
import SetRelationOrClaimDialog from '../RelationOrClaimModals/SetRelationOrClaimDialog';
import Description from './Description';
import Features from './Features';
import { FormattedMessage } from 'react-intl';

const FeatureList = ({ handleClose, grave, isSubscribed, user }) => {
  return (
    <React.Fragment>
      <Description grave={grave} isSubscribed={isSubscribed} />
      {!isSubscribed && (
        <React.Fragment>
          <Features />
          <div className={styles['message-info']}>
            <FormattedMessage id='set_relation_info_message' />
          </div>
        </React.Fragment>
      )}
      <SetRelationOrClaimDialog
        grave={grave}
        user={user}
        handleCloseRelationDialog={handleClose}
      />
    </React.Fragment>
  );
};

export default FeatureList;
