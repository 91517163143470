import React from 'react';
import Comments from '../Comments.jsx';
import CommentInput from '../CommentInput/CommentInput.jsx';
import { FormattedMessage } from 'react-intl';
import styles from './CommentsDrawer.module.css';
import Drawer from '../../Components/Drawer.jsx';

const CommentsDrawer = ({
  openDrawer,
  handelCloseDrawer,
  handelOpenDrawer,
  spark,
  handelCreateComment,
}) => {
  return (
    openDrawer && (
      <Drawer
        isOpen={openDrawer}
        closeDrawer={handelCloseDrawer}
        openDrawer={handelOpenDrawer}
        title={
          <div className={styles['total-comments']}>
            {spark.commentsCount} <FormattedMessage id='comments' />
          </div>
        }
        footer={
          <CommentInput
            handelCreateComment={handelCreateComment}
            spark={spark}
          />
        }
        children={<Comments spark={spark} />}
      />
    )
  );
};

export default CommentsDrawer;
