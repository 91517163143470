import React from 'react';
import FeatureList from './FeatureList';
import CustomModal from '../../../../components/CustomModal';
import Heading from './Heading';

const ConnectRelationsModal = (props) => {
  const isSubscribed = props.user.is_subscribed;
  return (
    <CustomModal
      isOpen={props.openRelationDialog}
      closeModal={props.handleCloseRelationDialog}
      children={
        <FeatureList
          grave={props.grave}
          handleClose={props.handleCloseRelationDialog}
          isSubscribed={isSubscribed}
          user={props.user}
        />
      }
      style={{
        width: '600px',
      }}
      title={<Heading isSubscribed={isSubscribed} grave={props.grave} />}
    />
  );
};

export default ConnectRelationsModal;
