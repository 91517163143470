import React, { useState, useCallback, useEffect } from 'react';
import Input from '../../../components/Input/Input';
import TermsAndCondition from './TermsAndCondition';
import PrimaryButton from '../../../components/PrimaryButton/index';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './CreateMembership.module.css';
import debounce from 'lodash.debounce';
import showUserNotification from '../../../components/UserNotification/showUserNotification';
import BottomMessage from './BottomMessage';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from 'lodash.isempty';
import { CONSTANTS } from '../../../service/constants';
import ProcessModal from '../../Payment/atoms/ProcessModal';
import { getListOfCountries } from '../../../redux/actions';
import { fetch_request } from '../../../service/request';

const InputForm = ({ setHidePage, setUser }) => {
  const intl = useIntl();
  const [formData, setFormData] = useState({
    email: '',
    userName: '',
    gender: '',
    password: '',
    confirmPassword: '',
    county: {},
    dateOfBirth: '',
    checked: false,
    language: {},
  });
  const dispatch = useDispatch();
  const [languages, setLanguages] = useState([]);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [countries, setCountries] = useState([]);
  const [openProcessModal, setOpenProcessModal] = useState(false);
  const userInvitation = useSelector(
    (state) => state.invitationToken.subscriptionInvitationData
  );
  const fetchLanguages = () => {
    fetch_request('/api/languages')
      .then((res) => res.json())
      .then((data) => setLanguages(data.languages))
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    fetchLanguages();
  }, []);

  const fetchCountries = async () => {
    await fetch_request('/api/countries')
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
        dispatch(getListOfCountries(data));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchCountries();
  }, [dispatch]);

  useEffect(() => {
    if (!isEmpty(userInvitation) && userInvitation.email) {
      setFormData({
        ...formData,
        email: userInvitation.email,
        userName: userInvitation.full_name,
      });
    }
  }, [userInvitation]);

  const validateEmail = (email) => {
    return /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);
  };

  const debouncedValidate = useCallback(
    debounce((value) => {
      if (validateEmail(value)) {
        setIsValidEmail(false);
      } else {
        setIsValidEmail(true);
      }
    }, 500),
    []
  );

  const handleEmailChange = (value) => {
    setFormData({ ...formData, email: value });
    debouncedValidate(value);
  };

  const isPasswordValid = () => {
    // Minimum password length: 7-8 characters
    if (formData.password.length < 7) {
      return false;
    }

    // Password complexity: at least 3 out of 4 categories
    const uppercaseRegex = /[A-Z]/;
    const lowercaseRegex = /[a-z]/;
    const digitRegex = /[0-9]/;
    const nonAlphanumericRegex = /[^A-Za-z0-9]/;
    let categoriesCount = 0;

    if (uppercaseRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (lowercaseRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (digitRegex.test(formData.password)) {
      categoriesCount++;
    }
    if (nonAlphanumericRegex.test(formData.password)) {
      categoriesCount++;
    }

    return categoriesCount >= 3;
  };

  const passwordsMatch = () => {
    return formData.password === formData.confirmPassword;
  };

  const handleOnSelectCountry = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        county: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        county: value,
      }));
    }
  };

  const handleOnSelectLanguage = (event, value, reason) => {
    if (reason === 'clear') {
      setFormData((prev) => ({
        ...prev,
        language: {},
      }));
    } else if (reason === 'selectOption') {
      setFormData((prev) => ({
        ...prev,
        language: value,
      }));
    }
  };

  let formInputs = [
    {
      label: 'settings_billingEmail',
      type: 'email',
      placeholder: 'type_your_email',
      action: (value) => {
        handleEmailChange(value);
      },
      value: formData.email,
      autoComplete: 'off',
      error: isValidEmail,
      errorKey: 'email_error',
      disabled: false,
    },
    {
      label: 'settings_fullName',
      type: 'text',
      placeholder: 'auth_enterFullName',
      action: (value) => {
        if (value[0] !== ' ') {
          setFormData({
            ...formData,
            userName: value
              .replace(/[0-9]/g, '')
              .replace(/[\"'@#!$%^&*()_+|~=`{}\[\]:;'<>?,.\/]/g, ''),
          });
        }
      },
      value: formData.userName,
      autoComplete: 'off',
      error: false,
      errorKey: 'auth_notSame',
      disabled: false,
    },
    {
      label: 'settings_gender',
      type: 'gender',
      gendersList: ['mann', 'kvinne', 'annen'],
      action: (value) => {
        setFormData({ ...formData, gender: value });
      },
      value: formData.gender,
      autoComplete: 'off',
      error: false,
      errorKey: 'auth_notSame',
      disabled: false,
    },
    {
      label: 'date_of_birth',
      type: 'datePicker',
      error: false,
      disableFuture: true,
      disablePast: false,
      value: formData.dateOfBirth,
      action: (value) => {
        setFormData((prev) => ({ ...prev, dateOfBirth: value }));
      },
    },
    {
      label: 'country',
      type: 'autocomplete',
      placeholder: 'country',
      value: formData.county,
      options: countries,
      autoComplete: 'off',
      error: false,
      errorKey: '',
      disabled: false,
      action: handleOnSelectCountry,
    },
    {
      label: 'settings_language',
      type: 'autocomplete',
      placeholder: 'settings_language',
      value: formData.language,
      options: languages,
      autoComplete: 'off',
      error: false,
      errorKey: '',
      disabled: false,
      action: handleOnSelectLanguage,
    },
    {
      label: 'settings_password',
      type: 'password',
      placeholder: 'enter_your_password',
      action: (value) => {
        setFormData({ ...formData, password: value });
      },
      value: formData.password,
      autoComplete: 'new-password',
      error: formData.password.length > 0 && !isPasswordValid(),
      errorKey: 'password_length',
      disabled: false,
    },
    {
      label: 'confirm_password',
      type: 'password',
      placeholder: 'confirm_your_password',
      action: (value) => {
        setFormData({ ...formData, confirmPassword: value });
      },
      value: formData.confirmPassword,
      autoComplete: 'new-password',
      error: formData.confirmPassword.length > 7 && !passwordsMatch(),
      errorKey: 'auth_notSame',
      disabled: !isPasswordValid(),
    },
  ];

  const validateForm = (formData) => {
    const {
      email,
      userName,
      gender,
      password,
      confirmPassword,
      checked,
      dateOfBirth,
      county,
      language,
    } = formData;
    if (
      !email ||
      !userName ||
      !gender ||
      !password ||
      !confirmPassword ||
      !county ||
      !dateOfBirth ||
      !language
    ) {
      showUserNotification(intl.formatMessage({ id: 'filled_out' }), 'warning');
      return false;
    }

    if (!validateEmail(email)) {
      showUserNotification(
        intl.formatMessage({ id: 'email_error' }),
        'warning'
      );
      return false;
    }

    if (password.length === 0 || confirmPassword.length === 0) {
      showUserNotification(
        intl.formatMessage({ id: 'set_new_password_error' }),
        'warning'
      );
    }

    if (password !== confirmPassword) {
      showUserNotification(
        intl.formatMessage({ id: 'auth_notSame' }),
        'warning'
      );
      return false;
    }

    if (!checked) {
      showUserNotification(intl.formatMessage({ id: 'must_agree' }, 'warning'));
      return false;
    }

    return true;
  };

  const createUser = () => {
    setOpenProcessModal(true);
    const payload = {
      user: {
        password: formData.password,
        password_confirmation: formData.confirmPassword,
        full_name: formData.userName,
        gender: formData.gender,
        email: formData.email,
        country_id: formData.county.id,
        date_of_birth: formData.dateOfBirth,
        preferred_language_attributes: {
          language_id: formData.language.id,
        },
      },
    };

    if (userInvitation.length > 0) {
      payload.user[
        'subscription_invitation_token'
      ] = `${userInvitation.invitation_token}`;
    }

    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(payload),
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
      redirect: 'follow',
    };

    fetch(`${CONSTANTS.baseUrl}/api/users.json`, requestOptions)
      .then(async (response) => {
        const result = await response.json();

        if (!response.ok) {
          setOpenProcessModal(false);
          showUserNotification(result.message, 'error');
          return;
        }

        if (result.user) {
          setOpenProcessModal(false);
          showUserNotification(
            intl.formatMessage({ id: 'account_created' }),
            'success'
          );
          setHidePage(true);
          setUser({
            name: formData.userName,
            email: formData.email,
          });
        } else {
          setOpenProcessModal(false);
          showUserNotification(result.message, 'warning');
        }
      })
      .catch((error) => {
        setOpenProcessModal(false);
        console.log('error', error);
        showUserNotification(error.message, 'error');
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (validateForm(formData)) {
      createUser();
    }
  };

  const handleCheckBoxChange = () => {
    setFormData({ ...formData, checked: !formData.checked });
  };

  return (
    <div>
      <form autoComplete='off' onSubmit={handleFormSubmit}>
        {formInputs.map((item, index) => (
          <Input item={item} key={index} />
        ))}
        <TermsAndCondition
          checked={formData.checked}
          handleCheckBoxChange={handleCheckBoxChange}
          messageKey='auth_acceptConditions'
          actionKey='auth_termsAndCondition'
        />
        <div className={styles['btn-container']}>
          <PrimaryButton
            type='submit'
            children={<FormattedMessage id='auth_register' />}
          />
        </div>
      </form>
      <BottomMessage
        messageKey='auth_login_here'
        url='/auth/sign-in'
        textKey='auth_alreadyRegistered'
      />
      {openProcessModal && (
        <ProcessModal
          open={openProcessModal}
          messageKey='waiting_for_account'
        />
      )}
    </div>
  );
};

export default InputForm;
