import React from 'react';
import { useNavigate } from 'react-router-dom';
import BirthIcon from '../../img/Birth.svg';
import DeathIcon from '../../img/Death.svg';
import styles from './DeceasedTopHeader.module.css';
import moment from 'moment';
import Relations from './atoms/GraveContent/Relations';
import ConnectRelationsModal from './atoms/ConnectRelationsModal/ConnectRelationsModal';
import GraveAvatar from './atoms/GraveAvatar/GraveAvatar';
import PrimaryButton from '../../components/PrimaryButton';

const DeceasedHeaderCopy = (props) => {
  const {
    isAuth,
    grave,
    user,
    isClaimed,
    setOpenRelationDialog,
    openRelationDialog,
  } = props;
  const navigate = useNavigate();
  const current_user_in_claim_queue = grave.current_user_in_claim_queue;
  const can_claim = grave.can_claim;
  const can_set_relations = grave.can_set_relations;
  const current_user_in_set_relation_queue =
    grave.current_user_in_set_relation_queue;

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const yearsGrave = (death, birth) => {
    const startDate = moment(death, 'YYYY-MM-DD');
    const endDate = moment(birth, 'YYYY-MM-DD');
    const dateDiff = startDate.diff(endDate, 'years');
    return dateDiff;
  };

  const handleOpenClaimDialog = () => {
    navigate(`/grave/${grave.slug}/claim`);
  };

  const handleCloseRelationDialog = () => {
    if (user.is_subscribed) {
      setOpenRelationDialog(false);
    } else {
      navigate(-1);
    }
  };

  const handleOpenRelationDialog = () => {
    setOpenRelationDialog(true);
  };

  const handleClick = () => {
    navigate('/auth/sign-in');
  };

  return (
    <React.Fragment>
      {/*/////////////////// Set Relation or Claim modals /////////////////////////*/}
      {openRelationDialog && (
        <ConnectRelationsModal
          grave={grave}
          openRelationDialog={openRelationDialog}
          handleCloseRelationDialog={handleCloseRelationDialog}
          user={user}
        />
      )}
      <div className={styles['deceased-header-container']}>
        <div className={styles['grave-avatar']}>
          <GraveAvatar avatar={grave.avatar} user={user} grave={grave} />
          <div className={styles['header-deceased-info']}>
            <div className={styles['heading-container']}>
              <span className={styles['heading']}>{grave?.name}</span>
            </div>
            <div className={styles['deceased-grave-info']}>
              {grave?.date_of_death && grave?.date_of_birth && (
                <span className={styles['death-age']}>
                  {yearsGrave(grave.date_of_death, grave.date_of_birth)} år
                </span>
              )}
              <span className={styles['date-of-birth-container']}>
                <img src={BirthIcon} alt='date-of-birth' />
                <span className={styles['date-of-birth']}>
                  {grave?.date_of_birth ? formatDate(grave.date_of_birth) : ''}
                </span>
              </span>
              <span className={styles['date-of-death-container']}>
                <img src={DeathIcon} alt='date-of-death' />
                <span className={styles['date-of-death']}>
                  {grave?.date_of_death ? formatDate(grave.date_of_death) : ''}
                </span>
              </span>
              {grave && grave.reason_of_death && (
                <React.Fragment>
                  <span className={styles['side-border']}></span>
                  <span className={styles['reason-of-death']}>
                    Døde av{' '}
                    {grave.reason_of_death === 'Others' ||
                    grave.reason_of_death === 'Annen' ||
                    grave.reason_of_death === 'Other'
                      ? grave.other_reason_of_death
                      : grave.reason_of_death}
                  </span>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className={styles['header-right-container']}>
          {/* show claim page button if user not login or not register */}
          <div className={styles['header-right-container-button-wrapper']}>
            {!isAuth && can_claim && (
              <PrimaryButton onClick={handleClick}>
                Trykk for å ta styring
              </PrimaryButton>
            )}
            {/* show Claim page button if user not upload death certificate*/}
            {isAuth && can_claim && !current_user_in_claim_queue && (
              <PrimaryButton onClick={handleOpenClaimDialog}>
                Ta styring her
              </PrimaryButton>
            )}

            {!isAuth && can_set_relations && isClaimed && (
              <PrimaryButton onClick={handleClick}>Sett relasjon</PrimaryButton>
            )}

            {isAuth &&
              can_set_relations &&
              !current_user_in_set_relation_queue &&
              isClaimed && (
                <PrimaryButton onClick={handleOpenRelationDialog}>
                  Sett relasjon
                </PrimaryButton>
              )}
          </div>
          {isAuth && !can_claim && current_user_in_claim_queue ? (
            <div className={styles['set-relation-green']}>
              Henvendelsen er mottatt. Du vil motta beskjed når det er klart.
            </div>
          ) : (
            isAuth &&
            !can_set_relations &&
            current_user_in_set_relation_queue && (
              <div className={styles['set-relation-green']}>
                Henvendelsen er mottatt. Du vil motta beskjed når det er klart.
              </div>
            )
          )}
          {grave?.grave_users && grave.grave_users.length > 0 && isAuth && (
            <div>
              <Relations grave={grave} relationsArray={grave.grave_users} />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default DeceasedHeaderCopy;
