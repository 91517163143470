import React, { useState } from 'react';
import Comment from './Comment/Comment';
import styles from './Comments.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useCommentsQuery } from '../../../../../../graphql/generated/sparkHooks.ts';
import { setSparksComments } from '../../../../../../redux/actions';
import { Skeleton } from '@mui/material';
import isEmpty from 'lodash.isempty';
import { FormattedMessage } from 'react-intl';

const Comments = ({ spark }) => {
  const dispatch = useDispatch();
  const totalSkeleton = spark.commentsCount > 5 ? 5 : spark.commentsCount;
  const sparksComments = useSelector((state) => state.spark.sparksComments);
  const [loading, setLoading] = useState(true);

  const { data } = useCommentsQuery({
    variables: { sparkId: spark.id },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data?.comments.items && data?.comments.items.length > 0) {
        const sparkComments = sparksComments[spark.id] || { comments: {} };

        data.comments.items.forEach((comment) => {
          sparkComments.comments[comment.id] = comment;
        });

        sparksComments[spark.id] = sparkComments;

        dispatch(setSparksComments(sparksComments));
      }
      setLoading(false);
    },
  });

  if (loading) {
    return Array.from({ length: parseInt(totalSkeleton) }).map((_, index) => (
      <div key={index} className={styles['loading']}>
        <Skeleton animation='wave' variant='circular' width={45} height={40} />
        <Skeleton variant='text' sx={{ fontSize: '16px', width: '100%' }} />
      </div>
    ));
  }

  return (
    <div className={styles['container']}>
      {!isEmpty(sparksComments) && sparksComments[spark.id] ? (
        Object.values(sparksComments[spark.id].comments).map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            spark={spark}
            sparksComments={sparksComments}
          />
        ))
      ) : (
        <div className={styles['empty-comments']}>
          <FormattedMessage id='empty_comments' />
        </div>
      )}
    </div>
  );
};

export default Comments;
