import React, { useEffect } from 'react';
import LoginForm from '../../containers/NewAuth/LoginForm/LoginForm';
import { useSelector, useDispatch } from 'react-redux';
import { subscriptionInvitationToken } from '../../redux/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import './SignInPage.css';

const SignInPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.auth.isAuthorized);
  const screen = useSelector((state) => state.auth.currentScreen);
  const userSlug = localStorage.getItem('user-slug');
  const invitationToken = useSelector(
    (state) => state.invitationToken.subscriptionInvitationToken
  );

  const slugRedirect = localStorage.getItem('slug_for_redirect');

  useEffect(() => {
    sessionStorage.setItem('previousPath', location.pathname);
  }, [location.pathname]);

  const redirectToLastPage = () => {
    const trimmedToken = invitationToken.trim();
    const previousPath = sessionStorage.getItem('previousPath');

    if (
      slugRedirect !== 'null' &&
      slugRedirect !== null &&
      slugRedirect !== undefined
    ) {
      localStorage.setItem('slug_for_redirect', 'null');
      setTimeout(() => navigate(`/deceased/${slugRedirect}`), 1000);
    } else if (location.key) {
      if (trimmedToken === '') {
        if (
          previousPath &&
          (previousPath === '/registration' ||
            previousPath === '/auth/sign-in' ||
            previousPath === '/account-confirmation' ||
            previousPath === '/reset-password')
        ) {
          sessionStorage.removeItem('previousPath');
          navigate(`/`);
        } else {
          navigate(-1);
        }
      } else {
        dispatch(subscriptionInvitationToken(''));
        navigate(`/member/${userSlug}`);
      }
    } else {
      navigate(`/`);
    }
  };

  if (isAuth) {
    redirectToLastPage();
  }

  return (
    <div className='sign-in-page'>
      <LoginForm />
    </div>
  );
};

export default SignInPage;
